import { Button, Flex, FormControl, FormLabel, Select, Wrap } from "@chakra-ui/react";
import React, { memo } from "react";

function QuestionaireFilter({
  searchObj,
  handleChange,
  onGo,
  onCreate,
  instituteList,
  syllabusType,
  syllabusList,
  onUpload
}) {
  return (
    <>
      <Flex mt={2} align={'end'} justifyContent={"center"} gap={2} direction={{ base: "column", md: "column", lg: "row" }} alignItems={{ base: "center", md: "center", lg: "end" }} >
        <FormControl maxWidth={{ base: '75%', md: '75%', lg: '25%' }}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }}>Institute</FormLabel>
          <Select
            name="institution-id"
            id="institution-id"
            value={searchObj["institution-id"]}
            onChange={handleChange}


          >
            <option key="select" value={0} >
              -Select-
            </option>
            {instituteList?.data?.map((option, index) => (
              <option
                key={
                  option["institution-id"] + option["institution-name"] + index
                }
                value={option["institution-id"]}

              >
                {option["institution-name"]}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl>
          <FormLabel>Session Description</FormLabel>
          <Select
            name="syllabus-id"
            id="syllabus-id"
            value={searchObj["syllabus-id"]}
            onChange={handleChange}
          >
            <option key="select" value={0}>
              -Select-
            </option>
            {syllabusList?.data?.map((option, index) => (
              <option
                key={option["syllabus-id"] + option["syllabus-name"] + index}
                value={option["syllabus-id"]}
              >
                {option["syllabus-name"]}
              </option>
            ))}
          </Select>
        </FormControl> */}

        <FormControl maxWidth={{ base: '75%', md: '75%', lg: '25%' }}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }}>Syllabus Type</FormLabel>
          <Select
            name="syllabus-id"
            id="syllabus-id"
            value={searchObj["syllabus-id"]}
            onChange={handleChange}


          >
            <option key="select" value={0}>
              -Select-
            </option>
            {syllabusType.data.map((item) => (
              <option
                key={
                  item['syllabus-type-id']
                }
                value={item['syllabus-type-id']}
              >
                {item['syllabus-type-name']}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button colorScheme='blue' type="button" onClick={onGo} pr={8} pl={8} >
          Search
        </Button>
        {/* <Button type="button" onClick={onCreate} pr={10} pl={10}>
          Create
        </Button> */}
        <Button colorScheme='green' type="button" onClick={onUpload} pr={10} pl={10} >
          Bulk Upload
        </Button>
      </Flex>
    </>
  );
}

export default memo(QuestionaireFilter);
