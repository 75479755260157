import React, { memo, useContext } from "react";
// import { Link } from "react-router-dom";
// import { Table, Thead, Tbody, Tr, Td, Th, Flex, Link, useColorModeValue } from "@chakra-ui/react";
import { formatDate } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { useColorModeValue, Link, useBreakpointValue, Flex } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";

function AssessmentList({ assessmentList, linkHoverColor, isAdmin, sendAssessment, infoMsg }) {
    const navigate = useNavigate();
    const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
    const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
    const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });
    return (
        <>
            <Flex className="lni-table-section" mt={5} maxHeight="600px" >
                <Table variant={variant} colorScheme={colorScheme} cellSpacing={10} cellPadding={10}>
                    <Thead backgroundColor={popoverContentBgColor}>
                        <Tr>
                            <Th fontSize={'2xs'}>{isAdmin ? "Session Description" : "Title"}</Th>
                            <Th fontSize={'2xs'}>Grade/Department</Th>
                            <Th fontSize={'2xs'}>Level</Th>
                            <Th fontSize={'2xs'}>Section/Year</Th>
                            <Th fontSize={'2xs'}>Syllabus Type</Th>
                            {/* {(isAdmin) && <Th fontSize={'2xs'}>Complete By</Th>} */}
                            {(isAdmin) && <Th fontSize={'2xs'}>Action</Th>}
                            {(!isAdmin) && <Th fontSize={'2xs'}>Assessment Url</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(assessmentList?.data && assessmentList?.data.length > 0) ? assessmentList.data.map((item, index) => (
                            <Tr fontSize={'2xs'} key={item['assessment-id']}>
                                <Td fontSize={'2xs'} >{item['syllabus-name'] || "-"}</Td>
                                <Td fontSize={'2xs'} >{item['grade-name'] || "-"}</Td>
                                <Td fontSize={'2xs'} >{item['level-name'] || "-"}</Td>
                                <Td fontSize={'2xs'} >{item['section-name'] || "-"}</Td>
                                <Td fontSize={'2xs'} >{item['syllabus-type'] || "-"}</Td>
                                {/* <Td>{formatDate(item['Due date']) || "-"}</Td> */}
                                {(isAdmin) && <Td>
                                    <Link _hover={{ color: linkHoverColor }} onClick={() => sendAssessment(item)} href="#">Send</Link>
                                </Td>}
                                {(!isAdmin) && <Td>
                                    {item['url'] ? <Link _hover={{ color: linkHoverColor }} onClick={() => navigate(`/quiz/${item['assessment-id']}`)} href="#">View</Link> : "-"}
                                </Td  >}
                                {/* <Td className="action_flex">
                                <a href="javascript:void(0)" onClick={() => changeStatus(item)}>{item['active'] ? 'InActive' : 'Active'}</a>
                            </Td> */}
                            </Tr>
                        )) : (assessmentList?.isLoading ? null : <Tr><Td fontSize={'2xs'} textAlign={'center'} colSpan={5}>{infoMsg}</Td></Tr>)}
                    </Tbody>
                </Table>
            </Flex>
        </>
    )
}

export default memo(AssessmentList);