import React from "react";
import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

const Docs = ({ title, contents }) => {
  return (
    <Box textAlign="center" padding="20px 20px 20px 20px">
      <Heading as="h2" size="lg" mb={4}>
        {title}
      </Heading>
      {contents.map((content, index) => {
        const { head, details } = content;
        const lines = details.split("\n").map((line) => line.trim());

        return (
          <Box key={index} mb={6} textAlign="left">
            <Heading as="h3" size="md" mb={2}>
              {head}
            </Heading>
            {lines.map((line, lineIndex) => {
              if (line.startsWith("•")) {
                return (
                  <UnorderedList key={lineIndex}>
                    <ListItem fontSize="md" textAlign="justify">
                      {line.replace("•", "").trim()}
                    </ListItem>
                  </UnorderedList>
                );
              } else {
                return (
                  <Text key={lineIndex} fontSize="md" textAlign="justify">
                    {line}
                  </Text>
                );
              }
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default Docs;
