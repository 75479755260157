// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
   --color: #fff;
}

/* .user-heading {
    color: var(--color);
} */

.search {
    display: flex;
}

/* .form-label {
    color: var(--color);
}

table {
    color: var(--color);
} */

.action_flex {
    /* border-bottom: none; */
    display: flex;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/userStyle.css"],"names":[],"mappings":"AAAA;GACG,aAAa;AAChB;;AAEA;;GAEG;;AAEH;IACI,aAAa;AACjB;;AAEA;;;;;;GAMG;;AAEH;IACI,yBAAyB;IACzB,aAAa;IACb,SAAS;AACb","sourcesContent":[":root {\n   --color: #fff;\n}\n\n/* .user-heading {\n    color: var(--color);\n} */\n\n.search {\n    display: flex;\n}\n\n/* .form-label {\n    color: var(--color);\n}\n\ntable {\n    color: var(--color);\n} */\n\n.action_flex {\n    /* border-bottom: none; */\n    display: flex;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
