import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { axiosSource, postCall } from '../../services/httpService';
import { GRADE_API, INSTITUTE_API, LEVEL_API, QUESTIONAIRE_API, SYLLABUS_API } from '../../services/apiRoutes';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardBody, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Select, useToast } from "@chakra-ui/react";
import { FiFile, FiX } from "react-icons/fi";
import QuestionFile from "../../files/lni_Questionaire_v3.csv";
import { CONSTANTS } from '../../services/constants';
import { toastByStatus, toastErrorOnly, validateFileType } from '../../services/utils';
import { AuthContext } from '../../App';

function QuestionaireBulkUpload() {
    const [context] = useContext(AuthContext);
    const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);
    const toast = useToast();
    const navigate = useNavigate();
    const initialValues = {
        "institution-id": "",
        "syllabus-id": "",
        "grade-id": "",
        "level-id": "",
        "syllabus-name": "",
        "grade-section": "",
        "syllabus-type-id": "",
        "session-type-name": ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [instituteList, SetInstituteList] = useState({
        isLoading: false,
        data: []
    });
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef();
    const viewRef = useRef();
    const [institueType, setInstitueType] = useState("");
    const [gradeList, SetGradeList] = useState({
        isLoading: false,
        data: []
    });
    const [levelList, SetLevelList] = useState({
        isLoading: false,
        data: []
    });
    const [syllabusType, SetSyllabusType] = useState({
        isLoading: false,
        data: []
    });
    const [syllabusList, SetSyllabusList] = useState({
        isLoading: false,
        data: [],
    });
    const [sessionList, SetSessionList] = useState({
        isLoading: false,
        data: [],
    });

    const getSyllabusList = useCallback(
        async (source) => {
            const requestBody = {
                "institution-id": (formValues['institution-id'] ? Number(formValues['institution-id']) : 0),
            };
            await postCall(SYLLABUS_API.SYLLABUS, requestBody, {}, source)
                .then((res) => {
                    if (res.status === 200) {
                        SetSyllabusList((prev) => ({ ...prev, data: res?.data }));
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.log(error.message); // => the response payload
                    }
                })
                .finally(() =>
                    SetSyllabusList((prev) => ({ ...prev, isLoading: false }))
                );
        },
        [SetSyllabusList, formValues["institution-id"]]
    );


    const getSessionType = useCallback(
        async (source) => {
            await postCall(SYLLABUS_API.SYLLABUS_SESSION, null, {}, source)
                .then((res) => {
                    if (res.status === 200) {
                        SetSessionList((prev) => ({ ...prev, data: res?.data }));
                    }
                });
        },
        []
    );

    useEffect(() => {
        if (!formValues["institution-id"]) {
            setFormValues(prev => ({ ...prev, 'syllabus-id': "" }));
            return;
        }
        const source = axiosSource();
        getSyllabusList(source);
    }, [formValues["institution-id"], getSyllabusList]);

    async function getInstituteList(source) {
        let body = {};
        if (!isAdmin) {
            body = { ...body, "user-id": context?.user_data['lni-user-id'] }
        }
        await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true).then(res => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                SetInstituteList(prev => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
            }
        }).catch((error) => toastErrorOnly(error)).finally(() => SetInstituteList(prev => ({ ...prev, isLoading: false })));
    };

    const getGradeBasedOnScreen = useCallback((data) => {
        const result = instituteList.data?.filter(item => {
            const val = data;
            if (item['institution-id'] === Number(val['institution-id'])) {
                return item;
            }
        });
        // console.log('result', result);
        if (result && result.length > 0) {
            SetGradeList(prev => ({ ...prev, isLoading: true }));
            getGradeList(result[0]['institution-id'], result[0]['institution-type']);
        }
    }, [instituteList.data])


    async function getGradeList(id, type) {
        await postCall(GRADE_API.GRADE, {
            'institution-id': Number(id),
            'institution-type': type
        }, null, null, true).then(res => {
            if (res.status === 200) {
                SetGradeList(prev => ({ ...prev, data: res?.data }));
                // setFormValues(prev => ({ ...prev, "grade-id": syllabusDetail.data['grade-id'] }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        }).finally(() => SetGradeList(prev => ({ ...prev, isLoading: false })));
    }

    async function getSyllabusType(source) {
        await postCall(SYLLABUS_API.SYLLABUS_TYPE, null, null, source, true).then(res => {
            if (res.status === 200) {
                SetSyllabusType(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        }).finally(() => SetSyllabusType(prev => ({ ...prev, isLoading: false })));
    };

    async function getLevelList(source) {
        await postCall(LEVEL_API.LEVEL, {
            'institution-id': Number(formValues['institution-id'])
        }, null, source, true).then(res => {
            if (res.status === 200) {
                SetLevelList(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        })
            .finally(() => SetLevelList(prev => ({ ...prev, isLoading: false })));
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === "institution-id") {
            setFormValues(prev => ({ ...prev, 'grade-id': "", 'grade-section': "" }));
            getGradeBasedOnScreen({ [name]: value });
        }
        setFormValues(prev => ({ ...prev, [name]: value }));
        setFormErrors({});
    }, [setFormValues, getGradeBasedOnScreen]);

    useEffect(() => {
        SetInstituteList(prev => ({ ...prev, isLoading: true }));
        const source = axiosSource();
        getInstituteList(source);
        const source2 = axiosSource();
        getLevelList({}, source2);
        const source3 = axiosSource();
        getSyllabusType({}, source3);
        getSessionType();
        return () => {
            source.cancel()
        }
    }, []);


    useEffect(() => {
        if (!formValues['institution-id']) return;
        const result = instituteList.data?.filter(item => {
            if (item['institution-id'] === Number(formValues['institution-id'])) {
                return item;
            }
        });
        if (result && result.length > 0) {
            setInstitueType(result[0]['institution-type']);
        }
    }, [formValues['institution-id'], instituteList.data])

    useEffect(() => {

        async function arr() {

            const response = await getSyllabusId();
            const syllabus = syllabusList.data?.filter(item => item['syllabus-id'] === response.data['syllabus-id'])[0];

            if (response.data['syllabus-id']) {
                setFormValues(prev => ({ ...prev, 'syllabus-name': syllabus['syllabus-name'] }))
            }
        }
        arr()
    }, [formValues['level-id']])

    const handleBulkUpload = (e) => {
        if (!validateFileType(e.target.files[0])) {
            var validExts = [".csv"];
            toast({
                title: "Invalid file selected, valid files are " +
                    validExts.toString() +
                    " types.",
                status: 'warning',
                duration: CONSTANTS.DURATION,
            })
            return;
        }
        setFile(e.target.files[0]);
    };

    const uploadFile = useCallback((body) => {
        if (!file) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('institution-id', body['institution-id']);
        formData.append('syllabus-id', body['syllabus-id'])
        // formData.append('complete-by', formValues['complete-by'] + " 15:00:00")
        postCall(QUESTIONAIRE_API.QUESTIONAIRE_UPLOAD, formData, null, null, false).then(function (result) {
            setFile(null);
            if (ref.current) {
                ref.current.value = "";
            };
            if (viewRef.current) {
                viewRef.current.value = "";
            }
            setFormValues({
                "institution-id": "",
                "syllabus-id": "",
                // "complete-by": ""
            });
            toastByStatus(
                toast,
                result?.data?.success,
                result?.data?.error,
                result?.data?.success,
                result?.data?.error
            )
            if (result?.data?.error) {
                return;
            }
            setTimeout(() => {
                navigate(-1);
            }, 1000);
        }).catch((error) => toastErrorOnly(error)).finally(() => setIsLoading(false));
    }, [file, navigate, toast]);

    const getSyllabusId = async () => {
        const requestBody = {
            "institution-id": (formValues['institution-id'] ? Number(formValues['institution-id']) : 0),
            "grade-id": (formValues['grade-id'] ? Number(formValues['grade-id']) : 0),
            "grade-section": formValues['grade-section'],
            "level-id": (formValues['level-id'] ? Number(formValues['level-id']) : 0),
            "syllabus-type-id": (formValues['syllabus-type-id'] ? Number(formValues['syllabus-type-id']) : 0),
            "session-type-name": formValues['session-type-name']
        };
        return await postCall(SYLLABUS_API.SYLLABUS_GET_ID, requestBody, {}, null);
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        let syllabusId = null;
        if (!formValues['syllabus-id'] || formValues['syllabus-id'] == '0') {
            setIsLoading(true);
            const response = await getSyllabusId();
            toastByStatus(
                toast,
                response?.data?.success,
                response?.data?.error,
                response?.data?.success,
                response?.data?.error
            )
            setIsLoading(false);
            if (response?.data?.error) {
                return;
            }
            if (response.data['syllabus-id']) {
                syllabusId = response.data['syllabus-id'];
                setFormValues(prev => ({ ...prev, 'syllabus-id': syllabusId }));
                const syllabus = syllabusList.data?.filter(item => item['syllabus-id'] === syllabusId)[0];
                if (syllabus) {
                    setFormValues(prev => ({ ...prev, 'syllabus-name': syllabus['syllabus-name'] }))
                }
            }
        }
        const body = { ...formValues, 'syllabus-id': syllabusId };
        if (validate(body)) {
            uploadFile(body);
        }
    }, [formValues, getSyllabusId, syllabusList.data, toast, uploadFile]);

    const validate = (values) => {
        const errors = {};

        if (!values["institution-id"]) {
            errors["institution-id"] = "Institute is required!";
        }

        if (!values["grade-id"]) {
            errors["grade-id"] = "Grade is required!";
        }

        if ((institueType !== CONSTANTS.INSTITUTE_TYPE.SCHOOL) && !values["grade-section"]) {
            errors["grade-section"] = "Section is required!";
        }

        if (!values["level-id"]) {
            errors["level-id"] = "Level is required!";
        }

        if (!file) {
            errors["file-id"] = "Syllabus is required!";
        }

        if (!values["syllabus-type-id"]) {
            errors["syllabus-type-id"] = "Syllabus Type is required!";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    return (
        <>
            {(instituteList.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
            <Flex alignItems="center" justifyContent="center"  >
                <Card m={2} mt={5} >
                    <CardBody h={{ base: "87vh", lg: "125vh" }}>
                        <Flex justifyContent={'center'}>
                            <form className="bulkupload-section form-data">
                                <Heading mb={4} textAlign={"center"}>Questionnaire Upload</Heading>
                                <Flex flexDirection="column" justifyContent={'center'} minWidth={{ md: 400, lg: 400 }} maxWidth={300}>
                                    <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["institution-id"]}>
                                        <FormLabel className="form-label" htmlFor="institution-id">
                                            Institute
                                        </FormLabel>
                                        <Select
                                            name="institution-id"
                                            id="institution-id"
                                            value={formValues["institution-id"]}
                                            onChange={handleChange}
                                        >
                                            <option key="select" value={0}>
                                                -Select-
                                            </option>
                                            {instituteList?.data?.map((option, index) => (
                                                <option
                                                    key={
                                                        option["institution-id"] + option["institution-name"] + index
                                                    }
                                                    value={option["institution-id"]}
                                                >
                                                    {option["institution-name"]}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["institution-id"]}</FormErrorMessage>
                                    </FormControl>
                                    {/* <FormControl width={{ base: '100%', md: '100%' }}>
                        <FormLabel>Syllabus</FormLabel>
                            <Select
                                name="syllabus-id"
                                id="syllabus-id"
                                value={formValues["syllabus-id"]}
                                onChange={handleChange}
                                disabled={formValues['question-set-id']}
                            >
                                <option key="select" value={""}>
                                -Select-
                                </option>
                                {syllabusList?.data?.map((option, index) => (
                                <option
                                    key={
                                    option["syllabus-id"] + option["syllabus-name"] + index
                                    }
                                    value={option["syllabus-id"]}
                                >
                                    {option["syllabus-name"]}
                                </option>
                                ))}
                            </Select>
                        </FormControl> */}
                                    <FormControl width={{ base: '100%', md: '100%' }}>
                                        <FormLabel>Syllabus Type</FormLabel>
                                        <Select value={formValues['syllabus-type-id']} name="syllabus-type-id" id="syllabus-type-id" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {syllabusType.data.map((item) => (
                                                <option
                                                    key={
                                                        item['syllabus-type-id']
                                                    }
                                                    value={item['syllabus-type-id']}
                                                >
                                                    {item['syllabus-type-name']}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["syllabus-type-id"]}</FormErrorMessage>
                                    </FormControl>
                                    {/* <FormControl width={{ base: '100%', md: '100%' }}>
                            <FormLabel>Session Description</FormLabel>
                            <Input
                                isDisabled={true}
                                type="text"
                                name="syllabus-name"
                                placeholder="Enter Session Description"
                                value={formValues["syllabus-name"]}
                                onChange={handleChange}
                            /> */}
                                    {/* <FormErrorMessage>{formErrors.fname}</FormErrorMessage> */}
                                    {/* </FormControl> */}

                                    {(institueType && institueType === CONSTANTS.INSTITUTE_TYPE.COLLEGE) && <Box display={'flex'} gap={2}>
                                        <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["grade-id"]}>
                                            <FormLabel>Department</FormLabel>
                                            <Select value={formValues['grade-id']} name="grade-id" id="grade-id" onChange={handleChange}>
                                                <option value="">-Select-</option>
                                                {gradeList.data.map((option, index) => (
                                                    <option
                                                        key={
                                                            option['grade-value'] + index
                                                        }
                                                        value={option['grade-id']}
                                                    >
                                                        {option['grade-name']}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{formErrors["grade-id"]}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["grade-section"]}>
                                            <FormLabel>Year</FormLabel>
                                            <Select value={formValues['grade-section']} name="grade-section" id="grade-section" onChange={handleChange}>
                                                <option value="">-Select-</option>
                                                {CONSTANTS.YEAR.map((item, index) => (
                                                    <option
                                                        key={
                                                            item?.name + '-grade-section'
                                                        }
                                                        value={item?.name}
                                                    >
                                                        {item?.value}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{formErrors["grade-section"]}</FormErrorMessage>
                                        </FormControl>
                                    </Box>}

                                    {/* Grade, section, session */}

                                    {(institueType && institueType !== CONSTANTS.INSTITUTE_TYPE.COLLEGE) ?
                                        <Box display={'flex'} gap={2}>
                                            <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["grade-id"]}>
                                                <FormLabel>Grade</FormLabel>
                                                <Select isDisabled={!isAdmin} value={formValues['grade-id']} name="grade-id" id="grade-id" onChange={handleChange}>
                                                    <option value="">-Select-</option>
                                                    {gradeList.data.map((option, index) => (
                                                        <option
                                                            key={
                                                                option["grade-value"] + index
                                                            }
                                                            value={option["grade-id"]}
                                                        >
                                                            {option["grade-name"]}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <FormErrorMessage>{formErrors["grade-id"]}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["grade-section"]}>
                                                <FormLabel>Section</FormLabel>
                                                <Select isDisabled={!isAdmin} value={formValues['grade-section']} name="grade-section" id="grade-section" onChange={handleChange}>
                                                    <option value="">-Select-</option>
                                                    {CONSTANTS.SECTION.map((option, index) => (
                                                        <option
                                                            key={
                                                                option + index
                                                            }
                                                            value={option}
                                                        >
                                                            {option}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <FormErrorMessage>{formErrors["grade-section"]}</FormErrorMessage>
                                            </FormControl>
                                        </Box> :
                                        <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["session-type-name"]}>
                                            <FormLabel>Session Type</FormLabel>
                                            <Select isDisabled={!isAdmin} value={formValues['session-type-name']} name="session-type-name" id="session-type-name" onChange={handleChange}>
                                                <option value="">-Select-</option>
                                                {sessionList.data.map((option, index) => (
                                                    <option
                                                        key={
                                                            option["session_type"] + index
                                                        }
                                                        value={option["session_type"]}
                                                    >
                                                        {option["session_type"]}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{formErrors["session-type-name"]}</FormErrorMessage>
                                        </FormControl>}

                                    <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["level-id"]}>
                                        <FormLabel>Level</FormLabel>
                                        <Select isDisabled={!isAdmin} value={formValues['level-id']} name="level-id" id="level-id" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {levelList.data.map((option, index) => (
                                                <option
                                                    key={
                                                        option["level-value"] + index
                                                    }
                                                    value={option["level-id"]}
                                                >
                                                    {option["level-name"]}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["level-id"]}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl width={{ base: '100%', md: '100%' }}>
                                        <FormLabel>Session Description</FormLabel>
                                        <Input
                                            isDisabled={true}
                                            type="text"
                                            name="syllabus-name"
                                            placeholder="Enter Session Description"
                                            value={formValues["syllabus-name"]}
                                            onChange={handleChange}
                                        />
                                        {/* <FormErrorMessage>{formErrors.fname}</FormErrorMessage> */}
                                    </FormControl>
                                    {/* <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["complete-by"]}>
                            <FormLabel>Complete By</FormLabel>
                            <Input type="date" 
                            // min={CONSTANTS.CURRENT_DATE.toLocaleDateString('en-CA')}
                            value={formValues['complete-by']}
                            name="complete-by" id="complete-by" onChange={handleChange} />
                            <FormErrorMessage>{formErrors["complete-by"]}</FormErrorMessage>
                        </FormControl> */}
                                    <FormControl width={{ base: '100%', md: '100%' }} isInvalid={formErrors["file-id"]}>
                                        <FormLabel>
                                            Upload Questionnaire
                                        </FormLabel>
                                        {/* <input
                                    ref={ref}
                                    id="bulk_upload"
                                    type="file"
                                    name="upload"
                                    style={{ display: "none" }}
                                    accept=".pdf,.doc,.csv,.docx,application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={handleBulkUpload}
                                /> */}
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<Icon as={FiFile} />}
                                            />
                                            <input type='file' accept=".pdf, .doc,.csv,.docx, .xlsx, .xls, application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                name={'upload'} ref={ref} style={{ display: 'none' }} onChange={handleBulkUpload}></input>
                                            <Input
                                                placeholder={"Browse File"}
                                                readOnly={'true'}
                                                ref={viewRef}
                                                onClick={() => ref.current.click()}
                                                value={file?.name || null}
                                            />
                                            {(file && file.name) && <InputRightElement
                                                pointerEvents="visible"
                                                cursor={'pointer'}
                                                onClick={() => {
                                                    setFile(null);
                                                    if (ref.current) {
                                                        ref.current.value = "";
                                                    }
                                                    if (viewRef.current) {
                                                        viewRef.current.value = "";
                                                    }
                                                }}
                                                children={<Icon as={FiX} />}
                                            />}
                                        </InputGroup>
                                        <FormErrorMessage>{formErrors["file-id"]}</FormErrorMessage>
                                    </FormControl>
                                    {/* {(file && file.name) && <p className="upload">{file.name}</p>} */}
                                    <Box mt={2}>
                                        <Flex align={'center'} justify={'center'} gap={2}>
                                            <Button colorScheme='blue' type="submit" onClick={handleSubmit}>upload</Button>
                                            <Button type="button" onClick={
                                                () => { navigate(-1) }
                                            }>Cancel</Button>
                                        </Flex>
                                    </Box>
                                    <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <a style={{
                                            color: '#3182ce'
                                        }} href={QuestionFile} target="_blank" download="sample_question" rel="noreferrer">Download sample format</a>
                                    </Box>
                                </Flex>
                            </form>
                        </Flex>
                    </CardBody></Card>
            </Flex>
        </>
    )
}

export default QuestionaireBulkUpload
