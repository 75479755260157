export const CancellationPolicy = {
  title: "Cancellation and Refund Policy",
  contents: [
    
    {
      head: "Cancellation and Refund Policy",
      details: `Cancellation Policy: Course / Service Enrollment Cancellation
        • Cancellation by User:
          o You may cancel your enrollment for a course/service up to 15 days before the course start date to receive a full refund.
          o If you cancel your enrollment within 15 days but more than 7 days before the course/service start date, you will be eligible for a 50% refund.
          o No refunds will be provided if you cancel your enrollment 7 days or less before the course/service start date or after the course/service has commenced.
        • Cancellation by A3Minds:
          o A3Minds reserves the right to cancel any course/service due to insufficient enrollment, instructor availability, or other unforeseen circumstances. In such cases, you will be notified as soon as possible, and a full refund will be provided.
          o If a course/service is rescheduled or moved online/offline, participants will be given the option to attend the rescheduled course/service, move to the online/offline format, or receive a full refund.
        Refund Policy
        Refund Process
        • To request a refund, please contact our support team at info@a3minds.com with your enrollment or subscription details and the reason for the cancellation.
        • Refunds will be processed within 10 business days of receiving your request.
        • Refunds will be issued using the original payment method. If this is not possible, an alternative method will be arranged.
        Non-Refundable Items
        • The following items are non-refundable:
          o Materials and resources provided as part of the course or service that have been accessed or downloaded.
          o Courses or services that have already commenced or been completed.
          o Any administrative fees associated with the payment process.`,
    },
    {
      head: "Shipping and Delivery",
      details: `Shipping and Delivery is not applicable for our services.`,
    },
    {
      head: "Changes to Cancellation Policy",
      details: `A3Minds may revise these cancellation policy from time to time. Revised cancellation policy will apply to the use of this website from the date of the publication of the revised cancellation policy on this website. We encourage you to review this policy periodically to stay informed about our cancellation policy.`,
    },
    {
      head: "Governing Law",
      details: `These cancellation policy are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in Chennai.`,
    },
    {
      head: "Contact Information",
      details: `If you have any questions about these cancellation policy, please contact us at:
        • Email: info@a3minds.com`,
    },
  ],
};
