import { CONSTANTS } from "./constants";

export const initialValues = {
  institute: "Third Party",
  program: "A21Skills",
  programDesc:
    "Adaption to Technology and 21st Century Skills (6 Hours Program on 13th, 14th and 15th July 2024 7 PM - 9 PM)",
  fname: "",
  lname: "",
  email: "",
  phone: "",
  gender: "",
  age: "",
  country: "",
  city: "",
  state: "",
  utype: "Student",
  active: 0,
};

export const userSearch = {
  searchValue: "",
  type: "",
};

export const validate = (values, setFormErrors) => {
  const errors = {};

  if (!values.fname) {
    errors.fname = "First Name is required!";
  }

  if (!values.lname) {
    errors.lname = "Last Name is required!";
  }

  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!CONSTANTS.EMAIL_REGEX.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }

  if (!values.phone) {
    errors.phone = "Phone Number is required!";
  }

  if (
    values.phone &&
    (values.phone.toString().length > 10 || values.phone.toString().length < 10)
  ) {
    errors.phone = "Phone Number length should be 10 digits";
  }

  if (!errors.phone && !CONSTANTS.PHONE_REGEX.test(values.phone)) {
    errors.phone = "Enter valid Phone Number";
  }

  if (!values.gender) {
    errors.gender = "Select Gender";
  }
  if (!values.age) {
    errors.age = "Age is required!";
  }
  if (!values.country) {
    errors.country = "Select Country";
  }
  if (!values.state) {
    errors.state = "Select State";
  }
  if (!values.city) {
    errors.city = "Select City";
  }

  if (!values.type) {
    errors.type = "Select Type";
  }
  if (!values.program || !values.progrmId) {
    errors.program = "Select Program";
  }
  if (!values.transId) {
    errors.transId = "Enter Transaction Id";
  }
  if (values.transId) {
    const isValidTransId = /^[a-zA-Z0-9]*$/.test(values.transId); // Allow alphanumeric characters
    if (!isValidTransId || values.transId.length > 50 || values.transId === '') {
      errors.transId = "Please enter correct transaction id.";
    }
  }

  if (values.age) {
    const isValidAge = /^[a-zA-Z0-9]*$/.test(values.age); // Allow alphanumeric characters
    if (!isValidAge || values.age.length > 2 || !(values.age >= 10 && values.age <= 99)) {
      errors.age = "Please enter valid age.";
    }
  }

  // Additional validations based on selected type
  if (values.type === 'Student') {
    if (!values.institute_id) {
      errors.institute_id = 'Institute selection is required';
    }
    if (!values.grade_id) {
      errors.grade_id = 'Grade selection is required';
    }
    if (!values.grade_section_id) {
      errors.grade_section_id = 'Grade section selection is required';
    }
  } else if (values.type === 'Professional') {
    if (!values.profession) {
      errors.profession = 'Profession input is required';
    }
  }

  setFormErrors(errors);
  return Object.keys(errors).length === 0;
};
