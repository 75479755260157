import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  Text,
  UnorderedList,
  ListItem,
  useTheme
} from '@chakra-ui/react'

import adaptation2 from '../../images/adaptation2.jpg';
import adaptation3 from '../../images/adaptation3.jpg';
import adaptation4 from '../../images/adaptation4.jpg';
import adaptationImg from '../../images/adaptation.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Adaptation() {
  const theme = useTheme();
  const { imageFlex, flexText, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>ADAPTATION TO TECHNOLOGY AND 21ST-CENTURY SKILLS</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="adaptation" /> */}
        <img src={adaptationImg} alt="adaptationImg" />
      </Box>
      <Box>
        <Flex mt={5} mb={5} alignItems={'center'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont}>
                Adaptation to technology and the development of 21st-century skills are crucial in today's rapidly changing world. As technology continues to advance and reshape various aspects of our lives, individuals and societies must proactively adapt to these changes to thrive. Likewise, 21st-century skills are essential for success in the modern world, as they encompass a range of abilities that go beyond traditional academic knowledge and focus on preparing individuals to navigate complex,
                interconnected, and dynamic environments.
              </Text>
              <Text fontSize={fFont}>
                Our "ADAPTATION TO TECHNOLOGY AND 21 ST - CENTURY SKILLS" Training Programs are broadly divided into three categories which covers the entire spectrum of life.
              </Text>
            </Box>
            <Box m={5}>
              <Center>
                <Image src={adaptation2} w={'100%'} mb={1} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText} >
                  Ability to comprehend and adopt new technology
                </Text>
              </Flex>
              <Center>
                <Image src={adaptation3} w={'100%'} mb={1} mt={12} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText} >
                  Integration of learning across disciplines
                </Text>
              </Flex>
              <Center>
                <Image src={adaptation4} w={'100%'} mb={1} mt={12} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText} >
                  Improve "Information and Communication Technology" (ICT) proficiency
                </Text>
              </Flex>

              <Text mt={8} mb={4} fontSize={{ base: "md", md: "xl", lg: "2xl" }}>
                <strong>
                  ADAPTATION TO TECHNOLOGY AND 21 ST - CENTURY SKILLS Training Programs has been designed to address the following Modules.
                </strong>
              </Text>
              <UnorderedList fontSize={fFont}>
                <ListItem><strong>Digital Literacy:</strong> Understanding how to effectively use digital tools, devices, and platforms for communication, information retrieval, and problem-solving.</ListItem>
                <ListItem><strong>Technological Fluency:</strong> Being comfortable with adopting and using new technologies, as well as staying updated with the latest advancements.</ListItem>
                <ListItem><strong>Cybersecurity Awareness:</strong> Being aware of online threats, practicing safe internet habits, and protecting personal and sensitive information.</ListItem>
                <ListItem><strong>Adaptive Learning:</strong> Utilizing digital resources and online courses to continuously learn and acquire new skills as technology evolves.</ListItem>
                <ListItem><strong>Remote Collaboration:</strong> Effectively working with others in virtual environments, using tools like video conferencing, project management software, and online collaboration platforms.</ListItem>
                <ListItem><strong>Creativity and Innovation:</strong> Generating original ideas, thinking outside the box, and adapting to changing circumstances with imaginative approaches.</ListItem>
                <ListItem><strong>Global and Cultural Awareness:</strong> Understanding and appreciating cultural differences, and being aware of global issues and interconnectedness.</ListItem>
                <ListItem><strong>Media Literacy:</strong> Analysing and critically evaluating media messages, recognizing bias, and understanding the influence of media on society.</ListItem>
              </UnorderedList>
            </Box>
          </Container>

        </Flex>
      </Box>
    </>
  )
}