


import { CONSTANTS } from "./constants";

export const initialValues = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  utype: "",
  active: 0,
  "lni-user-id": 0,
  "auth59-user-id": 0,
};

export const userSearch = {
  searchValue: "",
  type: "",
};

export const validate = (values, setFormErrors) => {
  const errors = {};

  if (!values.fname) {
    errors.fname = "First Name is required!";
  }

  if (!values.lname) {
    errors.lname = "Last Name is required!";
  }

  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!CONSTANTS.EMAIL_REGEX.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }

  if (!values.phone) {
    errors.phone = "Phone Number is required!";
  }

  if (
    values.phone &&
    (values.phone.toString().length > 15 || values.phone.toString().length < 10)
  ) {
    errors.phone = "Phone Number length should be between 10 to 15 digits";
  }

  if (!errors.phone && !CONSTANTS.PHONE_REGEX.test(values.phone)) {
    errors.phone = "Enter valid Phone Number";
  }

  if (!values.utype) {
    errors.utype = "Select User Type";
  }
  setFormErrors(errors);
  return Object.keys(errors).length === 0;
};