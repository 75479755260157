import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { axiosSource, ccode, postCall } from '../../services/httpService';
import { GRADE_API, INSTITUTE_API, LEVEL_API, SYLLABUS_API } from '../../services/apiRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { FormErrorMessage, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Select, Box, Button, Card, CardBody, useToast } from "@chakra-ui/react";
import { FiFile, FiX } from "react-icons/fi";
import { CONSTANTS } from '../../services/constants';
import { AuthContext } from '../../App';
import { dataObj, toastByStatus, toastErrorOnly, validateFileType } from '../../services/utils';
import Multiselect from 'multiselect-react-dropdown';

function CreateEditSyllabus() {
    const [context] = useContext(AuthContext);
    const initialValues = {
        "institution-id": "",
        "syllabus-id": 0,
        "grade-id": "",
        "level-id": "",
        "file-id": "",
        "completed-on": "",
        "syllabus-name": "",
        "grade-section": "",
        "syllabus-type-id": "",
        "session-type-name": ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [instituteList, SetInstituteList] = useState({ ...dataObj });
    const [gradeList, SetGradeList] = useState({ ...dataObj });
    const [levelList, SetLevelList] = useState({ ...dataObj });
    const [syllabusDetail, setSyllabusDetails] = useState({ ...dataObj, data: null });
    const [syllabusType, SetSyllabusType] = useState({ ...dataObj });
    const [institueType, setInstitueType] = useState("");
    const [sessionList, SetSessionList] = useState({ ...dataObj });
    const [file, setFile] = useState();
    const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);
    const navigate = useNavigate();
    const ref = useRef();
    const viewRef = useRef();
    const toast = useToast();
    let { id } = useParams();

    async function getSyllabusDetails(syllabusId, source) {
        const requestBody = { "syllabus-id": (syllabusId ? Number(syllabusId) : 0) };
        await postCall(SYLLABUS_API.SYLLABUS_LIST, requestBody, {}, source).then(res => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                setSyllabusDetails(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => toastErrorOnly(error)).finally(() => setSyllabusDetails(prev => ({ ...prev, isLoading: false })));
    };

    useEffect(() => {
        const source = axiosSource();
        if (id) {
            setSyllabusDetails(prev => ({ ...prev, isLoading: true }));
            getSyllabusDetails(Number(id), source)
        }
        return () => {
            source.cancel()
        }
    }, [id]);

    useEffect(() => {
        if (!syllabusDetail.data) return;
        setFormValues({
            'syllabus-name': syllabusDetail.data['syllabus-name'],
            'institution-id': syllabusDetail.data['institution-id'],
            'file-id': syllabusDetail.data['file-id'],
            'complete-by': new Date(syllabusDetail.data['complete-by']).toLocaleDateString('en-CA'),
            'syllabus-id': syllabusDetail.data['syllabus-id'],
            "grade-id": syllabusDetail.data['grade-id'],
            "level-id": syllabusDetail.data['level-id'],
            "grade-section": syllabusDetail.data['grade-section'],
            "session-type-name": syllabusDetail.data['session-type-name'],
            "syllabus-type-id": syllabusDetail.data['type-id'],
            "completed-on": syllabusDetail.data['completed-on'] ? new Date(syllabusDetail.data['completed-on']).toLocaleDateString('en-CA') : "",
        })
    }, [syllabusDetail.data]);

    const getGradeBasedOnScreen = useCallback((data) => {
        const result = instituteList.data?.filter(item => {
            const val = data;
            if (item['institution-id'] === Number(val['institution-id'])) {
                return item;
            }
        });
        // console.log('result', result);
        if (result && result.length > 0) {
            SetGradeList(prev => ({ ...prev, isLoading: true }));
            getGradeList(result[0]['institution-id'], result[0]['institution-type']);
        }
    }, [instituteList.data])

    useEffect(() => {
        if (syllabusDetail.data) {
            getGradeBasedOnScreen(syllabusDetail.data);
        }
    }, [syllabusDetail.data, getGradeBasedOnScreen]);

    async function getInstituteList(source) {
        let body = {};
        if (!isAdmin) {
            body = { ...body, "user-id": context?.user_data['lni-user-id'] }
        }
        await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true).then(res => {
            // console.log('res?.data', res?.data);
            if (res.status === 200) {
                SetInstituteList(prev => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        }).finally(() => SetInstituteList(prev => ({ ...prev, isLoading: false })));
    };

    async function getGradeList(id, type) {
        await postCall(GRADE_API.GRADE, {
            'institution-id': Number(id),
            'institution-type': type
        }, null, null, true).then(res => {
            if (res.status === 200) {
                SetGradeList(prev => ({ ...prev, data: res?.data }));
                // setFormValues(prev => ({ ...prev, "grade-id": syllabusDetail.data['grade-id'] }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        }).finally(() => SetGradeList(prev => ({ ...prev, isLoading: false })));
    };

    async function getSyllabusType(source) {
        await postCall(SYLLABUS_API.SYLLABUS_TYPE, null, null, source, true).then(res => {
            if (res.status === 200) {
                SetSyllabusType(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        }).finally(() => SetSyllabusType(prev => ({ ...prev, isLoading: false })));
    };

    async function getLevelList(source) {
        await postCall(LEVEL_API.LEVEL, {
            'institution-id': Number(formValues['institution-id'])
        }, null, source, true).then(res => {
            if (res.status === 200) {
                SetLevelList(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        })
            .finally(() => SetLevelList(prev => ({ ...prev, isLoading: false })));
    };

    const updateCompleteOn = useCallback((formValues) => {
        const syllabusBody = {
            "syllabus-id": id ? Number(id) : 0,
            "complete-by": formValues['complete-by'] + " 17:00:00",
            "completed-on": formValues['completed-on'] + " 17:00:00",
        }
        postCall(SYLLABUS_API.SYLLABUS_UPDATE_COMPLETED, syllabusBody).then((res) => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                if (res?.data?.error) {
                    return;
                }
                setFormValues({
                    "institution-id": "",
                    "syllabus-id": 0,
                    "grade-id": "",
                    "level-id": "",
                    "file-id": "",
                    "complete-by": "",
                    "syllabus-name": ""
                });
            }
            setTimeout(() => {
                navigate('/syllabus');
            }, 1000);
        }).catch((error) => toastErrorOnly(error)).finally(() => {
            setIsLoading(false);
        });
    }, [formValues, id, navigate, toast]);

    const createSyllabus = (finalFormValues, multiple = false) => {
        if (!isAdmin) { // update complete on
            updateCompleteOn(finalFormValues);
            return;
        }
        delete finalFormValues['completed-on'];
        const SyllabusBody = { ...finalFormValues, 'syllabus-description': finalFormValues['syllabus-name'] };
        return postCall(SYLLABUS_API.SYLLABUS_CREATE, SyllabusBody);
    };

    const getSessionType = useCallback(
        async (source) => {
            await postCall(SYLLABUS_API.SYLLABUS_SESSION, null, {}, source)
                .then((res) => {
                    if (res.status === 200) {
                        SetSessionList((prev) => ({ ...prev, data: res?.data }));
                    }
                });
        },
        []
    );

    const getSyllabusId = async () => {
        const requestBody = {
            "institution-id": (formValues['institution-id'] ? Number(formValues['institution-id']) : 0),
            "grade-id": formValues['grade-id'],
            "grade-section": formValues['grade-section'],
            "level-id": formValues['level-id'],
            "syllabus-type-id": formValues['syllabus-type-id'],
            "session-type-name": formValues['session-type-name']
        };
        await postCall(SYLLABUS_API.SYLLABUS_GET_ID, requestBody, {}, null)
            .then((res) => {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
            });
    }

    const uploadFile = useCallback(async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('institution-id', formValues['institution-id']);
        let config = {};
        config["headers"] = { "Content-Type": "application/json", 'ccode': ccode };
        await postCall(SYLLABUS_API.SYLLABUS_UPLOAD, formData, null, null, false).then(async function (result) {
            // console.log(result, result?.data?.files_added[0]["file-id"]);
            setFormValues(prev => ({ ...prev, "file-id": result?.data?.files_added[0]["file-id"] }));
            // finalFormValues used to pass latest state data to API
            const finalFormValues = {
                ...formValues,
                "file-id": result?.data?.files_added[0]["file-id"],
                "institution-id": Number(formValues["institution-id"]),
                "grade-id": formValues["grade-id"],
                "level-id": formValues["level-id"],
                "grade-section": formValues["grade-section"]
            };
            if (formValues['complete-by']) {
                finalFormValues["complete-by"] = formValues['complete-by'] + " 17:00:00";
            }
            setFile(null);
            if (ref.current) {
                ref.current.value = "";
            }
            if (viewRef.current) {
                viewRef.current.value = "";
            }
            // toastByStatus(
            //     toast,
            //     result?.data?.success,
            //     result?.data?.error,
            //     'File Upload successfully',
            //     result?.data?.error
            // )
            if (result?.data?.error) {
                setIsLoading(false)
                return;
            }
            if (validate(finalFormValues)) {
                if ((institueType === CONSTANTS.INSTITUTE_TYPE.SCHOOL) && (finalFormValues["grade-section"].length > 0)) {
                    setIsLoading(true)
                    try {
                        let response = null;
                        for (let i = 0; i < finalFormValues["grade-section"].length; i++) {
                            let body = { ...finalFormValues, "grade-section": finalFormValues["grade-section"][i] };
                            response = await createSyllabus(body, true);
                        }
                        toastByStatus(
                            toast,
                            response?.data?.success,
                            response?.data?.error,
                            response?.data?.success,
                            response?.data?.error
                        )
                        if (response?.data?.error) {
                            return;
                        }
                        setFormValues({
                            "institution-id": "",
                            "syllabus-id": 0,
                            "grade-id": "",
                            "level-id": "",
                            "file-id": "",
                            "complete-by": "",
                            "syllabus-name": ""
                        });
                        setTimeout(() => {
                            navigate('/syllabus');
                        }, 1000);
                    } catch (e) {
                        toastErrorOnly(e)
                    }
                    finally {
                        setIsLoading(false)
                    }
                } else {
                    try {
                        const response = await createSyllabus(finalFormValues, false);
                        toastByStatus(
                            toast,
                            response?.data?.success,
                            response?.data?.error,
                            response?.data?.success,
                            response?.data?.error
                        )
                        if (response?.data?.error) {
                            return;
                        }
                        setFormValues({
                            "institution-id": "",
                            "syllabus-id": 0,
                            "grade-id": "",
                            "level-id": "",
                            "file-id": "",
                            "complete-by": "",
                            "syllabus-name": ""
                        });
                        setTimeout(() => {
                            navigate('/syllabus');
                        }, 1000);
                    } catch (e) { toastErrorOnly(e) }
                }
            }
        }).catch((error) => toastErrorOnly(error)).finally(() => setIsLoading(false));
    }, [file, formValues, syllabusDetail.data]);

    useEffect(() => {
        if (!id && !isAdmin) { // restricted non adminn use to create
            navigate('/syllabus');
        }
        SetInstituteList(prev => ({ ...prev, isLoading: true }));
        const source = axiosSource();
        getInstituteList(source);
        const source2 = axiosSource();
        getLevelList({}, source2);
        const source3 = axiosSource();
        getSyllabusType({}, source3)
        getSessionType();
        return () => {
            source.cancel()
            source2.cancel()
            source3.cancel()
        }
    }, []);

    useEffect(() => {
        if (!formValues['institution-id']) return;
        const result = instituteList.data?.filter(item => {
            if (item['institution-id'] === Number(formValues['institution-id'])) {
                return item;
            }
        });
        if (result && result.length > 0) {
            setInstitueType(result[0]['institution-type']);
        }
        // if(result['institution-type'] === CONSTANTS.INSTITUTE_TYPE.SCHOOL) {
        //     SetGradeList(prev => ({ ...prev, data: CONSTANTS.GRADE }));
        // } else {
        //     SetGradeList(prev => ({ ...prev, data: CONSTANTS.HOURS }));
        // }
    }, [formValues['institution-id'], instituteList.data])


    useEffect(() => {
        if (!syllabusDetail.data || (syllabusDetail.data && !syllabusDetail?.data['institution-id'])) return;
        if (instituteList.data && instituteList.data.length === 0) return;
        const result = instituteList.data?.filter(item => {
            if (item['institution-id'] === Number(syllabusDetail.data['institution-id'])) {
                return item;
            }
        });
        if (result && result.length > 0) {
            setInstitueType(result[0]['institution-type']);
        }
    }, [syllabusDetail.data, instituteList.data])

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        // console.log('name', name, value);
        if (name === "institution-id") {
            setFormValues(prev => ({ ...prev, 'grade-id': "", 'grade-section': "" }));
            getGradeBasedOnScreen({ [name]: value });
        }
        setFormValues(prev => ({ ...prev, [name]: value }));
        setFormErrors({});
    }, [setFormValues, getGradeBasedOnScreen]);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (file && formValues['institution-id']) {
            await uploadFile();
        } else {
            if (validate(formValues)) {
                if ((institueType === CONSTANTS.INSTITUTE_TYPE.SCHOOL) && (formValues["grade-section"].length > 0)) {
                    setIsLoading(true)
                    try {
                        let response = null;
                        for (let i = 0; i < formValues["grade-section"].length; i++) {
                            let body = { ...formValues, "grade-section": formValues["grade-section"][i] };
                            response = await createSyllabus(body, true);

                        }
                        toastByStatus(
                            toast,
                            response?.data?.success,
                            response?.data?.error,
                            response?.data?.success,
                            response?.data?.error
                        )
                        if (response?.data?.error) {
                            return;
                        }
                        setFormValues({
                            "institution-id": "",
                            "syllabus-id": 0,
                            "grade-id": "",
                            "level-id": "",
                            "file-id": "",
                            "complete-by": "",
                            "syllabus-name": ""
                        });
                        setTimeout(() => {
                            navigate('/syllabus');
                        }, 1000);
                    } catch (e) {
                        toastErrorOnly(e)
                    } finally {
                        setIsLoading(false)
                    }
                } else {
                    const response = await createSyllabus(formValues, false);
                    toastByStatus(
                        toast,
                        response?.data?.success,
                        response?.data?.error,
                        response?.data?.success,
                        response?.data?.error
                    )
                    if (response?.data?.error) {
                        return;
                    }
                    setFormValues({
                        "institution-id": "",
                        "syllabus-id": 0,
                        "grade-id": "",
                        "level-id": "",
                        "file-id": "",
                        "complete-by": "",
                        "syllabus-name": ""
                    });
                    setTimeout(() => {
                        navigate('/syllabus');
                    }, 1000);
                }
            }
        };
    }, [file, formValues, uploadFile, institueType, toast]);

    const validate = (values) => {
        const errors = {};

        if (!values["institution-id"]) {
            errors["institution-id"] = "Institute is required!";
        }

        if (!values["file-id"]) {
            errors["file-id"] = "Syllabus is required!";
        }

        if (!values["grade-id"]) {
            errors["grade-id"] = "Grade is required!";
        }

        if ((institueType !== CONSTANTS.INSTITUTE_TYPE.SCHOOL) && (!values["grade-section"])) {
            errors["grade-section"] = "Section is required!";
        }

        if ((institueType === CONSTANTS.INSTITUTE_TYPE.SCHOOL) && (values["grade-section"].length === 0)) {
            errors["grade-section"] = "Section is required!";
        }

        if (!values["level-id"]) {
            errors["level-id"] = "Level is required!";
        }

        if (!values["complete-by"]) {
            errors["complete-by"] = "Complete By is required!";
        }

        if (!values["syllabus-name"]) {
            errors["syllabus-name"] = "Session Description is required!";
        }

        if (!values["syllabus-type-id"]) {
            errors["syllabus-type-id"] = "Syllabus Type is required!";
        }

        if ((institueType !== CONSTANTS.INSTITUTE_TYPE.SCHOOL) && !values["session-type-name"]) {
            errors["session-type-name"] = "Session Type is required!";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleBulkUpload = (e) => {
        setFile(e.target.files[0]);
    };

    const onMultiSelect = (e) => {
        setFormValues(prev => ({ ...prev, 'grade-section': e }))
    }

    return (
        <>
            {(instituteList.isLoading || gradeList.isLoading || levelList.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
            <Flex alignItems="center" justifyContent="center">
                <Card m={2} mt={5}>
                    <CardBody h={{ base: "87vh", lg: "125vh" }}>
                        <form onSubmit={handleSubmit}>
                            <Flex flexDirection="column" minWidth={{ md: 400, lg: 400 }} maxWidth={300}>
                                <Heading mb={4} alignSelf={'center'}>{id ? 'Edit' : 'Create'} Syllabus</Heading>
                                <FormControl isInvalid={formErrors["institution-id"]}>
                                    <FormLabel>Institute</FormLabel>
                                    <Select size='sm' isDisabled={!isAdmin} value={formValues['institution-id']} name="institution-id" id="institution-id" onChange={handleChange}>
                                        <option value="">-Select-</option>
                                        {instituteList && instituteList?.data?.map((option, index) => (
                                            <option
                                                key={
                                                    option["institution-id"] + option["institution-name"] + index
                                                }
                                                value={option["institution-id"]}
                                            >
                                                {option["institution-name"]}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{formErrors["institution-id"]}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={formErrors["syllabus-type-id"]}>
                                    <FormLabel>Syllabus Type</FormLabel>
                                    <Select
                                        isDisabled={!isAdmin} size='sm' value={formValues['syllabus-type-id']} name="syllabus-type-id" id="syllabus-type-id" onChange={handleChange}>
                                        <option value="">-Select-</option>
                                        {syllabusType.data.map((item) => (
                                            <option
                                                key={
                                                    item['syllabus-type-id']
                                                }
                                                value={item['syllabus-type-id']}
                                            >
                                                {item['syllabus-type-name']}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{formErrors["syllabus-type-id"]}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={formErrors['syllabus-name']}>
                                    <FormLabel>Session Description</FormLabel>
                                    <Input
                                        size='sm'
                                        isDisabled={!isAdmin}
                                        type="text"
                                        name="syllabus-name"
                                        placeholder="Enter Session Description"
                                        value={formValues["syllabus-name"]}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage>{formErrors['syllabus-name']}</FormErrorMessage>
                                </FormControl>
                                {isAdmin && <FormControl isInvalid={formErrors["file-id"]}>
                                    <FormLabel htmlFor="bulk_upload">
                                        Syllabus
                                    </FormLabel>
                                    {/* <Input
                                    ref={ref}
                                    id="bulk_upload"
                                    type="file"
                                    name="upload"
                                    style={{ display: "none" }}
                                    accept=".pdf,.doc,.csv,.docx,application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    onChange={handleBulkUpload}
                                /> */}
                                    <InputGroup size='sm'>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<Icon as={FiFile} />}
                                        />
                                        <input type='file' accept=".pdf, .doc,.csv, .docx, .xlsx, .xls, application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            name={'upload'} ref={ref} style={{ display: 'none' }} onChange={handleBulkUpload}></input>
                                        <Input
                                            placeholder={"Browse File"}
                                            readOnly={'true'}
                                            ref={viewRef}
                                            onClick={() => ref.current.click()}
                                            value={file?.name || null}
                                        />
                                        {(file && file.name) && <InputRightElement
                                            pointerEvents="visible"
                                            cursor={'pointer'}
                                            onClick={() => {
                                                setFile(null);
                                                if (ref.current) {
                                                    ref.current.value = "";
                                                }
                                                if (viewRef.current) {
                                                    viewRef.current.value = "";
                                                }
                                            }}
                                            children={<Icon as={FiX} />}
                                        />}
                                    </InputGroup>
                                    <FormErrorMessage>{formErrors["file-id"]}</FormErrorMessage>
                                </FormControl>}

                                {(institueType && institueType === CONSTANTS.INSTITUTE_TYPE.COLLEGE) && <Box display={'flex'} gap={2}>
                                    <FormControl width={'50%'} isInvalid={formErrors["grade-id"]}>
                                        <FormLabel>Department</FormLabel>
                                        <Select size='sm' isDisabled={!isAdmin} value={formValues['grade-id']} name="grade-id" id="grade-id" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {gradeList.data.map((option, index) => (
                                                <option
                                                    key={
                                                        option['grade-value'] + index
                                                    }
                                                    value={option['grade-id']}
                                                >
                                                    {option['grade-name']}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["grade-id"]}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl width={'50%'} isInvalid={formErrors["grade-section"]}>
                                        <FormLabel>Year</FormLabel>
                                        <Select size='sm' isDisabled={!isAdmin} value={formValues['grade-section']} name="grade-section" id="grade-section" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {CONSTANTS.YEAR.map((item, index) => (
                                                <option
                                                    key={
                                                        item?.name + '-grade-section'
                                                    }
                                                    value={item?.name}
                                                >
                                                    {item?.value}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["grade-section"]}</FormErrorMessage>
                                    </FormControl>
                                </Box>}

                                {/* Grade, section, session */}

                                {(institueType && institueType !== CONSTANTS.INSTITUTE_TYPE.COLLEGE) ?
                                    <>
                                        <FormControl isInvalid={formErrors["grade-id"]}>
                                            <FormLabel>Grade</FormLabel>
                                            <Select size='sm' isDisabled={!isAdmin} value={formValues['grade-id']} name="grade-id" id="grade-id" onChange={handleChange}>
                                                <option value="">-Select-</option>
                                                {gradeList.data.map((option, index) => (
                                                    <option
                                                        key={
                                                            option["grade-value"] + index
                                                        }
                                                        value={option["grade-id"]}
                                                    >
                                                        {option["grade-name"]}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{formErrors["grade-id"]}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={formErrors["grade-section"]}>
                                            <FormLabel>Section</FormLabel>
                                            {/* <Select size='sm' isDisabled={!isAdmin} value={formValues['grade-section']} name="grade-section" id="grade-section" onChange={handleChange}>
                                    <option value="">-Select-</option>
                                    {CONSTANTS.SECTION.map((option, index) => (
                                        <option
                                            key={
                                                option + index
                                            }
                                            value={option}
                                        >
                                            {option}
                                        </option>
                                    ))}
                                </Select> */}
                                            <Box>
                                                <Multiselect
                                                    isObject={false}
                                                    selectedValues={formValues['grade-section']}
                                                    options={CONSTANTS.SECTION}
                                                    onSelect={onMultiSelect}
                                                    onRemove={onMultiSelect}
                                                /></Box>
                                            <FormErrorMessage>{formErrors["grade-section"]}</FormErrorMessage>
                                        </FormControl>
                                    </> :
                                    <FormControl isInvalid={formErrors["session-type-name"]}>
                                        <FormLabel>Session Type</FormLabel>
                                        <Select
                                            isDisabled={!isAdmin} size='sm' value={formValues['session-type-name']} name="session-type-name" id="session-type-name" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {sessionList.data.map((option, index) => (
                                                <option
                                                    key={
                                                        option["session_type"] + index
                                                    }
                                                    value={option["session_type"]}
                                                >
                                                    {option["session_type"]}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{formErrors["session-type-name"]}</FormErrorMessage>
                                    </FormControl>}

                                <FormControl isInvalid={formErrors["level-id"]}>
                                    <FormLabel>Level</FormLabel>
                                    <Select size='sm' isDisabled={!isAdmin} value={formValues['level-id']} name="level-id" id="level-id" onChange={handleChange}>
                                        <option value="">-Select-</option>
                                        {levelList.data.map((option, index) => (
                                            <option
                                                key={
                                                    option["level-value"] + index
                                                }
                                                value={option["level-id"]}
                                            >
                                                {option["level-name"]}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{formErrors["level-id"]}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={formErrors["complete-by"]}>
                                    <FormLabel>Complete By</FormLabel>
                                    <Input size='sm' type="date"
                                        disabled={!isAdmin}
                                        value={formValues['complete-by']}
                                        name="complete-by" id="complete-by" onChange={handleChange} />
                                    <FormErrorMessage>{formErrors["complete-by"]}</FormErrorMessage>
                                </FormControl>
                                {!isAdmin && <FormControl isInvalid={formErrors["completed-on"]}>
                                    <FormLabel>Completed On</FormLabel>
                                    <Input size='sm' type="date"
                                        value={formValues['completed-on']}
                                        name="completed-on" id="completed-on" onChange={handleChange} />
                                    <FormErrorMessage>{formErrors["completed-on"]}</FormErrorMessage>
                                </FormControl>}
                                <Box mt={2}>
                                    <Flex align={'center'} justify={'center'} gap={2}>
                                        <Button size='sm' colorScheme='blue' isLoading={isLoading} type="submit" disabled={isLoading}>{id ? 'Update' : 'Submit'}</Button>
                                        <Button size='sm' colorScheme='blue' type="button" onClick={
                                            () => { navigate('/syllabus') }
                                        }>Cancel</Button>
                                    </Flex>
                                </Box>

                            </Flex>
                        </form>
                    </CardBody></Card>
            </Flex>
        </>
    )
}

export default CreateEditSyllabus;
