import React, { useState, useCallback, useEffect } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import axios from "axios";

import { useToast } from "@chakra-ui/react";
import { CONSTANTS } from "../../services/constants";
import { initialValues, validate } from "../../services/registerService";
import { initializeRazorpay } from "../../services/paymentv2";
import { USER_API } from "../../services/apiRoutes";
import { useParams, useNavigate } from "react-router-dom";
import qr from "../../images/logo.png";
import { SuccessModal, ErrorModal } from "../../components/SuccessErrorModal"


import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Card,
  CardBody,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  Image,
  Text,
  Center,
  Link,
  Checkbox,
} from "@chakra-ui/react";



const RegisterCompetitionUser = () => {
  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    gender: 'female',
    age: '',
    college: '',
    department: '',
    semester: '',
    competitions: []
  });

  const toast = useToast();
  const [isOpen, setIsOpen] = useState(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [userDetails, setUserDetails] = useState({
    isLoading: false,
    data: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormValues((prevValues) => ({
        ...prevValues,
        competitions: checked
          ? [...prevValues.competitions, name]
          : prevValues.competitions.filter((item) => item !== name)
      }));
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setIsLoading(false);
  };

  async function getUserDetails(id, source) {
    const requestBody = { "lni-user-id": id };
    await postCall(USER_API.USER, requestBody, {}, source)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails((prev) => ({ ...prev, data: res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message); // => the response payload
        }
      })
      .finally(() => setUserDetails((prev) => ({ ...prev, isLoading: false })));
  }
  useEffect(() => {
    const source = axiosSource();
    if (id) {
      setUserDetails((prev) => ({ ...prev, isLoading: true }));
      getUserDetails(Number(id), source);
    }
    return () => {
      source.cancel();
    };
  }, [id]);

  const handleSubmit = () => {
    const errors = validateForm(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const requestBody = {
        ...formValues,
        "institute": "Third Party",
        "program": "INTER COLLEGIATE COMPETITION",
        "programDesc": "Drug Abuse Awareness and Prevention",
        "auth59-user-id": 0,
        "lni-user-id": 0,
      };

      //Submit form data to the server
      lookupRegister(requestBody)
    }
  };

  const lookupRegister = async (requestBody) => {

    try {
      const response = await postCall(USER_API.COMPETITIONREGISTER, requestBody);
      console.log(response)
      if (response.data == "SUCCESS") {
        setShowSuccessModal(true);
      }
      else {
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error in payment success call:", error);
      setShowErrorModal(true);
      return false;
    }
  };

  console.log(modalVisible);

  const handleSubmit2 = useCallback(
    (e) => {
      // if (!showTime) {
      //   toast({
      //     title: "Please Click Program:A21Skills" || "",
      //     status: "error",
      //     duration: CONSTANTS.DURATION,
      //   });
      // } else {
      // }
      e.preventDefault();
      if (validate(formValues, setFormErrors)) {
        setIsLoading(true);

        const requestBody = {
          ...formValues,
          "auth59-user-id": formValues["auth59-user-id"],
          "lni-user-id": formValues["lni-user-id"],
        };

        lookupRegister(requestBody);

        setIsLoading(false);
      }
    },
    [formValues, showTime]
  );

  const validateForm = (values) => {
    const errors = {};
    if (!/^[A-Za-z\s]+$/.test(values.fname)) {
      errors.fname = 'First name should contain only alphabets and spaces';
    }
    if (!/^[A-Za-z\s]+$/.test(values.lname)) {
      errors.lname = 'Last name should contain only alphabets and spaces';
    }
    if (!/^\S+@\S+\.\S+$/.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!/^\d{10}$/.test(values.phone)) {
      errors.phone = 'Phone number should be a 10 digit number';
    }
    if (values.age < 15 || values.age > 99) {
      errors.age = 'Age should be between 15 and 99';
    }
    // if (!/^[A-Za-z\s]+$/.test(values.college)) {
    //   errors.college = 'College name should contain only alphabets and spaces';
    // }
    if (!/^[A-Za-z\s]+$/.test(values.department)) {
      errors.department = 'Department name should contain only alphabets and spaces';
    }
    if (!/^[A-Za-z0-9\s]+$/.test(values.semester)) {
      errors.semester = 'Semester should contain alphabets, numbers, and spaces';
    }
    if (values.competitions.length === 0) {
      errors.competitions = 'You must select at least one competition';
    }
    return errors;
  };

  return (
    <Flex direction="column" maxW="700px" mx="auto" mt="20px">
      <br/><br/><br/><br/>
      <Center><Text fontSize='15px' color='tomato' as="b">Due to unforeseen circumstances, the competitions date has been postponed. We will announce the new date soon. New registrations will be open from 31.08.2024</Text></Center>
      <br/><br/><br/><br/>
    </Flex>

  );
};

export default RegisterCompetitionUser;