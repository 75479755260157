import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { axiosSource, postCall } from "../../services/httpService";
import { INSTITUTE_API, QUESTIONAIRE_API, SYLLABUS_API } from "../../services/apiRoutes";
import { CONSTANTS } from "../../services/constants";
import QuestionaireList from "./QuestionaireList";
import { Box, Button, Card, CardBody, Checkbox, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, Radio, RadioGroup, Select, useToast } from "@chakra-ui/react";
import { FiX } from "react-icons/fi";
import { AuthContext } from '../../App';

function CreateQuestion() {
  const toast = useToast();
  const navigate = useNavigate();
  const questionSet = {
    "institution-id": "",
    "name": "",
    "syllabus-id": "",
    // "complete-by": "",
    "level": "",
    "grade": ""
  };
  const questionOptions = {
    "question-set-id": 0,
    "question-type": "SCQ",
    "question-text": "",
    "options": []
  };
  const [questionSetValues, setQuestionSetValues] = useState({ ...questionSet });
  const [optionValues, setOptionValues] = useState({ ...questionOptions });
  const [questionSetErrors, setQuestionSetErrors] = useState({});
  const [optionErrors, setOptionErrors] = useState({});
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: [],
  });
  const [syllabusList, SetSyllabusList] = useState({
    isLoading: false,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // options state
  const [optionsState, setOptions] = useState("");
  // radio answer select
  const [radioAnsState, setRadioAnswer] = useState("");
  // questions list based on setId
  const [questionaireList, SetQuestionaireList] = useState({
    isLoading: false,
    data: []
  });
  const [value, setValue] = useState("");
  const [gradeLevel, setGradeLevel] = useState();
  const [context] = useContext(AuthContext);
  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);


  async function getInstituteList(source) {
    let body = {};
        if(!isAdmin) {
        body = {...body, "user-id": context?.user_data['lni-user-id']}
        }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL: INSTITUTE_API.INSTITUTE), body, null, source, true)
      .then((res) => {
        if (res.status === 200) {
          SetInstituteList((prev) => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message); // => the response payload
        }
      })
      .finally(() =>
        SetInstituteList((prev) => ({ ...prev, isLoading: false }))
      );
  }

  const getSyllabusList = useCallback(
    async (source) => {
      const requestBody = {
        "institution-id": (questionSetValues['institution-id'] ? Number(questionSetValues['institution-id']): 0),
      };
      await postCall(SYLLABUS_API.SYLLABUS, requestBody, {}, source)
        .then((res) => {
          if (res.status === 200) {
            SetSyllabusList((prev) => ({ ...prev, data: res?.data }));
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error.message); // => the response payload
          }
        })
        .finally(() =>
          SetSyllabusList((prev) => ({ ...prev, isLoading: false }))
        );
    },
    [SetSyllabusList, questionSetValues["institution-id"]]
  );

  useEffect(() => {
    SetInstituteList((prev) => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getInstituteList(source);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (!questionSetValues["institution-id"]) {
      setQuestionSetValues(prev => ({ ...prev, 'syllabus-id': "" }));
      return;
    }
    const source = axiosSource();
    getSyllabusList(source);
  }, [questionSetValues["institution-id"], getSyllabusList]);

  useEffect(() => {
    if (radioAnsState === "") return;
    let optionsArry = [...optionValues.options];
    const result = optionsArry.map(item => {
      if (item['option-text'] === radioAnsState) {
        item['option-isAnswer'] = 1;
      } else {
        item['option-isAnswer'] = 0;
      };
      return item;
    });
    // setOptionValues(prev => ({...prev, options: result}));
    console.log("🚀 ~ file: CreateQuestion.js:109 ~ result ~ result:", result)
  }, [optionValues.options, radioAnsState]);

  useEffect(() => {
    let optionsArry = [...optionValues.options];
    const result = optionsArry.map(item => {
      item['option-isAnswer'] = 0;
      return item;
    });
    setOptionValues(prev => ({ ...prev, options: result }));
    if (optionValues["question-type"] !== "MCQ") return;
    setRadioAnswer("");
    setValue("");
  }, [optionValues["question-type"]]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "syllabus-id") {
      const selectedSyllabus = syllabusList.data?.filter(item => {
        if (item['syllabus-id'] == value) return item;
        return null
      });
      if (selectedSyllabus && selectedSyllabus.length > 0) {
        setQuestionSetValues(prev => ({ ...prev, [name]: value, "level": selectedSyllabus[0]['level-name'], "grade": selectedSyllabus[0]['grade-name'] }));
      };
    } else {
      setQuestionSetValues((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }
    setQuestionSetErrors({});
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setOptionValues((prevFormData) => ({
      ...prevFormData,

      [name]: value,
    }));
    setOptionErrors({});
  };

  const validateQuestionForm = () => {
    const newErrors = {};

    if (questionSetValues['institution-id'] === "") {
      newErrors['institution-id'] = "Institue is required!";
    }
    if (questionSetValues['syllabus-id'] === "") {
      newErrors['syllabus-id'] = "Syllabus is required!";
    }
    // if (questionSetValues['complete-by'] === "") {
    //   newErrors['complete-by'] = "CompleteBy is required!";
    // }
    if (questionSetValues['name'] === "") {
      newErrors['name'] = "Name is required!";
    }

    setQuestionSetErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const validateOptionForm = () => {
    const newErrors = {};

    if (optionValues["question-text"] === "") {
      newErrors["question-text"] = "Question Text is required";
    }

    // if (optionValues["question-type"] === "") {
    //   newErrors["question-type"] = "Question Type is required";
    // }

    if (optionValues["options"].length === 0) {
      newErrors["options"] = "Option is required";
    }
    if (optionValues["options"].length < 2) {
      newErrors["options"] = "Please enter atleast 2 options";
    }
    let optionsArry = [...optionValues.options];
    if (optionValues["options"].length > 1 && !optionsArry.some(item => item['option-isAnswer'] === 1)) {
      newErrors["options"] = "Please choose answer in options";
    }

    setOptionErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateQuestionForm()) {
      // Submit the form data
      console.log("Form submitted:", questionSetValues);
      setIsLoading(true);
      createQuestionSet();
    } else {
      console.log("Form validation failed");
    }
  };

  const handleQuestionSubmit = (event) => {
    event.preventDefault();
    if (validateOptionForm()) {
      setIsLoading(true);
      creationAnsOptions();
    }
  }
  /* creating question set */
  const createQuestionSet = useCallback(() => {
    const body = {
      "institution-id": questionSetValues['institution-id'],
      "name": questionSetValues['name'],
      "syllabus-id": questionSetValues['syllabus-id'],
      // "complete-by": questionSetValues['complete-by'],
    };
    postCall(QUESTIONAIRE_API.QUESTION_SET_ADD_EDIT, body, null, null, false).then(function (result) {
      // console.log("🚀 ~ file: CreateQuestion.js:172 ~ result:", result, result?.data, result?.data["question-set-id"]);
      // setFormValues({...questionSet});
      getLevelandSyllabus(questionSetValues['syllabus-id']);
      setOptionValues(prev => ({ ...prev, "question-set-id": result?.data["question-set-id"] }))
    })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => setIsLoading(false));
  }, [questionSetValues]);

  const getLevelandSyllabus = useCallback(async(id) => {
    const requestBody = {
      "syllabus-id": (id ? Number(id): 0)
    };
    await postCall(SYLLABUS_API.SYLLABUS_LIST, requestBody).then(res => {
      // console.log('res', res.data);
      setGradeLevel({'grade-name': res.data['grade-name'], 'level-name': res.data['level-name']})
    }).catch((error) => {
        if(error){
            console.log(error.message); // => the response payload 
        }
    });
  }, []);

  const getQuestionaireList = useCallback(async (search = {}, source) => {
    const requestBody = {
      "institution-id": Number(questionSetValues['institution-id']),
      "question-set-id": Number(optionValues['question-set-id']),
      "syllabus-id": Number(questionSetValues['syllabus-id'])
    };
    await postCall(QUESTIONAIRE_API.QUESTIONAIRE, requestBody, {}, source).then(res => {
      SetQuestionaireList(prev => ({ ...prev, data: res?.data }));
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetQuestionaireList(prev => ({ ...prev, isLoading: false })));
  }, [SetQuestionaireList, questionSetValues['institution-id'], optionValues['question-set-id'], questionSetValues['syllabus-id']]);

  /* creating question with opitions & answer */
  const creationAnsOptions = useCallback(() => {
    const body = { ...optionValues };
    // console.log("🚀 ~ file: CreateQuestion.js:266 ~ creationAnsOptions ~ body:", body)
    postCall(QUESTIONAIRE_API.QUESTION_ADD_EDIT, body, null, null, false).then(function (result) {
      toast({
        title: `Question created successfully! Scroll down to view created questions`,
        status: 'success',
        duration: CONSTANTS.DURATION
      });
      setOptionValues(prev => ({ ...questionOptions, "question-set-id": prev['question-set-id'] }));
      getQuestionaireList({}, null);
    })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => setIsLoading(false));
  }, [optionValues, getQuestionaireList, questionOptions]);

  const addOptions = () => {
    if (!optionsState) return;
    let optionsArry = [...optionValues.options];
    if (optionsArry.findIndex((item) => item["option-text"] === optionsState) > -1) {
      setOptionErrors(prev => ({ ...prev, options: "Option already exists" }));
      return;
    } else {
      setOptionErrors(prev => ({ ...prev, options: "" }));
    };
    optionsArry.push({
      "option-text": optionsState,
      "option-isAnswer": 0
    });
    setOptions("");
    setOptionValues(prev => ({ ...prev, options: optionsArry }));
  };

  const removeOption = (index) => {
    let optionsArry = [...optionValues.options];
    optionsArry.splice(index, 1);
    setOptionValues(prev => ({ ...prev, options: optionsArry }));
  }

  const onSelectAnswer = (value, index) => {
    console.log("🚀 ~ file: CreateQuestion.js:215 ~ onSelectAnswer ~ value:", value)
    let optionsArry = [...optionValues.options];
    let selectedOption = { ...optionsArry[index] };
    let updateAnswer = { ...selectedOption, 'option-isAnswer': value ? 1 : 0 }
    optionsArry[index] = updateAnswer;
    setOptionValues(prev => ({ ...prev, options: optionsArry }));
  };

  return (
    <>
      {(instituteList.isLoading || syllabusList.isLoading || isLoading || questionaireList.isLoading) && (<div className="loader flex-center">Loading...</div>)}
      <Flex h="87vh" alignItems="center" justifyContent="center">
        <Card m={2}>
          <CardBody>
            <form onSubmit={optionValues['question-set-id'] ? handleQuestionSubmit : handleSubmit}>
              <Flex flexDirection="column" minWidth={400} maxWidth={300}>
                <Heading alignSelf={'center'}>Create Questionnaire</Heading>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" value={questionSetValues['name']} name="name" id="name" onChange={handleChange}
                    disabled={optionValues['question-set-id']} placeholder="Enter QuestionSet name" />
                  {questionSetErrors['name'] && (
                    <span className="error-message">{questionSetErrors['name']}</span>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Institute</FormLabel>
                  <Select
                    name="institution-id"
                    id="institution-id"
                    value={questionSetValues["institution-id"]}
                    onChange={handleChange}
                    disabled={optionValues['question-set-id']}
                  >
                    <option key="select" value={0}>
                      -Select-
                    </option>
                    {instituteList?.data?.map((option, index) => (
                      <option
                        key={
                          option["institution-id"] +
                          option["institution-name"] +
                          index
                        }
                        value={option["institution-id"]}
                      >
                        {option["institution-name"]}
                      </option>
                    ))}
                  </Select>
                  {questionSetErrors['institution-id'] && (
                    <span className="error-message">{questionSetErrors['institution-id']}</span>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Syllabus</FormLabel>
                  <Select
                    name="syllabus-id"
                    id="syllabus-id"
                    value={questionSetValues["syllabus-id"]}
                    onChange={handleChange}
                    disabled={optionValues['question-set-id']}
                  >
                    <option key="select" value={""}>
                      -Select-
                    </option>
                    {syllabusList?.data?.map((option, index) => (
                      <option
                        key={
                          option["syllabus-id"] + option["syllabus-name"] + index
                        }
                        value={option["syllabus-id"]}
                      >
                        {option["syllabus-name"]}
                      </option>
                    ))}
                  </Select>
                  {questionSetErrors['syllabus-id'] && (
                    <span className="error-message">{questionSetErrors['syllabus-id']}</span>
                  )}
                </FormControl>

                {/* <FormControl>
                  <FormLabel>Complete By</FormLabel>
                  <Input type="date" value={questionSetValues['complete-by']} name="complete-by" id="complete-by" onChange={handleChange}
                    disabled={optionValues['question-set-id']} />
                  {questionSetErrors['complete-by'] && (
                    <span className="error-message">{questionSetErrors['complete-by']}</span>
                  )}
                </FormControl> */}

                <FormControl>
                  <FormLabel>Level</FormLabel>
                  <Input
                    readOnly
                    type="text"
                    name="level"
                    id="level"
                    placeholder="Label"
                    value={questionSetValues.level}
                    disabled={true}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Grade</FormLabel>
                  <Input
                    readOnly
                    type="text"
                    name="grade"
                    id="grade"
                    placeholder="Grade"
                    value={questionSetValues.grade}
                    disabled={true}
                  />
                </FormControl>

                {optionValues['question-set-id'] ? <>
                  <FormControl>
                    <FormLabel>Questionnaire Title</FormLabel>
                    <Input
                      type="text"
                      name="question-text"
                      id="question-text"
                      placeholder="Enter Question Title"
                      value={optionValues['question-text']}
                      onChange={handleOptionChange}
                    />
                    {optionErrors['question-text'] && (
                      <span className="error-message">{optionErrors['question-text']}</span>
                    )}
                  </FormControl>

                  {/* <FormControl>
                    <FormLabel>Questionnaire Type</FormLabel>
                    <Select
                      name="question-type"
                      id="question-type"
                      value={optionValues['question-type']}
                      onChange={handleOptionChange}
                    >
                      <option key="select" value={""}>
                        -Select-
                      </option>
                      {CONSTANTS.QUESTION_TYPE.map((option, index) => (
                        <option
                          key={
                            option + index
                          }
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </Select>
                    {optionErrors['question-type'] && (
                      <span className="error-message">{optionErrors['question-type']}</span>
                    )}
                  </FormControl> */}

                  <FormControl>
                    <FormLabel>Questionnaire Answer</FormLabel>
                    <InputGroup>
                    <Input
                      type="text"
                      name="optionsState"
                      id="optionsState"
                      placeholder="Add Question Answer"
                      value={optionsState}
                      onChange={(e) => (
                        setOptions(e.target.value)
                      )}
                    />
                    <InputRightElement>

                      <Button type="button" onClick={addOptions}>
                        Add
                      </Button>
                    </InputRightElement>
                    </InputGroup>
                    {optionErrors.options && (
                      <span className="error-message">{optionErrors.options}</span>
                    )}
                  </FormControl>

                  {/* {optionValues['question-type'] === "MCQ" && optionValues.options.map((option, index) => (<div
                    key={'_check' + option['option-text'] + index}
                    className="field">
                    <InputGroup>
                    <Checkbox
                      name={"optionsState" + index}
                      isChecked={option['option-isAnswer']}
                      onChange={(e) => {
                        console.log("🚀 ~ file: CreateQuestion.js:388 ~ CreateQuestion ~ e:", e.target.checked)
                        onSelectAnswer(e.target.checked, index)
                      }}
                    >
                      {option['option-text']}
                    </Checkbox>
                    <InputRightElement color={'red.500'} cursor={'pointer'} title="Remove" children={<Icon as={FiX} onClick={() => removeOption(index)}/>}/>
                    </InputGroup>
                  </div>))} */}
                  <RadioGroup onChange={setValue} value={value}>
                  {optionValues.options.map((option, index) => (<div
                    key={'_check' + option['option-text'] + index}
                    className="field">
                    <InputGroup>
                    <Radio
                      name={"optionsState"}
                      value={option["option-text"]}
                      isChecked={radioAnsState === option["option-text"]}
                      onChange={(e) => {
                        console.log("🚀 ~ file: CreateQuestion.js:388 ~ CreateQuestion ~ e:", e.target.value)
                        setRadioAnswer(e.target.value)
                      }}
                    >
                      {option['option-text']}
                    </Radio>
                    <InputRightElement color={'red.500'} cursor={'pointer'} title="Remove" children={<Icon as={FiX} onClick={() => removeOption(index)}/>}/>
                    </InputGroup>
                    {/* <span className="cross" onClick={() => removeOption(index)}>Remove</span> */}
                  </div>))}
                  </RadioGroup>
                </> : null
                }
                <Box mt={2}>
                  <Flex align={'center'} justify={'center'} gap={2}>
                    <Button
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        if (window.confirm('Are you sure you want to cancel ? this will clear the from !')) {
                          // Save it!
                          navigate(-1);
                        } else {
                          // Do nothing!
                          console.log('Thing was not saved to the database.');
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </form>
          </CardBody>
        </Card>
      </Flex>
      {(questionaireList.data && questionaireList.data.length > 0) ? <QuestionaireList questionaireList={questionaireList} changeStatus={() => { }} gradeLevel={gradeLevel}/> : null}
    </>
  );
}

export default CreateQuestion;
