import { useLocation } from 'react-router-dom';
import { DesktopNav } from './Navbar';
import { useEffect, useState } from 'react';
import logo from '../images/header_logo.png';
import antlogo from '../images/antony_logo.png';
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  useColorModeValue,
  useBreakpointValue,
  Stack,
  Image
} from '@chakra-ui/react'
import { FiMenu } from "react-icons/fi";
import Sidebar from './Sidebar';


const Header = () => {
  let location = useLocation();
  const [locationState, setLocationState] = useState();

  useEffect(() => {
    setLocationState(location);
  }, [location]);

  return (
    <>
      {(locationState && locationState?.pathname !== '/login') ? <Nav></Nav> : <Nav></Nav>}
    </>
  );
};

export default Header;

const smVariant = { navigation: 'drawer', navigationButton: true }
const mdVariant = { navigation: 'sidebar', navigationButton: false }

export function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant })

  return (
    <>
      <Box width={'100%'} className='sticky-header' bg={useColorModeValue('black', 'gray.900')} px={2}>
        <Flex h={{ base: '16' }} alignItems={'center'} justifyContent={'space-between'}>
          <Button
            aria-label={'Open Menu'}
            p={3}
            display={{ lg: 'none' }}
            justifyContent={'center'}

            onClick={isOpen ? onClose : onOpen}
          >
            <FiMenu />
          </Button>
          <Box
            width={{ base: '30%', md: '20%', lg: '10%' }}
            height='auto'
          >
            <Image
              src={logo}
              alt="Description of the image"
            />
          </Box>
          <Stack display={{ base: 'none', md: 'none', lg: 'flex' }} width={{ lg: '83%' }} mr={{ lg: 5 }} as={'nav'} spacing={4} >
            <DesktopNav />

          </Stack>
          <Box
            width={{ base: '20%', md: '10%', lg: '5%' }}
            height="auto"
          >
            <Image
              src={antlogo}
              alt="Description of the image"
            />
          </Box>
        </Flex>
        {/* sidebar menu */}
        {isOpen ? (<Sidebar
          variant={variants?.navigation}
          isOpen={isOpen}
          onClose={isOpen ? onClose : onOpen}>
          <DesktopNav />
        </Sidebar>) : null}
      </Box>
    </>
  )
}