

export const chakraStyles = {
    "aFont": { base: "sm", md: "md", lg: "4xl" },
    "bFont": { base: 'sm', md: 'md', lg: '3xl' },
    "cFont": { base: "sm", md: "md", lg: "lg" },
    "dFont": { base: "sm", md: "md", lg: "2xl" },
    "eFont": { base: "sm", md: "md", lg: "5xl" },
    "fFont": { base: "sm", md: "md", lg: "xl" },
    "gFont": { base: "md", md: "lg", lg: "3xl" },
    "hFont": { base: "md", md: "lg", lg: "2xl" },
    topic: { align: "center", justifyContent: "center", fontSize: { base: "md", md: "4xl", lg: "2.4rem" } },
    exploreButton: {
        flex: { mt: 5, mb: 1, justifyContent: "center" },
        button: {
            size: { base: "sm", md: "md", lg: "lg" },
            cursor: "pointer",
            color: 'white'
        }
    },
    aboutStyles: {
        visionContainer: { w: { base: '50%', md: '50%', lg: '90%' }, h: { lg: '55%' }, mt: 14, mb: { sm: 5, md: 5, lg: 14 }, ml: { base: '25%', lg: '0%' }, justifyContent: { base: 'center' } },
        lists: { pb: 4, pt: 4, bg: '#4c4c47', flexDirection: { base: 'column-reverse', lg: 'row' }, justifyContent: 'center' },
        topic: { mt: 12, mb: 12, textAlign: "center", className: "text-header" },
        imageCenter: { w: { base: '70%', lg: '50%' }, ml: { base: '20%', lg: '0%' }, mt: 14, mb: 14 },
        visionFlex: { bg: '#4c4c47', flexDirection: { base: 'column', lg: 'row' }, justifyContent: { base: 'center' } },
        strategiesText: { mt: 12, mb: 12 },
        missionText: { display: 'flex', w: { lg: '100%' }, justifyContent: 'center', alignItems: 'center' }
    },
    homeStyles: {
        imagesCardWidth: { base: '100%', md: '100%', lg: '100%' },
        imageWidth: "100%",
        cardStyle: { height: { lg: 32 }, mt: 1, mb: 18, align: "center", justifyContent: "center" },
        container: { d: "flex", maxW: "3xl", alignItems: "center" },
        training: {
            topFlex: { mb: 5, gap: { base: 6, md: 12, lg: 14 }, fontFamily: 'Segoe UI', justifyContent: 'center' },
            box: { mt: { base: 12 } },
            image: { width: { base: 20, md: 40, lg: 56 }, height: { base: 20, md: 40, lg: 56 } },
            flex: { rowGap: { base: 0, md: 0, lg: 0 }, width: "110%", py: { base: 2, md: 3, lg: 10 }, align: "center", direction: 'column', bg: '#4c4c47', color: 'white' },
            text: { fontSize: { base: "sm", md: "lg", lg: "3xl" }, height: '1.2rem' },
            secondText: { fontSize: { base: "sm", md: "lg", lg: "2xl" }, height: '1.2rem' },
            bottomBox: { py: 6, justifyContent: 'center', className: "progBox", width: { base: '100%', md: '100%', lg: '103%' }, color: 'white', align: "center", bg: '#4c4c47' }
        }
    },
    programStyles: {
        imageStyle: { justifyContent: 'center', display: 'flex', w: '100%' },
        imageContainer: { className: "imgBox", mb: 18, mt: 1, width: '100%', justifyContent: "center", align: "center" }

    },
    pedagogyStyles: {
        imageContainer: { className: "imgBox", h: { base: '80px' }, mt: 1, align: "center", mb: 18, width: { base: '100%', lg: '60%' }, justifyContent: "center" },
        imageW: { base: '100%', lg: '60%' },
        imageText: { color: 'white', mt: 1, fontSize: { base: "md", md: "xl", lg: "2xl" } },
        BoxText: { color: 'white', align: 'center', mt: { base: 10, lg: 16 }, fontSize: { base: "md", md: "xl", lg: "4xl" } },
        BoxInnerText: { color: 'white', className: "ped-block", mb: { base: 2, md: -18, lg: 8 }, fontSize: { base: "sm", md: "md", lg: "2xl" } },
        flexText: { color: 'white', fontSize: { base: "md", md: "xl", lg: "2xl" }, mt: 1 }

    },
    personalStyles: {
        imageFlex: { className: "progBox", w: { base: '100%', lg: '100%' }, h: { base: '80px' }, align: "center", justifyContent: 'center' },
        flexText: { color: 'white', fontSize: { base: "md", md: "xl", lg: "2xl" }, p: { lg: 3, md: 3 } },
        flexConatiner: { className: "progBox", justifyContent: 'center', w: { base: '100%' }, h: { base: '80px' }, align: "center" }
    },
    imageFlex: { className: "progBox", w: { base: '100%' }, h: { base: '80px' }, align: "center", justifyContent: 'center' },
    flexText: { color: 'white', fontSize: { base: "md", md: "xl", lg: "2xl" }, p: { lg: 3, md: 3 } }

}