import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { extendTheme } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Container,
  Text,
  Image,
  Center,
  Heading,
  VStack,
  Button,
  useTheme, Wrap
} from "@chakra-ui/react";
import { } from "@chakra-ui/react";
import whya3 from "../../images/whya3minds.jpg"
import slide1 from "../../images/1.jpg";
import slide2 from "../../images/2.jpg";
import slide3 from "../../images/3.jpg";
import slide4 from "../../images/4.jpg";
import slide5 from "../../images/5.jpg";
import slide6 from "../../images/6.jpg";
import slide7 from "../../images/7.jpg";
import slide8 from "../../images/8.jpg";
import slide9 from "../../images/9.jpg";
import basic from "../../images/basic.jpg";
import moderate from "../../images/moderate.jpg";
import advanced from "../../images/advanced.jpg";
import Logo from "../../images/logo.png";
import OurProgram from "../../images//PERSONAL_DEVELOPMENT.jpg";
import Communication from "../../images/COMMUNICATION_Box.jpg";
import Adaptation from "../../images/ADAPTATION_Box.jpg";
import WellBeing from "../../images/WELLBEING_Box.jpg";
import Acadamic from "../../images/ACADEMIC_Box.jpg";
import Holistic from "../../images/Holistic.jpg";
import Expertise from "../../images/Expertise.jpg";
import Research from "../../images/Research.jpg";
import Customized from "../../images/Customized.jpg";
import Proven from "../../images/Proven.jpg";
import Comitted from "../../images/Comitted.jpg";
import { useNavigate } from "react-router-dom";
import a3minds_promo_video from '../../videos/a3minds_video.mp4';
import { CONSTANTS } from "../../services/constants";

export default function Home() {
  const [imageNum, setImageNum] = useState(1);
  const navigate = useNavigate();



  const sliderImages = [
    {
      url: slide8,
    },
    {
      url: slide9,
    },
    {
      url: slide1,
    },
    {
      url: slide2,
    },
    {
      url: slide3,
    },
    {
      url: slide4,
    },
    {
      url: slide5,
    },
    {
      url: slide6,
    },
    {
      url: slide7,
    },
  ];



  return (
    <>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
        }}
        spacing={10}
        py={5}
      >
        <Flex justifyContent={'center'}>
          <GridItem colSpan={1} className="slider">
            <SimpleImageSlider
              width={window.innerWidth - 20}
              images={sliderImages}
              showBullets={false}
              showNavs={true}
              autoPlay={true}
              navSize={30}
              onStartSlide={(index, length) => {
                setImageNum(index);
              }}
              autoPlayDelay={3}
            />
          </GridItem>
        </Flex>
      </Grid>
      <StatsGridWithImage></StatsGridWithImage>
    </>
  );
}

export function StatsGridWithImage() {
  const theme = useTheme();
  const { aFont, bFont, cFont, dFont, eFont, fFont, homeStyles, exploreButton, gFont, hFont, imageFlex, flexText } = theme;
  const cardW = homeStyles.imagesCardWidth;
  const imageW = homeStyles.imageWidth;
  const cardStyle = homeStyles.cardStyle;
  const trainingElement = homeStyles.training;


  const navigate = useNavigate();

  const handleExplore = (url) => {
    navigate(url);
  };

  return (
    <Box className="homeComponent">
      <Container {...homeStyles.container}>
        <Box m={5} mb={0}>
          <Text textAlign={'center'} fontSize={bFont}>
            <i>
              <b>"There are no secrets to success"</b>
              <br />It is the result of preparation,
              learning and Hard work for which training sessions are vital to
              the learning process that has a direct impact on an individual's
              skills and performance.
            </i>
          </Text>
        </Box>
        <Box m={5} mt={3}>
          <Heading mt={6} fontSize={bFont} textAlign={"center"}>
            Welcome to
          </Heading>
          <Center mt={3} mb={5}>
            <Box
              width={{ base: '75%', md: '20%', lg: '55%' }}
              height='auto'
            >
              <Image
                src={Logo}
                alt="Description of the image"
              />
            </Box>
          </Center>

        </Box>
      </Container>


      <center>
        <Box width={{ md: '45%', md: '55%' }} >
          <Flex bg={'#3a3b3c'} justifyContent={"center"} align={"center"}>
            <Box width={'100%'}>
              <center>
                <Text color={'white'} mt={14} align={'center'} letterSpacing={3} fontSize={aFont}>a3minds is a training company</Text>
                <Text color={'white'} mb={14} letterSpacing={3} fontSize={cFont}>
                  that specializes in developing<br />
                  Non-Academic Skills and Abilities<br />
                  in Individuals
                </Text>
              </center>
            </Box>
          </Flex>
          <Box
            as='video'
            controls
            autoPlay={true}
            muted
            src={a3minds_promo_video}
            alt='A3 Minds'
            objectFit='contain'
            width={"100%"}
            sx={{
              aspectRatio: '16/9'
            }}
            loop
            mt={5}

          />
          <Box >
            <Image src={whya3} alt="Why A3" mt={'5'} width={"100%"} />
          </Box>

        </Box>

      </center>

      <Flex mt={5} mb={12} pt={14} pb={14} className="vision-section" justifyContent={"center"} align="center" >
        <Box>
          <Text mb={5} fontSize={eFont} textAlign={"center"}>
            VISION
          </Text>
          <Text textAlign={'center'} fontSize={aFont}>
            <i>
              Our Vision is to empower & train<br />
              Individuals with essential skills and<br />
              foster their personal growth,<br />
              leadership abilities and soft skills.
            </i>
          </Text>
        </Box>
      </Flex>
      <Container {...homeStyles.container}>
        <section>
          <Text textAlign={"center"} fontSize={aFont} className="text-header">
            OUR PROGRAMS
          </Text>
          <Box>
            <Center mt={3}>
              <Image src={OurProgram} alt="ourprogram" w={imageW} />
            </Center>

            {/* <Flex mt={1} mb={5} width={cardW} align="center" justifyContent={"center"} backgroundColor={"black"} paddingY={"0.5rem"} >
              <Text color={'white'} fontSize={dFont} textAlign={"center"}  >
                PERSONAL DEVELOPMENT
              </Text>
            </Flex> */}
            <Flex {...imageFlex} mt={1}>
              <Text {...flexText} >
                PERSONAL DEVELOPMENT
              </Text>
            </Flex>
            <Text mt={5} fontSize={fFont}>
              At A3 Minds, we believe that personal development plays a crucial
              role in the holistic growth of individuals. Our Personal Development
              programs are designed to equip school and college individuals with
              essential life skills and empower them to thrive academically and
              personally.
            </Text>
            <Flex {...exploreButton.flex}>
              <Button
                {...exploreButton.button}
                onClick={() => handleExplore("/personaldev")}
              >
                EXPLORE
              </Button>
            </Flex>
          </Box>
          <Box m={5}>
            <Center mt={10}>
              <Image src={Communication} alt="ourprogram" w={imageW} />
            </Center>
            <Flex {...imageFlex} mt={1}>
              <Text {...flexText} >
                COMMUNICATION & INTERPERSONAL SKILLS
              </Text>
            </Flex>

            <Text mt={5} fontSize={fFont}>
              Effective communication and interpersonal skills are essential for
              success in academic and personal spheres. Our Communication and
              Interpersonal Skills programs provide individuals with the tools and
              techniques to express themselves confidently, collaborate
              effectively and build positive relationships.Effective
              communication and interpersonal skills are essential for success
              in academic and personal spheres. Our Communication and
              Interpersonal Skills programs provide individuals with the tools and
              techniques to express themselves confidently, collaborate
              effectively and build positive relationships.
            </Text>
            <Flex {...exploreButton.flex}>
              <Button
                {...exploreButton.button}
                onClick={() => handleExplore("/communication")}
              >
                EXPLORE
              </Button>
            </Flex>

          </Box>
          <Box m={5}>
            <Center mt={10}>
              <Image src={Adaptation} alt="ourprogram" w={imageW} />
            </Center>


            <Flex {...imageFlex} mt={1}>
              <Text {...flexText} >
                ADAPTATION TO TECHNOLOGY &  21ST CENTURY SKILLS
              </Text>
            </Flex>
            <Text mt={5} fontSize={fFont}>
              Adaptation to technology and the development of 21st-century
              skills are crucial in today's rapidly changing world. As
              technology continues to advance and reshape various aspects of our
              lives, individuals and societies must proactively adapt to these
              changes to thrive. Likewise, 21st-century skills are essential for
              success in the modern world, as they encompass a range of
              abilities that go beyond traditional academic knowledge and focus
              on preparing individuals to navigate complex, interconnected and
              dynamic environments.
            </Text>

            <Flex {...exploreButton.flex}>
              <Button
                {...exploreButton.button}
                onClick={() => handleExplore("/adaptation")}
              >
                EXPLORE
              </Button>
            </Flex>
          </Box>
          <Box m={5}>
            <Center mt={10}>
              <Image src={WellBeing} alt="ourprogram" w={imageW} />
            </Center>


            <Flex {...imageFlex} mt={1}>
              <Text {...flexText} >
                WELL BEING & MENTAL HEALTH
              </Text>
            </Flex>
            <Text mt={5} fontSize={fFont}>
              At A3 Minds, we prioritize the well-being and mental health of
              individuals. Our Well-being and Mental Health programs provide
              support, guidance, and tools to help individuals manage stress,
              cultivate resilience, and promote overall emotional well-being.
            </Text>

            <Flex {...exploreButton.flex}>
              <Button
                {...exploreButton.button}
                onClick={() => handleExplore("/wellbeing")}
              >
                EXPLORE
              </Button>
            </Flex>
          </Box>
          <Box m={5}>
            <Center mt={10}>
              <Image src={Acadamic} alt="ourprogram" w={imageW} />
            </Center>

            <Flex {...imageFlex} mt={1}>
              <Text {...flexText} >
                ACADEMIC & CAREER READINESS
              </Text>
            </Flex>
            <Text mt={5} fontSize={fFont}>
              Academic and Career Readiness is crucial for individuals to excel in
              their studies and prepare for their future aspirations. Our
              programs focus on developing critical thinking, time management,
              study skills, and building a strong foundation for academic and
              career success.
            </Text>

            <Flex {...exploreButton.flex}>
              <Button
                {...exploreButton.button}
                onClick={() => handleExplore("/academic")}
              >
                EXPLORE
              </Button>
            </Flex>
          </Box>
        </section>
      </Container>
      <Container>

        <Text textAlign={"center"} fontSize={eFont} className="text-header" mb={10}>
          OUR TRAINING MODULES
        </Text>
      </Container>
      <section className="our_training"  >
        <div className="text-areas" >
          <Text fontWeight={600} fontSize={dFont}>
            <ul>
              <li>Adaptation to technology</li>
              <li>21st Century Skills</li>
              <li>Time Management</li>
              <li>Organisational Skills</li>
              <li>Entrepreneurial Skills</li>
              <li>Emotional Intelligence</li>
              <li>Communication Skills</li>
              <li>Social Skills</li>
              <li>Self-advocacy, Self-care & Self-Discipline</li>
              <li>Budgeting</li>
            </ul>
          </Text>
          <Text fontWeight={600} fontSize={dFont}>
            <ul>
              <li>Balancing Life</li>
              <li>Enriching Core Values</li>
              <li>Ambition & Vision</li>
              <li>Elevating Character</li>
              <li>Growth Mindset</li>
              <li>Non-Cognitive Traits and Habits</li>
              <li>Soft Skills</li>
              <li>Digital Learning Content</li>
              <li>Personality Development</li>
              <li>Career Guidance</li>
            </ul>
          </Text>
        </div>
      </section>
      <Container>
        <Box m={5}>
          <Flex {...exploreButton.flex}>
            <Button
              {...exploreButton.button}
              onClick={() => handleExplore("/program")}
            >
              EXPLORE
            </Button>
          </Flex>
        </Box>

      </Container>


      <Container {...homeStyles.container} fontSize={cFont}>
        <section>
          <Text textAlign={"center"} fontSize={eFont} className="text-header">
            OUR TRAINING SESSIONS
          </Text>
          <Text fontSize={cFont} textAlign={'center'} >
            Choose from our Training Sessions which are categorized into three packages.
          </Text>
        </section>
        <Flex {...trainingElement.topFlex}>
          <Box {...trainingElement.box} >
            <Center>
              <Image {...trainingElement.image} src={basic} alt="basic session" />
            </Center>
            <Flex  {...trainingElement.flex} >
              <Text {...trainingElement.text} >
                <b>BASIC</b>
              </Text>
              <Text fontSize={{ base: "2rem", lg: "3.5rem" }} height={{ base: '3rem', lg: '5rem' }} >
                <b>3</b>
              </Text>
              <Text {...trainingElement.secondText} >
                HOURS
              </Text>
            </Flex>
          </Box>
          <Box {...trainingElement.box} >
            <Center>
              <Image {...trainingElement.image} src={moderate} alt="basic session" />
            </Center>
            <Flex  {...trainingElement.flex} >
              <Text {...trainingElement.text} >
                <b>MODERATE</b>
              </Text>
              <Text fontSize={{ base: "2rem", lg: "3.5rem" }} height={{ base: '3rem', lg: '5rem' }} >
                <b>12</b>
              </Text>
              <Text {...trainingElement.secondText} >
                HOURS
              </Text>
            </Flex>
          </Box>
          <Box {...trainingElement.box} >
            <Center>
              <Image {...trainingElement.image} src={advanced} alt="basic session" />
            </Center>
            <Flex  {...trainingElement.flex} >
              <Text {...trainingElement.text} >
                <b>ADVANCED</b>
              </Text>
              <Text fontSize={{ base: "2rem", lg: "3.5rem" }} height={{ base: '3rem', lg: '5rem' }} >
                <b>24</b>
              </Text>
              <Text {...trainingElement.secondText} >
                HOURS
              </Text>
            </Flex>
          </Box>

        </Flex>
        <Flex {...trainingElement.bottomBox}>
          <Text fontSize={dFont} >Feel free to specify the dates that are convenient for you to distribute the training hours.</Text>
        </Flex>
      </Container>

      <Text textAlign={"center"} mt={20} fontSize={eFont} mb={5} className="text-header">
        OUR PEDAGOGY
      </Text>
      <Box className="pedagogy-section">
        <Flex >
          <Flex mb={4}
            gap={4} margin={{ lg: '0%', xl: '5%', "2xl": '5%' }} marginTop={'2%'} flexDirection={"column"}  >
            <Wrap justify={'center'} >


              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Holistic} alt='Holistic Development' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    HOLISTIC DEVELOPMENT
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} textColor={'white'} onClick={() => handleExplore('/personaldev')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Expertise} alt='Expertise' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    EXPERTISE AND EXPERIENCE
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/communication')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Research} alt='Research driven approach' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    RESEARCH-DRIVEN APPROACH
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/adaptation')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>


              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Customized} alt='Customized' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    CUSTOMIZED PROGRAMS
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/wellbeing')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Proven} alt='proven effectiveness' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    PROVEN EFFECTIVENESS
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/academic')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={Comitted} alt='ourprogram' w={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="pedagogy-container" />
                </Center>
                <Flex mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px', xl: '350px', "2xl": "460px" }} className="imgBox pedagogy-container " height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    COMMITTED TO EXCELLENCE
                  </Text>
                </Flex>
                {/* <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/academic')}>EXPLORE</Button>
                  </Center>
                </Box> */}
              </Box>

            </Wrap>
            <Box mt={5}>
              <Center>
                <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => { handleExplore('/pedagogy') }}>EXPLORE</Button>
              </Center>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box >
  );
}
