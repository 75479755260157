import React, { useCallback, useEffect, useState } from 'react'
import { axiosSource, postCall } from '../../services/httpService';
import { INSTITUTE_API, USER_API } from '../../services/apiRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, CardBody, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { CONSTANTS } from '../../services/constants';
import { toastByStatus, toastErrorOnly } from '../../services/utils';

function CreateEditInstitute() {
    const toast = useToast();
    const initialValues = {
        "institution-id": 0,
        "institution-name": "",
        "institution-type": "",
        "trainer-user-id": ""
    };
    const coordinator = {
        fname: "",
        lname: "",
        email: "",
        phone: ""
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [message, setMessage] = useState({
    //     text: "",
    //     success: true
    // });
    const [coordinatorValues, setCoordinatorValues] = useState(coordinator);
    const [coordinatorErrors, setCoordinatorErrors] = useState({});
    const [userList, SetUserList] = useState({
        isLoading: false,
        data: []
    });
    const navigate = useNavigate();
    let { id } = useParams();
    const [instituteDetails, setInstituteDetails] = useState({
        isLoading: false,
        data: null
    });

    async function getInsituteDetails(id, source) {
        const requestBody = { "institution-id": id };
        await postCall(INSTITUTE_API.INSTITUTE_ALL, requestBody, {}, source).then(res => {
            if (res.status === 200) {
                setInstituteDetails(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        })
            .finally(() => setInstituteDetails(prev => ({ ...prev, isLoading: false })));
    };
    // Edit - getdetails
    useEffect(() => {
        const source = axiosSource();
        if (id) {
            setInstituteDetails(prev => ({ ...prev, isLoading: true }));
            getInsituteDetails(Number(id), source)
        }
        return () => {
            source.cancel()
        }
    }, [id]);

    useEffect(() => {
        if (!instituteDetails.data) return;
        setFormValues({
            'institution-name': instituteDetails.data['institution-name'],
            'institution-type': instituteDetails.data['institution-type'],
            'coord-fname': instituteDetails.data['coord-fname'],
            'coord-lname': instituteDetails.data['coord-lname'],
            'coord-email': instituteDetails.data['coord-email'],
            'coord-phone': instituteDetails.data['coord-phone'],
            'coord-user-id': instituteDetails.data['coord-user-id'],
            "trainer-user-id": instituteDetails.data['trainer-user-id'],
        })
    }, [instituteDetails.data]);

    async function getUserList(source) {
        await postCall(USER_API.USER, null, null, source).then(res => {
            if (res.status === 200) {
                SetUserList(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
        })
            .finally(() => SetUserList(prev => ({ ...prev, isLoading: false })));
    };

    useEffect(() => {
        SetUserList(prev => ({ ...prev, isLoading: true }));
        const source = axiosSource();
        getUserList({}, source);
        return () => {
            source.cancel()
        }
    }, []);

    const createInstitue = useCallback(() => {
        let institueBody;
        if (id) {
            institueBody = { ...formValues, 'institution-id': id };
        } else {
            institueBody = { ...formValues };
        }
        if (!Object.keys(formValues).includes('coord-user-id')) {
            institueBody['coord-user-id'] = "";
        }
        // console.log('institueBody',institueBody);
        // console.log('createInstitue', InstitueBody);
        postCall(INSTITUTE_API.INSTITUTE_CREATE, institueBody).then((res) => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                if (res?.data?.error) {
                    return;
                }
                setFormValues({
                    "institution-id": 0,
                    "institution-name": "",
                    "institution-type": "",
                    "coord-user-id": "",
                    "trainer-user-id": ""
                });
                setCoordinatorValues({
                    fname: "",
                    lname: "",
                    email: "",
                    phone: ""
                });
            }
            setTimeout(() => {
                navigate("/institute")
            }, 2000);
        }).catch((error) => toastErrorOnly(error)).finally(() => {
            setIsSubmit(false);
            setIsLoading(false);
        });
    }, [formValues, id, navigate, toast]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({ ...prev, [name]: value }));
        setFormErrors({});
    }, [setFormValues]);

    const handleCoordinatorChange = useCallback((e) => {
        const { name, value } = e.target;
        setCoordinatorValues({ ...coordinatorValues, [name]: value });
        setCoordinatorErrors({});
    }, [coordinatorValues]);

    const createCoordinator = useCallback(() => {
        const requestBody = { ...coordinatorValues, "user-type": "TEACHER", "auth59-user-id": 11, "lni-user-id": 0 };
        // console.log('createCoordinator', requestBody);
        postCall(USER_API.REGISTER, requestBody).then((res) => {
            if (res.status === 200) {
                setFormValues(prev => ({ ...prev, "coord-user-id": res?.data['lni-user-id'] }))
            }
            setTimeout(() => {
                navigate("/institute");
            }, 1000);
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
            }
            setIsLoading(false);
        });
    }, [coordinatorValues, navigate]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        // setFormErrors(validate(formValues));
        // setCoordinatorErrors(validateCoordinator(coordinatorValues));
        setIsSubmit(true);
        // if (validate(formValues) && validateCoordinator(coordinatorValues) && isSubmit) {
        //     setIsLoading(true);
        // createCoordinator();
        // }
        console.log('formValues', formValues);
        if (validate(formValues)) {
            setIsLoading(true);
            createInstitue();
        }
    }, [formValues, createInstitue]);

    const validate = (values) => {
        const errors = {};

        // if (!values["trainer-user-id"]) {
        //     errors["trainer-user-id"] = "Trainer is required!";
        // }

        if (!values["institution-name"]) {
            errors["institution-name"] = "Institution Name is required!";
        }

        if (!values["institution-type"]) {
            errors["institution-type"] = "Institution type is required!";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateCoordinator = (values) => {
        const errors = {};

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.fname) {
            errors.fname = "First Name is required!";
        }

        if (!values.lname) {
            errors.lname = "Last Name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        setCoordinatorErrors(errors);
        return Object.keys(errors).length === 0;
    };

    return (
        <>
            {(userList.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
            <Flex alignItems="center" justifyContent="center">
                {/* {message.text ? (
                    <div className={"ui message " + message.success ? 'success': 'error-message'}>{message.text}</div>
                ) : null} */}
                <Card m={2} mt={5}>
                    <CardBody h={{ base: "87vh", lg: "125vh" }}>
                        <Flex justifyContent={'center'}>
                            <form className="bulkupload-section form-data" onSubmit={handleSubmit}>
                                <Heading mb={4} textAlign={"center"}>{id ? "Edit" : "Create"} Institute</Heading>
                                <Flex flexDirection="column" minWidth={{ base: 300, md: 400, lg: 400 }} maxWidth={300}>
                                    <FormControl isInvalid={formErrors["institution-type"]}>
                                        <FormLabel>Institute Type: </FormLabel>
                                        <Select isDisabled={!!id} value={formValues['institution-type']} name="institution-type" id="institution-type" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            <option value="SCHOOL">School</option>
                                            <option value="COLLEGE">College</option>
                                            <option value="OTHERS">Others</option>
                                        </Select>
                                        <FormErrorMessage>{formErrors["institution-type"]}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={formErrors["institution-name"]}>
                                        <FormLabel>Institute Name: </FormLabel>
                                        <Input
                                            type="text"
                                            name="institution-name"
                                            placeholder="Enter Institution Name"
                                            value={formValues["institution-name"]}
                                            onChange={handleChange}
                                        />
                                        <FormErrorMessage>{formErrors["institution-name"]}</FormErrorMessage>
                                    </FormControl>
                                    {/* <FormControl isInvalid={coordinatorErrors.fname}>
                            <FormLabel>Co-Ordinator First Name: </FormLabel>
                            <Input
                                type="text"
                                name="fname"
                                placeholder="Enter First Name"
                                value={coordinatorValues.fname}
                                onChange={handleCoordinatorChange}
                            />
                            <FormErrorMessage>{coordinatorErrors.fname}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={coordinatorErrors.lname}>
                            <FormLabel>Co-Ordinator Last Name: </FormLabel>
                            <Input
                                type="text"
                                name="lname"
                                placeholder="Enter Last Name"
                                value={coordinatorValues.lname}
                                onChange={handleCoordinatorChange}
                            />
                            <FormErrorMessage>{coordinatorErrors.lname}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={coordinatorErrors.email}>
                            <FormLabel>Co-Ordinator Email: </FormLabel>
                            <Input
                                type="text"
                                name="email"
                                placeholder="Enter Email Address"
                                value={coordinatorValues.email}
                                onChange={handleCoordinatorChange}
                            />
                            <FormErrorMessage>{coordinatorErrors.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={coordinatorErrors.phone}>
                            <FormLabel>Co-Ordinator Phone: </FormLabel>
                            <Input
                                type="text"
                                name="phone"
                                placeholder="Enter Phone Number"
                                value={coordinatorValues.phone}
                                onChange={handleCoordinatorChange}
                            />
                            <FormErrorMessage>{coordinatorErrors.phone}</FormErrorMessage>
                        </FormControl> */}
                                    <FormControl isInvalid={formErrors["coord-user-id"]}>
                                        <FormLabel>Co-Ordinator: </FormLabel>
                                        <Select value={formValues['coord-user-id']} name="coord-user-id" id="coord-user-id" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {
                                                userList.data?.map((item, index) => {
                                                    if (item['user-type'] === CONSTANTS.USER_TYPE.COORDINATOR) {
                                                        return <option key={item.fname + item['lni-user-id'] + index} value={item['lni-user-id']}>{item.fname}&nbsp;{item.lname}</option>
                                                    }
                                                })
                                            }
                                        </Select>
                                        <FormErrorMessage>{formErrors["trainer-user-id"]}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={formErrors["trainer-user-id"]}>
                                        <FormLabel>Trainer: </FormLabel>
                                        <Select value={formValues['trainer-user-id']} name="trainer-user-id" id="trainer-user-id" onChange={handleChange}>
                                            <option value="">-Select-</option>
                                            {
                                                userList.data?.map((item, index) => {
                                                    if (item['user-type'] === CONSTANTS.USER_TYPE.TRAINER) {
                                                        return <option key={item.fname + item['lni-user-id'] + index} value={item['lni-user-id']}>{item.fname}&nbsp;{item.lname}</option>
                                                    }
                                                })
                                            }
                                        </Select>
                                        <FormErrorMessage>{formErrors["trainer-user-id"]}</FormErrorMessage>
                                    </FormControl>
                                    <Box mt={2}>
                                        <Flex align={'center'} justify={'center'} gap={2}>
                                            <Button colorScheme='blue' type="submit" isLoading={isLoading} className="fluid ui button blue" disabled={isLoading}>{id ? "Update" : "Submit"}</Button>
                                            <Button type="button" onClick={
                                                () => { navigate("/institute") }
                                            }>Cancel</Button>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </form>
                        </Flex>
                    </CardBody></Card>
            </Flex>
        </>
    )
}

export default CreateEditInstitute
