import { Navigate, Route, Routes } from "react-router-dom";
import React, { useContext } from "react";

import AboutUs from "./pages/Common/AboutUs";
import Academic from "./pages/Common/Academic";
import Adaptation from "./pages/Common/Adaptation";
import Assessment from "./pages/AssessmentManagement/Assessment";
import { AuthContext } from "./App";
import Communication from "./pages/Common/Communication";
import ContactUs from "./pages/Common/ContactUs";
import CoordinatorHome from "./pages/Common/Roles/CoordinatorHome";
import CreateAssessment from "./pages/AssessmentManagement/CreateAssessment";
import CreateEditInstitute from "./pages/InstituteManagement/CreateEditInstitute";
import CreateEditSyllabus from "./pages/SyllabusManagement/CreateSyllabus";
import CreateQuestion from "./pages/QuestionaireManagement/CreateQuestion";
import CreateUser from "./pages/UserManagement/CreateUser";
import DashBoardHome from "./pages/Common/Roles/DashBoardHome";
import Home from "./pages/Common/Home";
import Institute from "./pages/InstituteManagement/Institute";
import Login from "./pages/AuthManagement/Login";
import Otppage from "./pages/AuthManagement/Otppage";
import Pedagogy from "./pages/Common/Pedagogy";
import Personaldev from "./pages/Common/Personaldev";
import Program from "./pages/Common/Program";
// import Syllabus from './pages/SyllabusManagement/Syllabus';
import Questionaire from "./pages/QuestionaireManagement/Questionaire";
import QuestionaireBulkUpload from "./pages/QuestionaireManagement/QuestionaireBulkUpload";
import Quiz from "./pages/AssessmentManagement/Quiz";
import Reports from "./pages/ReportManagement/Report";
import StudentHome from "./pages/Common/Roles/StudentHome";
import ThankyouComponent from "./pages/Common/thankyou";
import TrainerHome from "./pages/Common/Roles/TrainerHome";
import { User } from "./pages/UserManagement/User";
import Wellbeing from "./pages/Common/Wellbeing";
import Syllabus from "./pages/SyllabusManagement/Syllabus";
import EditQuestionarie from "./pages/QuestionaireManagement/EditQuestionarie";
import Register from "./pages/AuthManagement/Register";
import RegisterCompetition from "./pages/AuthManagement/RegisterCompetition";
import RegisterC from "./pages/AuthManagement/RegisterC";

import Docs from "./components/Docs";
import { PrivacyPolicy } from "./constants/privacyPolicy";
import { TermsAndConditions } from "./constants/termsAndConditions";
import { CancellationPolicy } from "./constants/cancellationPolicy";
import { ShippingDelivery } from "./constants/shippingDelivery";

const SyllabusComponent = React.lazy(() =>
  import("./pages/SyllabusManagement/Syllabus")
);

function AppRoutes() {
  const [context] = useContext(AuthContext);
  const termsTitle = TermsAndConditions.title;
  const termsContents = TermsAndConditions.contents;

  const privacyTitle = PrivacyPolicy.title;
  const privacyContents = PrivacyPolicy.contents;

  const cancellationTitle = CancellationPolicy.title;
  const cancellationContents = CancellationPolicy.contents;

  const shippingTitle = ShippingDelivery.title;
  const shippingContents = ShippingDelivery.contents;

  return (
    <Routes>
      {/* <Route path="*" element={<Navigate to="forgot-password" replace/>} />
      <Route path="forgot-password" element={<ForgotPassword />} /> */}
      {!context.auth_key ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/cregister" element={<RegisterC />}  />
          <Route path="/cregister2" element={<RegisterC />} />
          <Route path="/otppage" element={<Otppage />} />
          <Route path="/users" element={<User />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/pedagogy" element={<Pedagogy />} />
          <Route path="/academic" element={<Academic />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/personaldev" element={<Personaldev />} />
          <Route path="/wellbeing" element={<Wellbeing />} />
          <Route path="/program" element={<Program />} />
          <Route path="/adaptation" element={<Adaptation />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
          <Route
            path="/terms-and-conditions"
            element={<Docs title={termsTitle} contents={termsContents} />}
          />
          <Route
            path="/privacy-policy"
            element={<Docs title={privacyTitle} contents={privacyContents} />}
          />
          <Route
            path="/terms"
            element={<Docs title={termsTitle} contents={termsContents} />}
          />
          <Route
            path="/privacy"
            element={<Docs title={privacyTitle} contents={privacyContents} />}
          />
          <Route
            path="/cancellation"
            element={<Docs title={cancellationTitle} contents={cancellationContents} />}
          />
          <Route
            path="/shipping"
            element={<Docs title={shippingTitle} contents={shippingContents} />}
          />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/otppage" element={<Otppage />} />
          {/* <Route path="/users?auth_key=:auth_key&user=:user&auth59_usr_id=:auth59_usr_id" element={<User />} /> */}
          <Route path="/student" element={<DashBoardHome />} />
          <Route path="/dashboard" element={<DashBoardHome />} />
          <Route path="/coord" element={<DashBoardHome />} />
          <Route path="/trainer" element={<DashBoardHome />} />
          <Route path="/users" element={<User />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/create-user" element={<CreateUser />} />
          <Route path="/edit-user/:id" element={<CreateUser />} />
          <Route path="/syllabus" element={<Syllabus />} />
          <Route path="/create-syllabus" element={<CreateEditSyllabus />} />
          <Route path="/edit-syllabus/:id" element={<CreateEditSyllabus />} />
          <Route path="/questionaire" element={<Questionaire />} />
          <Route path="/create-questionaire" element={<CreateQuestion />} />
          <Route path="/edit-questionaire" element={<EditQuestionarie />} />

          <Route
            path="/questionaire-upload"
            element={<QuestionaireBulkUpload />}
          />
          <Route path="/institute" element={<Institute />} />
          <Route path="/create-institute" element={<CreateEditInstitute />} />
          <Route path="/edit-institute/:id" element={<CreateEditInstitute />} />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/create-assessment" element={<CreateAssessment />} />
          <Route path="/quiz/:assessmentId" element={<Quiz />} />
          <Route path="/thankyou" element={<ThankyouComponent />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/terms-and-conditions"
            element={<Docs title={termsTitle} contents={termsContents} />}
          />
          <Route
            path="/privacy-policy"
            element={<Docs title={privacyTitle} contents={privacyContents} />}
          />
          <Route
            path="/cancellation"
            element={<Docs title={cancellationTitle} contents={cancellationContents} />}
          />
          <Route
            path="/shipping"
            element={<Docs title={shippingTitle} contents={shippingContents} />}
          />
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
