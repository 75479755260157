import React, { useState, useCallback, useEffect } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import axios from "axios";

import { useToast } from "@chakra-ui/react";
import { CONSTANTS } from "../../services/constants";
import { initialValues, validate } from "../../services/registerService";
import { initializeRazorpay } from "../../services/paymentv2";
import { USER_API } from "../../services/apiRoutes";
import { useParams, useNavigate } from "react-router-dom";
import qr from "../../images/logo.png";


import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Card,
  CardBody,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  Image,
  Text,
  Center,
  Link,
  Checkbox,
} from "@chakra-ui/react";



const RegisterCompetitionUser = () => {
  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    gender: 'female',
    age: '',
    college: '',
    department: '',
    semester: '',
    competitions: []
  });

  const toast = useToast();
  const [isOpen, setIsOpen] = useState(true);

  const [userDetails, setUserDetails] = useState({
    isLoading: false,
    data: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormValues((prevValues) => ({
        ...prevValues,
        competitions: checked
          ? [...prevValues.competitions, name]
          : prevValues.competitions.filter((item) => item !== name)
      }));
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  async function getUserDetails(id, source) {
    const requestBody = { "lni-user-id": id };
    await postCall(USER_API.USER, requestBody, {}, source)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails((prev) => ({ ...prev, data: res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message); // => the response payload
        }
      })
      .finally(() => setUserDetails((prev) => ({ ...prev, isLoading: false })));
  }
  useEffect(() => {
    const source = axiosSource();
    if (id) {
      setUserDetails((prev) => ({ ...prev, isLoading: true }));
      getUserDetails(Number(id), source);
    }
    return () => {
      source.cancel();
    };
  }, [id]);

  const handleSubmit = () => {
    const errors = validateForm(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const requestBody = {
        ...formValues,
        "institute": "Third Party",
        "program": "INTER COLLEGIATE COMPETITION",
        "programDesc": "Drug Abuse Awareness and Prevention",
        "auth59-user-id": 0,
        "lni-user-id": 0,
      };

      //Submit form data to the server
      lookupRegister(requestBody)
    }
  };

  const lookupRegister = async (requestBody) => {
    try {
      const response = await postCall(USER_API.COMPETITIONREGISTER, requestBody);
      alert('Thank you for the registration');

      console.log("Payment success call:", response.data);
    } catch (error) {
      console.error("Error in payment success call:", error);
      alert('Thank you for the registration');
      window.location.reload();

      //return false;
    }
  };

  console.log(modalVisible);

  const handleSubmit2 = useCallback(
    (e) => {
      // if (!showTime) {
      //   toast({
      //     title: "Please Click Program:A21Skills" || "",
      //     status: "error",
      //     duration: CONSTANTS.DURATION,
      //   });
      // } else {
      // }
      e.preventDefault();
      if (validate(formValues, setFormErrors)) {
        setIsLoading(true);

        const requestBody = {
          ...formValues,
          "auth59-user-id": formValues["auth59-user-id"],
          "lni-user-id": formValues["lni-user-id"],
        };

        lookupRegister(requestBody);

        setIsLoading(false);
      }
    },
    [formValues, showTime]
  );

  const validateForm = (values) => {
    const errors = {};
    if (!/^[A-Za-z\s]+$/.test(values.fname)) {
      errors.fname = 'First name should contain only alphabets and spaces';
    }
    if (!/^[A-Za-z\s]+$/.test(values.lname)) {
      errors.lname = 'Last name should contain only alphabets and spaces';
    }
    if (!/^\S+@\S+\.\S+$/.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!/^\d{10}$/.test(values.phone)) {
      errors.phone = 'Phone number should be a 10 digit number';
    }
    if (values.age < 17 || values.age > 25) {
      errors.age = 'Age should be between 17 and 30';
    }
    // if (!/^[A-Za-z\s]+$/.test(values.college)) {
    //   errors.college = 'College name should contain only alphabets and spaces';
    // }
    if (!/^[A-Za-z\s]+$/.test(values.department)) {
      errors.department = 'Department name should contain only alphabets and spaces';
    }
    if (!/^[A-Za-z0-9\s]+$/.test(values.semester)) {
      errors.semester = 'Semester should contain alphabets, numbers, and spaces';
    }
    if (values.competitions.length === 0) {
      errors.competitions = 'You must select at least one competition';
    }
    return errors;
  };

  return (
    <Flex direction="column" maxW="500px" mx="auto" mt="20px">


      <FormControl>
        <FormLabel fontSize="sm">
          Program :{" "}
          <Link onClick={() => setShowTime(!showTime)}>
            INTER COLLEGIATE COMPETITION
          </Link>
        </FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm">
          Desc:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Drug Abuse Awareness and Prevention
        </FormLabel>
        {/* {showTime && (
                      <>
                        <FormLabel ml="110px" fontSize="sm">
                          6 Hours Program on 6th, 7th and 8th July 2024
                        </FormLabel>
                        <FormLabel ml="110px" fontSize="sm">
                          7PM - 9PM
                        </FormLabel>
                      </>
                    )} */}
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm">
          Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 20/09/2024 &nbsp; - &nbsp; 9AM to 12:30PM
        </FormLabel>
        <FormLabel fontSize="sm">
          Venue: &nbsp;&nbsp;&nbsp;&nbsp; Women's Christian College (W.C.C) College Road, Chennai - 6
        </FormLabel>
      </FormControl>
      <Text fontSize="sm" as="b">Please provide accurate details. The E-Certificate will be sent to your E-Mail after you complete the competition</Text>
      <Center><Text fontSize='15px' color='tomato' as="b">REGISTRATION IS OPEN TO WOMEN STUDENTS ONLY</Text></Center><br />



      <FormControl isInvalid={formErrors.fname}>
        <FormLabel>First Name</FormLabel>
        <Input
          size="sm"
          type="text"
          name="fname"
          maxLength="75"
          placeholder="Enter First Name"
          value={formValues.fname}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.fname}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.lname}>
        <FormLabel>Last Name</FormLabel>
        <Input
          size="sm"
          type="text"
          name="lname"
          maxLength="75"
          placeholder="Enter Last Name"
          value={formValues.lname}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.lname}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.email}>
        <FormLabel>Email Address</FormLabel>
        <Input
          size="sm"
          type="text"
          name="email"
          maxLength="200"
          placeholder="Enter Email Address"
          value={formValues.email}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.email}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.phone}>
        <FormLabel>Phone Number</FormLabel>
        <Input
          size="sm"
          type="text"
          name="phone"
          maxLength="15"
          placeholder="Enter Phone Number"
          value={formValues.phone}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.gender}>
        <FormLabel>Gender</FormLabel>
        <Select
          size="sm"
          value={formValues.gender}
          name="gender"
          id="gender"
          onChange={handleChange}
        >
          <option value="female">Female</option>
        </Select>
        <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.age}>
        <FormLabel>Age</FormLabel>
        <Input
          size="sm"
          type="text"
          name="age"
          maxLength="2"
          placeholder="Enter Age"
          value={formValues.age}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.age}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.college}>
        <FormLabel>College</FormLabel>

        <Select
          size="sm"
          value={formValues.college}
          name="college"
          id="college"
          onChange={handleChange}
        >
          <option value="">Choose Your College</option>
          <option value="W.C.C.">W.C.C.</option>
          <option value="Stella Maris">Stella Maris</option>
          <option value="Loyola">Loyola</option>
          <option value="Ethiraj">Ethiraj</option>
          <option value="MOP Vaishnav">MOP Vaishnav</option>
          <option value="D.G.Vaishnav">D.G.Vaishnav</option>
          <option value="M.C.C.">M.C.C.</option>
          <option value="Patrician">Patrician</option>
          <option value="Don Bosco">Don Bosco</option>
          <option value="St. Thomas">St. Thomas</option>
          <option value="Mar Gregorious">Mar Gregorious</option>
          <option value="Queen Marys">Queen Marys</option>
          <option value="S.I.E.T.">S.I.E.T.</option>
          <option value="Presidency">Presidency</option>
          <option value="Stella Matutina">Stella Matutina</option>
          <option value="St. Christophers">St. Christophers</option>
          <option value="Guru Shree">Guru Shree</option>
          <option value="Sashan">Sashan</option>
          <option value="C.T.T.E. ">C.T.T.E. </option>
          <option value="Bharathi">Bharathi</option>
          <option value="Anna Adarsh">Anna Adarsh</option>
          <option value="Valliammal">Valliammal</option>
          <option value="St.Anns">St.Anns</option>
          <option value="Krishnaswamy">Krishnaswamy</option>
          <option value="Ewart">Ewart</option>
          <option value="R.K.Nagar Govt.">R.K.Nagar Govt.</option>
          <option value="Chellammal">Chellammal</option>
          <option value="Bhaktavatsalam">Bhaktavatsalam</option>
          <option value="Quaid-E-Millath">Quaid-E-Millath</option>
          <option value="S.D.N.B.">S.D.N.B.</option>
          <option value="Annai Violet">Annai Violet</option>
          <option value="M.S.S.W.">M.S.S.W.</option>
          <option value="A.M.Jain">A.M.Jain</option>
          <option value="Annai Veilankanni">Annai Veilankanni</option>
          <option value="Meenakshi">Meenakshi</option>
          <option value="Dr.M.G.R.Janaki">Dr.M.G.R.Janaki</option>
          <option value="Kanyaka Parameshwari">Kanyaka Parameshwari</option>
        </Select>


        <FormErrorMessage>{formErrors.college}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.department}>
        <FormLabel>Department</FormLabel>
        <Input
          size="sm"
          type="text"
          name="department"
          maxLength="50"
          placeholder="Enter Department"
          value={formValues.department}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.department}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={formErrors.semester}>
        <FormLabel>Your Current Semester</FormLabel>
        <Input
          size="sm"
          type="text"
          name="semester"
          placeholder="Enter Semester"
          maxLength="10"
          value={formValues.semester}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.semester}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={formErrors.competitions}>
        <FormLabel>Register for</FormLabel>
        <Checkbox
          size="sm"
          name="Essay Competition (English)"
          onChange={handleChange}
        >
          Essay Competition (English)&nbsp;&nbsp;
        </Checkbox>
        <Checkbox
          size="sm"
          name="Essay Competition (Tamil)"
          onChange={handleChange}
        >
          Essay Competition (Tamil)&nbsp;&nbsp;
        </Checkbox>
        <Checkbox
          size="sm"
          name="Poetry Competition (English)"
          onChange={handleChange}
        >
          Poetry Competition (English)&nbsp;&nbsp;
        </Checkbox>
        <Checkbox
          size="sm"
          name="Poetry Competition (Tamil)"
          onChange={handleChange}
        >
          Poetry Competition (Tamil)&nbsp;&nbsp;
        </Checkbox>
        <Checkbox
          size="sm"
          name="Speech Competition (English)"
          onChange={handleChange}
        >
          Speech Competition (English)&nbsp;&nbsp;
        </Checkbox>
        <Checkbox
          size="sm"
          name="Speech Competition (Tamil)"
          onChange={handleChange}
        >
          Speech Competition (Tamil)&nbsp;&nbsp;
        </Checkbox>
        <FormErrorMessage>{formErrors.competitions}</FormErrorMessage>
      </FormControl>

      <Box mt={2} >
        <Flex align={"center"} justify={"center"} gap={2}>
          <Button
            size={"sm"}
            colorScheme="blue"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Register
          </Button>
          <Button
            size={"sm"}
            colorScheme="gray"
            type="button"
            onClick={() => {
              navigate("/home");
            }}
          >
            Cancel
          </Button>
        </Flex>
      </Box>
    </Flex>

  );
};

export default RegisterCompetitionUser;
