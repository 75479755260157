import "./userStyle.css";

// import { Flex, Link, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { memo } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";
import { useBreakpointValue } from "@chakra-ui/react";
import { useColorModeValue, Link, Flex, Container, Text, Box } from "@chakra-ui/react"
import { CONSTANTS } from "../../services/constants";
import { useNavigate } from "react-router-dom";
import antony from '../../images/antony.png';

function UserList({ userList, changeStatus = () => { } }) {
    console.log('🚀 ~ file: UserList.js:8 ~ UserList ~ userList:', userList)
    const navigate = useNavigate();
    const linkHoverColor = useColorModeValue('#0563b4', '#0563b4');
    const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
    const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
    const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });
    const handleEdit = (id) => {
        navigate(`/edit-user/${id}`);
    }

    return (
      <>
        <Flex mt={5} position={"relative"}>
          <Table variant={variant} colorScheme={colorScheme} mt={5}>
            <Thead
              backgroundColor={popoverContentBgColor}
              position={"sticky"}
              top={0}
            >
              <Tr>
                <Th fontSize={"2xs"}>Name</Th>
                <Th fontSize={"2xs"}>Contact Number</Th>
                <Th fontSize={"2xs"}>Email Address</Th>
                <Th fontSize={"2xs"}>User Type</Th>
                <Th fontSize={"2xs"}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userList.data && userList.data.length > 0 ? (
                userList.data.map((item, index) => (
                  <Tr key={item.email + index}>
                    <Td fontSize={"2xs"}>
                      {item.fname}&nbsp;{item.lname}
                    </Td>
                    <Td fontSize={"2xs"}>{item.phone || "-"}</Td>
                    <Td fontSize={"2xs"}>{item.email || "-"}</Td>
                    <Td fontSize={"2xs"}>
                      {CONSTANTS.USER_TYPE_LABEL[item["user-type"]] || "-"}
                    </Td>
                    <Td fontSize={"2xs"} className="action_flex">
                      <Link
                        _hover={{ color: linkHoverColor }}
                        href={"#"}
                        onClick={() => handleEdit(item["lni-user-id"])}
                      >
                        Edit
                      </Link>
                      <Link
                        _hover={{ color: linkHoverColor }}
                        href="#"
                        onClick={() => changeStatus(item)}
                      >
                        {item["active"] ? "InActive" : "Active"}
                      </Link>
                    </Td>
                  </Tr>
                ))
              ) : userList.isLoading ? null : (
                <Tr>
                  <Td>No data found</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>

        <Box className="footer" fontFamily={"Segoe UI"} width={"100%"}>
          <br />
          <hr />
          <center>
            <img
              style={{
                width: "350px",
                height: "69px",
                marginTop: "10px",
              }}
              src={antony}
              alt="logo"
            />
          </center>
          <Container as="footer" role="contentinfo" bottom={0}>
            <Box padding="10px 0px 0px 0px">
              <Flex align={"center"} justify={"center"} gap={2}>
                <Link
                  fontSize="sm"
                  href="../privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                |
                <Link
                  fontSize="sm"
                  href="../terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>
              </Flex>
            </Box>
            <Text fontSize="14px" color="fg.subtle" textAlign={"center"}>
              <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam,
              Chennai – 600 092.
              <br />
              <Flex
                marginBottom={"5px"}
                justifyContent={"center"}
                flexWrap={"wrap"}
              >
                <Box width={"170px"}>
                  <Text>
                    <b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;
                  </Text>
                </Box>
                <Box width={"180px"}>
                  <Text>
                    <b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;
                  </Text>
                </Box>
                <Box width={"180px"}>
                  <Text>
                    <b>Email:</b>&nbsp;A3minds@gmail.com
                  </Text>
                </Box>
              </Flex>
            </Text>
            <Text fontSize="12px" color="fg.subtle" textAlign={"center"}>
              &copy; {new Date().getFullYear()} All rights reserved.
            </Text>
          </Container>
        </Box>
      </>
    );
}

export default UserList;