import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { ASSESSMENT_API, INSTITUTE_API, SYLLABUS_API, SECTION_API } from '../../services/apiRoutes';
import { axiosSource, postCall } from '../../services/httpService';
import { useNavigate } from 'react-router-dom';
import { Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Select, Box, Button, FormErrorMessage, Card, CardBody, useToast, Container } from "@chakra-ui/react";
import { CONSTANTS } from '../../services/constants';
import { AuthContext } from '../../App';
import Multiselect from 'multiselect-react-dropdown';

function CreateAssessment() {
  const navigate = useNavigate();
  const toast = useToast();
  const [context] = useContext(AuthContext);

  const assessmentVal = {
    "institution-id": "",
    "syllabus-id": "",
    "syllabus-name": "",
    "complete-by": "",
    "level": "",
    "grade": "",
    "grade-id": "",
    "level-id": "",
    "type-id": "",
    "session-type-id": ""
  };
  const currentDate = new Date();
  const [assessmentValues, setAssessmentValues] = useState({ ...assessmentVal });
  const [section, setSection] = useState([]);
  const [sectionId, setSectionId] = useState([]);
  const [assessmentErrors, setAssessmentErrors] = useState({});
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: [],
  });
  const [syllabusList, SetSyllabusList] = useState({
    isLoading: false,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);

  const onMultiSelect = (e) => {
    const eachSectionId = e.map(val => section.find(eSec => eSec["section-name"] == val)["section-id"] )
    console.log(eachSectionId)
    setSectionId((prev) => eachSectionId)
}

  async function getInstituteList(source) {
    let body = {};
    if (!isAdmin) {
      body = { ...body, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true)
      .then((res) => {
        if (res.status === 200) {
          SetInstituteList((prev) => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message); // => the response payload
        }
      })
      .finally(() =>
        SetInstituteList((prev) => ({ ...prev, isLoading: false }))
      );
  }

  const getSyllabusList = useCallback(
    async (source) => {
      const requestBody = {
        "institution-id": (assessmentValues['institution-id'] ? Number(assessmentValues['institution-id']) : 0)
      };
      await postCall(SYLLABUS_API.SYLLABUS, requestBody, {}, source)
        .then((res) => {
          if (res.status === 200) {
            const filterValue = [...new Set(res?.data.map(eachSylabus => eachSylabus["syllabus-name"]))]
            const filteredSyllabus = filterValue.map(eachSyllabusName => res?.data.find(eachdata => eachdata["syllabus-name"] == eachSyllabusName))
            SetSyllabusList((prev) => ({ ...prev, data: filteredSyllabus }));
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error.message); // => the response payload
          }
        })
        .finally(() =>
          SetSyllabusList((prev) => ({ ...prev, isLoading: false }))
        );
    },
    [SetSyllabusList, assessmentValues["institution-id"]]
  );

  useEffect(() => {
    SetInstituteList((prev) => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getInstituteList(source);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (!assessmentValues["institution-id"]) {
      setAssessmentValues(prev => ({ ...prev, 'syllabus-id': "" }));
      return;
    }
    const source = axiosSource();
    getSyllabusList(source);
  }, [assessmentValues["institution-id"], getSyllabusList]);

  useEffect(() => {
    postCall(SECTION_API.SECTION, {"institution-id" : assessmentValues["institution-id"], "syllabus-name" : assessmentValues["syllabus-name"]}, {}).then((res) => {
      if (res.status === 200 && res?.data.length) {
        setSection((prev) => ([...res.data]))
      } else {
        setSection((prev) => ([]))
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload
      }
    })
    .finally(() =>
      SetSyllabusList((prev) => ({ ...prev, isLoading: false }))
    );
  }, [assessmentValues["syllabus-name"]]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "syllabus-id") {
      const selectedSyllabus = syllabusList.data?.filter(item => {
        if (item['syllabus-id'] == value) return item;
        return null
      });
      if (selectedSyllabus && selectedSyllabus.length > 0) {
        console.log(selectedSyllabus);
        setAssessmentValues(prev => ({ ...prev, [name]: value, "syllabus-name": selectedSyllabus[0]['syllabus-name'], "level": selectedSyllabus[0]['level-name'], "grade": selectedSyllabus[0]['grade-name'], "grade-id": selectedSyllabus[0]['grade-id'], "level-id": selectedSyllabus[0]['level-id'], "type-id": selectedSyllabus[0]['type-id'], "session-type-id": selectedSyllabus[0]['session-type-id'] }));
      };
    } else {
      setAssessmentValues((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const validateQuestionForm = () => {
    const newErrors = {};

    if (assessmentValues['institution-id'] === "") {
      newErrors['institution-id'] = "Institue is required!";
    }
    if (assessmentValues['syllabus-id'] === "") {
      newErrors['syllabus-id'] = "Syllabus is required!";
    }
    if (assessmentValues['complete-by'] === "") {
      newErrors['complete-by'] = "Complete By is required!";
    }
    if (assessmentValues['name'] === "") {
      newErrors['name'] = "Name is required!";
    }

    setAssessmentErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateQuestionForm()) {
      setIsLoading(true);
      createAssessment();
    } else {
      console.log("Form validation failed");
    }
  };

  /* creating assessment */
  const createAssessment = useCallback(() => {
    const body = {
      "institution-id": (assessmentValues['institution-id'] ? Number(assessmentValues['institution-id']) : 0),
      // "syllabus-id": assessmentValues['syllabus-id'],
      "syllabus-name": assessmentValues['syllabus-name'],
      "section-id": sectionId,
      "complete-by": assessmentValues['complete-by'],
      "level-id":  assessmentValues["level-id"],
      "grade-id": assessmentValues["grade-id"],
      "type-id": assessmentValues["type-id"],
      "session-type-id": assessmentValues["session-type-id"]
    };
    postCall(ASSESSMENT_API.ASSESSMENT_ADD_EDIT_V2, body, null, null, false).then(function (result) {
      if (result.data.success != '') {
        toast({
          title: result.data.success || "",
          status: 'success',
          duration: CONSTANTS.DURATION
        });
      } else {
        toast({
          title: result.data.error || "",
          status: 'error',
          duration: CONSTANTS.DURATION
        });
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => setIsLoading(false));
  }, [assessmentValues]);

  return (
    <>
      {(instituteList.isLoading || syllabusList.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
      <Flex alignItems="center" justifyContent="center">
        <Card m={2} mt={5}>
          <CardBody h={{ base: "87vh", lg: "125vh" }}>
            <Flex justifyContent={'center'}>
              <form className="bulkupload-section form-data" onSubmit={handleSubmit}>
                <Heading mb={4} textAlign={"center"}>Assessment Create</Heading>
                <Flex flexDirection="column" justifyContent={'center'} minWidth={{ base: 300, md: 400, lg: 400 }} maxWidth={300}>
                  <FormControl isInvalid={assessmentErrors["institution-id"]} >
                    <FormLabel>Institute:&nbsp;</FormLabel>

                    <Select
                      name="institution-id"
                      id="institution-id"
                      value={assessmentValues["institution-id"]}
                      onChange={handleChange}
                    >
                      <option key="select" value={0}>
                        -Select-
                      </option>
                      {instituteList?.data?.map((option, index) => (
                        <option
                          key={
                            option["institution-id"] +
                            option["institution-name"] +
                            index
                          }
                          value={option["institution-id"]}
                        >
                          {option["institution-name"]}
                        </option>
                      ))}
                    </Select>
                    {/* {assessmentErrors['institution-id'] && (
              <span className="error-message">{assessmentErrors['institution-id']}</span>
            )} */}
                    <FormErrorMessage>{assessmentErrors["institution-id"]}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={assessmentErrors["syllabus-id"]} size={'sm'} >
                    <FormLabel>Session Description:&nbsp;</FormLabel>
                    <Select
                      name="syllabus-id"
                      id="syllabus-id"
                      value={assessmentValues["syllabus-id"]}
                      onChange={handleChange}

                    >
                      <option key="select" value={""}>
                        -Select-
                      </option>
                      {syllabusList?.data?.map((option, index) => (
                        <option
                          key={
                            option["syllabus-id"] + option["syllabus-name"] + index
                          }
                          value={option["syllabus-id"]}
                        >
                          {option["syllabus-name"]}
                        </option>
                      ))}
                    </Select>
                    {/* {assessmentErrors['syllabus-id'] && (
              <span className="error-message">{assessmentErrors['syllabus-id']}</span>
            )} */}
                    <FormErrorMessage>{assessmentErrors["syllabus-id"]}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                                            <FormLabel>Section/Year</FormLabel>
                                            <Box>
                                                <Multiselect
                                                    isObject={false}
                                                    selectedValues={''}
                                                    options={section.map((eachSection) => eachSection["section-name"])}
                                                    onSelect={onMultiSelect}
                                                    onRemove={onMultiSelect}
                                                /></Box>
                                            <FormErrorMessage>hello</FormErrorMessage>
                                        </FormControl>
                  <FormControl >
                    <FormLabel>Level:&nbsp;</FormLabel>
                    <Input
                      readOnly
                      type="text"
                      name="level"
                      id="level"
                      placeholder="Level"
                      value={assessmentValues.level}
                      disabled={true}
                    />
                  </FormControl>

                  <FormControl >
                    <FormLabel>Grade:&nbsp;</FormLabel>
                    <Input
                      readOnly
                      type="text"
                      name="grade"
                      id="grade"
                      placeholder="Grade"
                      value={assessmentValues.grade}
                      disabled={true}
                    />
                  </FormControl>

                  <FormControl isInvalid={assessmentErrors["complete-by"]}>
                    <FormLabel>Complete By:&nbsp;</FormLabel>
                    <Input type="date"
                      min={CONSTANTS.CURRENT_DATE.toLocaleDateString('en-CA')}
                      value={assessmentValues['complete-by']}
                      name="complete-by" id="complete-by"
                      onChange={handleChange} />
                    {/* {assessmentErrors['complete-by'] && (
              <span className="error-message">{assessmentErrors['complete-by']}</span>
            )} */}
                    <FormErrorMessage>{assessmentErrors["complete-by"]}</FormErrorMessage>
                  </FormControl>
                  <Box mt={2}>
                    <Flex align={'center'} justify={'center'} gap={2}>
                      <Button
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex></Box>
                </Flex>
              </form>
            </Flex>
          </CardBody>
        </Card>
      </Flex >
    </>
  )
}

export default CreateAssessment;
