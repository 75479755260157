import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'

function StudentHome() {
  return (
    <Flex mt={5} flexDirection={'column'} h="87vh" alignItems="center">
      <Heading>Welcome Student</Heading>
    </Flex>
  )
}

export default StudentHome
