// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .user-heading {
    color: var(--color);
} */

.search {
    display: flex;
}

/* .form-label {
    color: var(--color);
} */

/* table {
    color: var(--color);
} */

.bulkupload-section {
    margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/InstituteManagement/institutesStyle.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,aAAa;AACjB;;AAEA;;GAEG;;AAEH;;GAEG;;AAEH;IACI,cAAc;AAClB","sourcesContent":["/* .user-heading {\n    color: var(--color);\n} */\n\n.search {\n    display: flex;\n}\n\n/* .form-label {\n    color: var(--color);\n} */\n\n/* table {\n    color: var(--color);\n} */\n\n.bulkupload-section {\n    margin: 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
