import React, { memo } from "react";
// import { Table, Thead, Tbody, Tr, Td, Th, Flex, useColorModeValue, Link } from "@chakra-ui/react"
import { useColorModeValue, Link, Flex, Container, Text, Box } from "@chakra-ui/react"
import { formatDate } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import TableExample from "../../TestTable";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";
import { useBreakpointValue } from "@chakra-ui/react";
import antony from '../../images/antony.png';

function SyllabusList({ syllabusList, changeStatus = () => { }, isAdmin, onDownload, infoMsg }) {
    const navigate = useNavigate();
    const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
    const linkHoverColor = useColorModeValue('#0563b4', '#0563b4');
    const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
    const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });

    const handleEdit = (id, item) => {
        navigate(`/edit-syllabus/${id}`);
    }

    return (
        <>
            <Flex className="lni-table-section" mt={5} maxHeight="600px" overflowY={"auto"} maxWidth={"100%"}>
                <Table variant={variant} colorScheme={colorScheme} mt={5}   >
                    <Thead backgroundColor={popoverContentBgColor}>
                        <Tr>
                            <Th fontSize={'2xs'}>Institute Name</Th>
                            <Th fontSize={'2xs'}>Syllabus Type</Th>
                            <Th fontSize={'2xs'}>Session Desc</Th>
                            <Th fontSize={'2xs'}>Grade/Dept</Th>
                            <Th fontSize={'2xs'}>Level</Th>
                            <Th fontSize={'2xs'}>Section/Year</Th>
                            <Th fontSize={'2xs'}>Complete By</Th>
                            {!isAdmin && <Th fontSize={'2xs'}>Trainer Complete By</Th>}
                            <Th fontSize={'2xs'}>File</Th>
                            <Th fontSize={'2xs'}>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(syllabusList.data && syllabusList.data.length > 0) ? syllabusList.data.map((item, index) => (
                            <Tr key={item["syllabus-id"]}>
                                <Td fontSize={'2xs'} >{item["institution-name"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{item["type-name"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{item["syllabus-name"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{item["grade-name"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{item["level-name"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{item["grade-section"] || "-"}</Td>
                                <Td fontSize={'2xs'}>{formatDate(item["complete-by"]) || "-"}</Td>
                                {!isAdmin && <Td fontSize={'2xs'}>{(item["completed-on"] === 'None') ? "-" : formatDate(item["completed-on"])}</Td>}
                                <Td fontSize={'2xs'}>
                                    <Link _hover={{ color: linkHoverColor }} href={"#"} onClick={() => onDownload(item['file-url'], item)}>Download</Link>
                                </Td>
                                <Td fontSize={'2xs'} >
                                    <Flex gap={3}>

                                        <Link _hover={{ color: linkHoverColor }} href={"#"} onClick={() => handleEdit(item['syllabus-id'], item)}>Edit</Link>
                                        {isAdmin && <Link _hover={{ color: linkHoverColor }} href="#" onClick={() => changeStatus(item)}>{item['active'] ? 'InActive' : 'Active'}</Link>}
                                    </Flex>
                                </Td>
                            </Tr>
                        )) : (syllabusList.isLoading ? null : <Tr><Td fontSize={'2xs'} textAlign={'center'} >{infoMsg}</Td></Tr>)}
                    </Tbody>
                </Table>
            </Flex>


        </>
    )
}

export default memo(SyllabusList);