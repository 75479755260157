import {
  Box,
  Flex,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
  Heading,
  Text,
  Center,
  Button,
  Image,
  useTheme,
  Wrap, WrapItem
} from "@chakra-ui/react";
import century from "../../images/21century.jpg";
import adaptive from "../../images/Adaptive.jpg";
import orgskills from "../../images/Orgskills.jpg";
import time from "../../images/Time.jpg";
import entrepreneur from "../../images/ENTREPRENEURIAL_SKILLS.jpg";
import emotional from "../../images/EMOTIONAL_INTELLIGENCE.jpg";
import communication from "../../images/COMMUNICATION_SKILLS.jpg";
import social from "../../images/SOCIAL_SKILLS.jpg";
import self from "../../images/SELF.jpg";
import budget from "../../images/BUDGETING.jpg";
import balancing from "../../images/balance_life.jpg";
import enriching from "../../images/ENRICHING_CORE_VALUES.jpg";
import ambition from "../../images/ambition_vision.jpg";
import elevating from "../../images/ELEVATING_CHARACTER.jpg";
import growth from "../../images/GROWTH_MINDSET.jpg";
import nonCognitive from "../../images/NON-COGNITIVE_TRAITS_AND_HABITS.jpg";
import soft from "../../images/SOFT_SKILLS.jpg";
import digital from "../../images/DIGITAL_LEARNING_CONTENT.jpg";
import personality from "../../images/PERSONALITY_DEVELOPMENT.jpg";
import career from "../../images/CAREER_GUIDANCE.jpg"
import { useNavigate } from "react-router-dom";

import OurTrain1 from "../../images/ourtrain1.jpg";
import OurTrain2 from "../../images/ourtrain2.jpg";
import OurTrain3 from "../../images/ourtrain3.jpg";
import OurTrain4 from "../../images/ourtrain4.jpg";
import OurTrain5 from "../../images/ourtrain5.jpg";
import our_program_banner from "../../images/our_program_banner.jpg";


export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

export default function Program() {
  const theme = useTheme();
  const { programStyles, topic, fFont, aFont } = theme;
  const navigate = useNavigate();

  const handleExplore = (url) => {
    navigate(url)
  }
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>OUR TRAINING MODULES</b></Text>
      </Flex>
      <Box position={"relative"}>

        <img src={our_program_banner} alt="aboutus" />
      </Box>
      <Flex justifyContent={"center"}>
        <Box as={Container} maxW="3xl" mt={14} mb={8} p={4}>
          <Grid>
            <Box mt={5} fontSize={fFont}>
              Our Programs are designed for Non-academic Skills which are
              essential for Individuals because they play a crucial
              role in overall development and future success.
            </Box>
            <Box mt={5} fontSize={fFont}>
              Non-academic Skills complement academic knowledge and are
              fundamental to a child's holistic growth. They equip children with
              the tools needed to navigate challenges, communicate effectively,
              and contribute positively to society as they mature into
              adulthood. These skills support children in building fulfilling
              careers, nurturing healthy relationships, and maintaining a
              balanced lifestyle.
            </Box>
          </Grid>
          <Divider mt={5} mb={5} />
          <Text fontSize={{ base: "md", md: "3xl", lg: "4xl" }} ><b>Our Training Modules are:</b></Text>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
            }}
            gap={{ base: "8", sm: "12", md: "16" }}
            mt={5}
            mb={5}
          >
            <Box>
              <Image {...programStyles.imageStyle} src={adaptive} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  ADAPTATION TO TECHNOLOGY
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Successful <strong>Adaptation to Technology</strong> requires understanding, acceptance, and effective incorporation of LATEST technological innovations into our daily routines and societal structures.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key aspects of adaptation to technology are “Awareness and Education, Accessibility and Inclusivity, Security and Privacy, Balancing Technology Use, Continuous Learning and Long-term Planning”</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={century} alt="21st Century" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  21ST CENTURY SKILLS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>'21st-Century Skills'</strong> go beyond traditional academic knowledge and focus on equipping individuals with the tools they need to navigate an ever-changing global landscape, both in studies and personal lives.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key aspects for 21st-century skills are “Critical thinking and problem-solving, Creativity and Innovation, Information literacy, Media literacy, Digital literacy, Cultural Awareness And Global Citizenship, Resilience Initiative and Entrepreneurial Mind-Set”</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={time} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  TIME MANAGEMENT
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Effective <strong>'Time Management'</strong> involves setting priorities, allocating specific time for tasks, and maintaining focus on the most important activities.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Some key principles and strategies for improving time management are <strong>“Set Clear Goals, Create a To-Do List, Use Time Management Techniques, Minimize Distractions, Practice Work-Life Balance and Review and Adjust”</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={orgskills} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  ORGANISATIONAL SKILLS
                </Text>
              </Flex>

              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Organizational Skills</strong> are essential in both personal and professional settings and can greatly contribute to productivity, effectiveness, and overall success.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key components of organizational skills are “Planning and Goal Setting, Prioritization, Task Delegation, Problem-Solving and Stress Management”</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Organizational skills can be honed and developed over time through practice, self-awareness, and willingness to improve.
                </Text>
              </Box>

            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={entrepreneur} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  ENTREPRENEURIAL SKILLS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Entrepreneurial Skills</strong> are essential for anyone who aspires to become an entrepreneur and navigate the challenges of starting and running a business.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key entrepreneurial skills are Creativity and Innovation, Risk Management, Vision and Planning, Leadership, Financial Literacy, Sales and Marketing, Negotiation Skills, Networking, Problem-Solving and Customer Focus</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={emotional} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  EMOTIONAL INTELLIGENCE
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Emotional Intelligence</strong> involves a set of skills and qualities that enable individuals to navigate social interactions, handle conflicts, and make sound decisions with empathy and sensitivity.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Major domains of emotional intelligence are Self-awareness, Self-regulation, Motivation, Empathy, and Social Skills</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={communication} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  COMMUNICATION SKILLS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Effective Communication</strong> involves both speaking and listening, as well as non-verbal cues like body language and facial expressions.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some essential components of communication skills are Clarity, Listening, Non-Verbal Communication, Respect, Assertiveness, Open-Mindedness, and Conflict Resolution</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  It's essential to practice and continuously work on enhancing these skills to become a more effective communicator.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={social} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  SOCIAL SKILLS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Social Skills</strong> play a crucial role in building and maintaining relationships, understanding social norms, expressing oneself, and navigating various social contexts.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some important Social Skills are Communication, Active listening, Conflict resolution, Assertiveness, Flexibility, Respect, Cooperation and teamwork and Networking</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Improving Social Skills often involves practice, self-awareness, and continuous learning.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={self} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} height={'65px'}  >
                <Text color={'white'} fontSize="15px" mt={3}>
                  SELF-ADVOCACY SELF-CARE & SELF-DISCIPLINE
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Self-Advocacy, Self-Care and Self-Discipline</strong> are interconnected skills that are needed to improve Physical, Mental, and Emotional well-being. These skills empower individuals to become more confident and successful in life.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key aspects are Educate yourself, have proper physical, emotional and mental self-care, Nurture social and spiritual care, have proper focus and concentration by becoming a strong self-advocate and develop self-care & discipline</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={budget} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} height={'65px'}  >
                <Text color={'white'} fontSize="15px" >
                  BUDGETING
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Budgeting</strong> is the process of creating a plan for how to manage and allocate your financial resources. It involves setting financial goals, estimating income, and determining how much money to allocate to various expenses and savings.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key steps and principles involved in budgeting are Set financial goals, Track your income, List your expenses, Differentiate needs from wants, Create a budget plan, Review and adjust regularly and Build an emergency fund</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={balancing} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  BALANCING LIFE
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Balancing life</strong> is an essential aspect of maintaining overall well-being and happiness. It involves managing various aspects of your life to ensure that you allocate time and energy to different areas, such as work, family, personal interests, and self-care.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some tips to help you achieve a better balance are Prioritize, Time management, Delegate, Social support and Reflect and reassess</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  It's more about finding a healthy and sustainable equilibrium that allows you to thrive in different aspects of your life.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={enriching} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  ENRICHING CORE VALUES
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Enriching Core Values</strong> are the fundamental beliefs and principles that guide behaviour, decision-making, and interactions.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some steps to enrich Core Values are Reflection and self-awareness, Alignment with mission and vision, Prioritization and focus, Leading by example and Integration into processes and practices</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  When Core Values are deeply embedded in the fabric of an entity, they become a powerful force that drives positive change and cultivates a strong and purposeful identity.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={ambition} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  AMBITION & VISION
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Ambition and Vision</strong> are two fundamental elements that drive individuals toward achieving their goals and creating a meaningful impact on the world.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Ambition can manifest in various aspects of life, such as personal achievements, career advancement, or contributing to the betterment of society. A vision serves as a compass, providing direction and purpose, and it inspires others to rally behind a common cause. In essence, "Ambition provides the motivation and drive to pursue goals, while Vision sets the course and defines the ultimate destination"</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={elevating} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  ELEVATING CHARACTER
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Elevating Character</strong> - It involves cultivating positive traits, values, and behaviours that contribute to personal growth, better relationships, and positive contributions to society.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some key aspects of elevating character are Integrity, Compassion, Respect, Responsibility, Humility, Gratitude, Generosity, and Forgiveness.</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Elevating character is a lifelong journey that involves self-awareness, self-reflection, and consistent effort to become a better version of oneself.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={growth} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} height={'65px'}  >
                <Text color={'white'} fontSize="15px" >
                  GROWTH MINDSET
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  With a <strong>Growth Mind-Set</strong>, individuals embrace challenges, persevere in the face of obstacles, and see failures as opportunities for learning and growth. People with a growth mind-set tend to approach new tasks with enthusiasm and a desire to learn.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Having a growth mind-set can lead to increased motivation, resilience, and the ability to overcome obstacles.</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Overall, cultivating a growth mind-set can empower individuals to achieve their goals, develop new skills, and thrive in a constantly changing world.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={nonCognitive} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} height={'65px'} >
                <Text color={'white'} fontSize="15px" >
                  NON - COGNITIVE TRAITS AND HABITS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Non-cognitive traits and habits</strong> play a crucial role in shaping an individual's personality, behavior, and overall success in various aspects of life.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some common examples of non-cognitive traits and habits are Emotional Intelligence, Time Management, Adaptability, Creativity, Grit, Optimism, Healthy Habits, Curiosity, and Empathy</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Developing and nurturing these non-cognitive traits can contribute to better relationships, improved mental health, and increased overall satisfaction and success in various endeavors.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={soft} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  SOFT SKILLS
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Soft Skills</strong> are often intangible and difficult to measure, yet they play a crucial role in how individuals navigate and thrive in various situations.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some common examples of soft skills are Communication, Emotional Intelligence, Teamwork, Adaptability, Leadership, Conflict Resolution, and Decision-Making</strong>
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  Soft Skills are crucial in building and maintaining healthy relationships, whether in personal life or professional networking. They play a significant role in enhancing communication, resolving conflicts, and fostering cooperation.
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={digital} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  DIGITAL LEARNING CONTENT
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Digital Learning Content</strong> - It encompasses a wide range of content types, including text-based materials, videos, interactive simulations, quizzes, games, and more. Digital learning content is designed to support and enhance the learning experience.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Key characteristics and benefits of digital learning content are Interactivity, Personalization, Multimedia, Scalability, Self-paced Learning, Blended Learning, Global Reach, and Analytics and Reporting</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={personality} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  PERSONALITY DEVELOPMENT
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Personality Development</strong> - It encompasses the various psychological, social, and environmental factors that contribute to an individual's overall character and behaviour.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some Key components of personality development are Self-awareness, Self-confidence, Emotional intelligence, Communication skills, Adaptability, Continuous learning and Social skills</strong>
                </Text>
              </Box>
            </Box>
            <Box>
              <Image {...programStyles.imageStyle} src={career} alt="Adaptive Technology" />
              <Flex {...programStyles.imageContainer} >
                <Text color={'white'} fontSize="15px" >
                  CAREER GUIDANCE
                </Text>
              </Flex>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Career Guidance</strong> is extremely helpful for individuals, especially those who are uncertain about their career path or facing challenges in their current career. Career guidance often involves assessments and exercises that help individuals gain a better understanding of their strengths, interests, values, and personality traits.
                </Text>
              </Box>
              <Box mt={5}>
                <Text fontSize={fFont}>
                  <strong>Some reasons why career guidance can be beneficial are Self-awareness, Exploring options, Decision-making support, Identifying opportunities, Overcoming challenges, Resume, and interview assistance.</strong>
                </Text>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Flex>

      <Text textAlign={"center"} className="text-header " fontSize={aFont} mb={5}>
        OUR PROGRAMS
      </Text>
      <Box className="pedagogy-section " >





        <Flex >
          <Flex mb={4}
            gap={4} margin={'5%'} marginTop={'2%'} >
            <Wrap justify={'center'} >


              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={OurTrain5} alt='Personal Development' w={{ base: '100%', md: '300px', lg: '300px' }} />
                </Center>
                <Flex className="imgBox" mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px' }} height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    PERSONAL DEVELOPMENT
                  </Text>
                </Flex>
                <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} textColor={'white'} onClick={() => handleExplore('/personaldev')}>EXPLORE</Button>
                  </Center>
                </Box>
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={OurTrain3} alt='Communicaiton' w={{ base: '100%', md: '300px', lg: '300px' }} />
                </Center>
                <Flex className="imgBox" mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px' }} height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    COMMUNICATION & INTER-PERSONAL SKILLS
                  </Text>
                </Flex>
                <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/communication')}>EXPLORE</Button>
                  </Center>
                </Box>
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={OurTrain4} alt='Adaptation to Technology' w={{ base: '100%', md: '300px', lg: '300px' }} />
                </Center>
                <Flex className="imgBox" mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px' }} height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    ADAPTATION TO TECHNOLOGY & 21ST CENTURY SKILLS
                  </Text>
                </Flex>
                <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/adaptation')}>EXPLORE</Button>
                  </Center>
                </Box>
              </Box>


              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={OurTrain1} alt='Well Being' w={{ base: '100%', md: '300px', lg: '300px' }} />
                </Center>
                <Flex className="imgBox" mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px' }} height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    WELL BEING & MENTAL HEALTH
                  </Text>
                </Flex>
                <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/wellbeing')}>EXPLORE</Button>
                  </Center>
                </Box>
              </Box>
              <Box marginBottom={'3%'}>
                <Center>
                  <Image src={OurTrain2} alt='Academic Career Readiness' w={{ base: '100%', md: '300px', lg: '300px' }} />
                </Center>
                <Flex className="imgBox" mb={18} mt={1} width={{ base: '100%', md: '300px', lg: '300px' }} height={'90px'} justifyContent={"center"} align="center" >
                  <Text color={'white'} fontSize="15px" >
                    ACADEMIC & CAREER READINESS
                  </Text>
                </Flex>
                <Box mt={5}>
                  <Center>
                    <Button size={'md'} cursor={'pointer'} color={'white'} onClick={() => handleExplore('/academic')}>EXPLORE</Button>
                  </Center>
                </Box>
              </Box>

            </Wrap>
          </Flex>
        </Flex>

      </Box>
    </>
  );
}