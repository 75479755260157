export const CONSTANTS = {
  EMAIL_REGEX: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
  PHONE_REGEX: /^\+?(?:[0-9]){6,14}[0-9]$/,
  CURRENT_DATE: new Date(),
  QUESTION_TYPE: ["SCQ", "MCQ"],
  DEPARTMENT: [
    {
      "grade-id": 17,
      "grade-name": "B.Com",
      "grade-value": "BCOM",
      active: 1,
    },
    {
      "grade-id": 18,
      "grade-name": "BA.English",
      "grade-value": "BA",
      active: 1,
    },
    {
      "grade-id": 19,
      "grade-name": "BSC.Physics",
      "grade-value": "BSC",
      active: 1,
    },
  ],
  GRADE_DROPDOWN: [
    {
      id: 1,
      "grade-name": "3 Hours",
      "grade-value": "3H",
    },
    {
      id: 2,
      "grade-name": "12 Hours",
      "grade-value": "12H",
    },
    {
      id: 3,
      "grade-name": "24 Hours",
      "grade-value": "24H",
    },
  ],
  GRADE: [
    {
      value: "G5",
      label: "Grade 5",
    },
    {
      value: "G6",
      label: "Grade 6",
    },
    {
      value: "G7",
      label: "Grade 7",
    },
    {
      value: "G8",
      label: "Grade 8",
    },
    {
      value: "G9",
      label: "Grade 9",
    },
    {
      value: "G10",
      label: "Grade 10",
    },
    {
      value: "G11",
      label: "Grade 11",
    },
    {
      value: "G12",
      label: "Grade 12",
    },
  ],
  HOURS: [
    {
      value: "H3",
      label: "3 Hours",
    },
    {
      value: "H12",
      label: "12 Hours",
    },
    {
      value: "H24",
      label: "24 Hours",
    },
  ],
  LEVEL: [
    {
      value: "L1",
      label: "Level 1",
    },
    {
      value: "L2",
      label: "Level 2",
    },
  ],
  AUTH_KEY: "LNI_AUTH_KEY",
  AUTH_DATA: "LNI_AUTH_DATA",
  DEBUG_TOKEN: "542cacc3-4526-429f-bfd5-0bca2bc0259c",
  AUTH_USER_TYPE: "LNI_USER_TYPE",
  AUTH_USER_DATA: "LNI_USER_DATA",
  DURATION: 3000,
  INSTITUTE_TYPE: {
    COLLEGE: "COLLEGE",
    SCHOOL: "SCHOOL",
    OTHERS: "OTHERS",
  },
  USER_TYPE: {
    ADMINISTRATOR: "ADMIN",
    TRAINER: "TRAINER",
    COORDINATOR: "COORD",
    STUDENT: "STUDENT",
    // SUPER_ADMIN: 'SUPER_ADMIN',
    // SCHOOL_ADMIN: 'SCHOOL_ADMIN'
  },
  GENDER: {
    MALE: "MALE",
    FEMALE: "FEMALE",
    OTHERS: "OTHERS",
  },
  USER_TYPE_LABEL: {
    ADMIN: "Admin",
    TRAINER: "Trainer",
    COORD: "Co-Ordinator",
    STUDENT: "Student",
    // SUPER_ADMIN: 'SUPER_ADMIN',
    SCHOOL_ADMIN: "School Admins",
  },
  SECTION: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
  SYLLABUS_TYPE: [
    {
      title: "Personal Development",
      url: "/personaldev",
    },
    {
      title: "Communication & Interpersonal Skills",
      url: "/communication",
    },
    {
      title: "Adaptation to technology and 21st-century skills",
      url: "/adaptation",
    },
    {
      title: "Well - Being & Mental Health",
      url: "/wellbeing",
    },
    {
      title: "Academic & Career Readiness",
      url: "/academic",
    },
  ],
  YEAR: [
    {
      name: "FIRST YEAR",
      value: 1,
    },
    {
      name: "SECOND YEAR",
      value: 2,
    },
    {
      name: "THIRD YEAR",
      value: 3,
    },
    {
      name: "FOURTH YEAR",
      value: 4,
    },
  ],
};