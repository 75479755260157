// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 300 auto;
    margin-bottom: 200px;
    margin-top: 100px;
    /* margin-top: 200px; */
    /* margin: 0 auto; */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.otp-button{
    margin-top: 8px;
    margin-bottom: 55%;
    padding: 6px;
     font-size: 14px;
     font-weight: 600
}
form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthManagement/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,oBAAoB;IACpB,iBAAiB;IACjB,uBAAuB;IACvB,oBAAoB;IACpB,4BAA4B;IAC5B,kBAAkB;IAClB,sBAAsB;IACtB,8CAA8C;AAClD;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;KACX,eAAe;KACf;AACL;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,UAAU;IACV,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".login-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    width: 300 auto;\n    margin-bottom: 200px;\n    margin-top: 100px;\n    /* margin-top: 200px; */\n    /* margin: 0 auto; */\n    /* border: 1px solid #ccc; */\n    border-radius: 5px;\n    background-color: #fff;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n}\n\n.otp-button{\n    margin-top: 8px;\n    margin-bottom: 55%;\n    padding: 6px;\n     font-size: 14px;\n     font-weight: 600\n}\nform-group {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n\nlabel {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\ninput {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    font-size: 16px;\n    width: 100%;\n}\n\n.error {\n    color: red;\n    font-size: 14px;\n    margin-top: 5px;\n}\n\nbutton {\n    background-color: #007BFF;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    font-size: 20px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
