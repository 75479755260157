import "./Login.css"

import React, { useState, useContext } from 'react';
import { axiosSource, postCall } from "../../services/httpService";
import { handleToast, toastByStatus, toastErrorOnly } from "../../services/utils";

import { CONSTANTS } from "../../services/constants";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../App";
import { useToast, Box } from "@chakra-ui/react";

function Otppage() {
  const [password, setPassword] = useState('');
  const [context, setContext] = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();



  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Password:', password);
    const email = localStorage.getItem('email')
    let data = JSON.stringify({
      "user_id": email,
      "otp": password
    });

    await postCall('/user/validate_otp', data, {})
      .then((response) => {
        console.log(response.data);
        const data = response.data
        console.log('🚀 ~ file: Otppage.js:31 ~ .then ~ data:', data)

        if (data.status == 200) {
          localStorage.setItem(CONSTANTS.AUTH_KEY, data.success)

          localStorage.setItem(CONSTANTS.AUTH_USER_TYPE, data['lni-user-type'])
          localStorage.setItem(CONSTANTS.AUTH_USER_DATA, JSON.stringify({ "lni-user-id": data['lni-user-id'] }))

          setContext((prev) => {
            return {
              ...prev,
              auth_key: localStorage.getItem(CONSTANTS.AUTH_KEY),
              user_type: localStorage.getItem(CONSTANTS.AUTH_USER_TYPE),
              user_data: {
                ...prev.user_data,
                "lni-user-id": data['lni-user-id']
              }
            }
          })
          console.log('🚀 ~ file: Otppage.js:32 ~ .then ~ CONSTANTS:', localStorage.getItem(CONSTANTS.AUTH_KEY))
          const userType = localStorage.getItem(CONSTANTS.AUTH_USER_TYPE);
          if (userType === "STUDENT") {
            navigate('/student')
          } else if (userType === "TRAINER") {
            navigate('/trainer')
          } else if (userType == 'COORD') {
            navigate('/coord')
          } else {
            navigate('/dashboard')
          }
        } else if (!password) {
          throw new Error("Please enter OTP!")
        }
        else {
          throw new Error("Wrong OTP!")
        }
      })
      .catch((error) => {
        console.log(error);
        toastErrorOnly(toast, error)
      });


  };

  return (
    <div className="login-container">
      <Box width={{ base: '70%', "md": '35%', "lg": '23%' }}>
        <form onSubmit={handleSubmit} >
          <label>Enter OTP:</label>
          <input
            type="password"
            placeholder="Enter your OTP"
            value={password}
            onChange={handlePasswordChange}
          />
          <button className="otp-button" type="submit">{"   "}SUBMIT{"   "}</button>
        </form>
      </Box>
    </div>
  );
}

export default Otppage;
