import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  Text,
  useTheme
} from '@chakra-ui/react'

import pedagogy1 from '../../images/pedagogy1.jpg';
import pedagogy2 from '../../images/pedagogy2.jpg';
import pedagogy3 from '../../images/pedagogy3.jpg';
import pedagogy4 from "../../images/pedagogy4.jpg";
import pedagogy5 from '../../images/pedagogy5.jpg';
import pedagogy6 from '../../images/pedagogy6.jpg';
import pedagogy from '../../images/pedagogy.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Pedagogy() {
  const theme = useTheme();
  const { pedagogyStyles, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>OUR PEDAGOGY</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="pedagogy" /> */}
        <img src={pedagogy} alt="aboutus" />
      </Box>
      <Box>
        <Flex mb={5} alignItems={'center'}>
          <Container>
            <Box m={5}>
              <Text fontSize={fFont}>
                At A3 Minds, we take pride in our unique pedagogical approach that sets us apart from other competitors. We understand the importance of providing educational institutions with comprehensive and effective training programs for their individuals. Here's why educational institutions should choose A3 Minds as their trusted partner.<br /><br />
                Our Pedagogical Approach has broadly divided into Six Areas:
              </Text>
            </Box>
          </Container>
        </Flex>


        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy1} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.flexText}>
            HOLISTIC DEVELOPMENT
          </Text>
        </Flex>



        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} >
            <Box>
              <Text color={'white'} align={'center'} mt={{ lg: 16 }} fontSize={{ base: "md", md: "xl", lg: "4xl" }}  >HOLISTIC DEVELOPMENT</Text>
              <Text {...pedagogyStyles.BoxInnerText}>
                We believe in nurturing the holistic development of individuals beyond traditional academic subjects. Our programs focus on essential non-academic skills, such as soft skills, personal growth, and leadership abilities. By partnering with A3 Minds, educational institutions can ensure that their students and individuals receive a well-rounded education that prepares them for success in all aspects of life.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy2} mt={20} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.flexText}>
            EXPERTISE AND EXPERIENCE
          </Text>
        </Flex>

        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} paddingBottom={4} paddingTop={4} >
            <Box>
              <Text {...pedagogyStyles.BoxText}  >EXPERTISE AND EXPERIENCE</Text>
              <Text {...pedagogyStyles.BoxInnerText}>
                A3 Minds is backed by a team of domain experts who possess extensive experience in both academia and industry. Our instructors are renowned professionals who bring a wealth of knowledge and practical insights to the training sessions. By choosing A3 Minds, educational institutions gain access to the best minds in the field, ensuring that their students and individuals receive top-quality training from industry-leading experts.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy3} mt={20} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.imageText}>
            RESEARCH - DRIVEN APPROACH
          </Text>
        </Flex>
        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} paddingBottom={4} paddingTop={4}>
            <Box>
              <Text {...pedagogyStyles.BoxText}  >RESEARCH - DRIVEN APPROACH</Text>
              <Text {...pedagogyStyles.BoxInnerText}>
                Our training modules are designed based on rigorous research and industry insights. We invest significant time and effort in studying the latest trends, best practices, and advancements in the relevant fields. By partnering with A3 Minds, educational institutions can be confident that their individuals will receive training that is up-to-date, relevant, and aligned with the ever-changing demans of the modern world.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy4} mt={20} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.imageText}>
            CUSTOMIZED PROGRAMS
          </Text>
        </Flex>
        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} paddingBottom={4} paddingTop={4}>
            <Box>
              <Text {...pedagogyStyles.BoxText}  >CUSTOMIZED PROGRAMS</Text>
              <Text {...pedagogyStyles.BoxInnerText} >
                We understand that every educational institution has its own unique requirements and goals. That's why we offer customized programs tailored to the specific needs of each institution. Whether it's designing programs for different grade levels or aligning with specific curricula, we work closely with educational institutions to ensure that our training programs seamlessly integrate into their existing framework.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy5} mt={20} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.flexText}>
            PROVEN EFFECTIVENESS
          </Text>
        </Flex>
        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} paddingBottom={4} paddingTop={4}>
            <Box>
              <Text {...pedagogyStyles.BoxText}  >PROVEN EFFECTIVENESS</Text>
              <Text {...pedagogyStyles.BoxInnerText} >
                The effectiveness of our training programs has been scientifically tested and proven. We have a track record of successfully equipping individuals with the necessary skills to excel academically, develop strong personal attributes, and become effective leaders. Educational institutions can trust in our approach, knowing that their students and individuals will benefit from our evidence-based training methods.
              </Text>
            </Box>
          </Flex>
        </Box>

        <Center>
          <Image w={pedagogyStyles.imageW} src={pedagogy6} mt={20} />
        </Center>
        <Flex {...pedagogyStyles.imageContainer} >
          <Text {...pedagogyStyles.flexText}>
            COMMITTMENT TO EXCELLENCE
          </Text>
        </Flex>

        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} paddingBottom={4} paddingTop={4}>
            <Box>
              <Text {...pedagogyStyles.BoxText}  >COMMITTMENT TO EXCELLENCE</Text>
              <Text {...pedagogyStyles.BoxInnerText} >
                A3 Minds is dedicated to delivering excellence in every aspect of our services. We strive for continuous improvement and innovation in our curriculum, instructional methods, and program delivery. By choosing A3 Minds, educational institutions can rest assured that they are partnering with a company that is committed to providing the highest quality training programs for their students and individuals.<br /><br />
              </Text>
            </Box>
          </Flex>
        </Box>





        <Box m={5}>
          <center>
            <Text mb={5} width={'85%'} fontSize={{ base: "md", md: "xl", lg: "4xl" }}>
              <b>Partner with A3 Minds and experience the difference in student development. Our unique pedagogy, combined with our expertise, research-driven approach, and commitment to excellence, makes us the ideal choice for educational institutions seeking comprehensive and effective non-academic training programs.</b>
            </Text>
          </center>
        </Box>
        {/*                 
            </Container>
            
        </Flex> */}
      </Box>
    </>
  )
}