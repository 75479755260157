import {
    Box,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    VStack,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    Link,
    Flex,
    Image
} from '@chakra-ui/react'
import { coordMenu, guestMenuItems, menuItems, studentMenu, trainerMenu } from '../menuItems'
import { DesktopSubNav } from './Navbar';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import { useContext } from 'react';
import { AuthContext } from '../App';
import { CONSTANTS } from '../services/constants';
import { postCall } from '../services/httpService';
import { LOGOUT } from '../services/apiRoutes';

const SidebarContent = ({ onClick }) => {
    const linkColor = useColorModeValue('gray.800', 'white');
    const linkHoverColor = useColorModeValue('#0563b4', '#0563b4');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
    const navigate = useNavigate();
    const [context, setContext] = useContext(AuthContext);

    const handleLogout = () => {
        setContext({ auth_key: null, user_type: null });
        localStorage.clear();
        postCall(LOGOUT).then(res => res);
    }

    const getRoleBasedMenu = () => {
        if ((context?.auth_key || localStorage.getItem(CONSTANTS.AUTH_KEY))) {
            switch (context?.user_type) {
                case CONSTANTS.USER_TYPE.ADMINISTRATOR:
                    return [...menuItems];
                case CONSTANTS.USER_TYPE.STUDENT:
                    return [...studentMenu];
                case CONSTANTS.USER_TYPE.COORDINATOR:
                    return [...coordMenu];
                case CONSTANTS.USER_TYPE.TRAINER:
                    return [...trainerMenu];
                default:
                    return [...menuItems];
            }
        } else {
            return [...guestMenuItems];
        }
    }

    return (
        <VStack align={'flex-start'}>
            {getRoleBasedMenu().map((navItem, idx) => (
                <Box key={idx}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.url ?? '#'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    /* logout redirect */
                                    if (navItem.title === "Logout") {
                                        handleLogout()
                                        setTimeout(() => {
                                            navigate(navItem.url || "");
                                        }, 1000);
                                        onClick();
                                        return;
                                    }
                                    navigate(navItem.url || '');
                                    if (navItem.submenu && navItem.submenu.length > 0) return;
                                    onClick();
                                }}
                                fontSize={'sm'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}
                            >
                                {navItem.title}
                            </Link>
                        </PopoverTrigger>

                        {navItem.submenu && (
                            <PopoverContent border={0} boxShadow={'xl'} bg={popoverContentBgColor} p={4} rounded={'xl'} minW={'sm'}>
                                <VStack>
                                    {navItem.submenu.map((child, idx) => (
                                        <DesktopSubNav key={idx} {...child} onClick={() => onClick()} />
                                    ))}
                                </VStack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </VStack>
    )
}

const Sidebar = ({ isOpen, variant, onClose }) => {
    return variant === 'sidebar' ? (
        <Box
            position="fixed"
            left={0}
            p={5}
            w="200px"
            top={0}
            h="100%"
            bg="#dfdfdf"
        >
            <SidebarContent onClick={onClose} />
        </Box>
    ) : (
        <Flex className='flex-class' alignItems={'center'}>
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>

                        <DrawerHeader height={'16'}>
                            <Flex
                                marginLeft={'10'}
                                width={{ base: '60%', md: '', lg: '' }}
                                height='auto'
                                justifyContent={"center"}
                            >
                                <Image
                                    src={logo}
                                    alt="Description of the image"
                                />
                                <DrawerCloseButton top={4} />
                            </Flex>
                        </DrawerHeader>
                        <DrawerBody>
                            <SidebarContent onClick={onClose} />
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </Flex>
    )
}

export default Sidebar
