import { Box, Container, Flex, Image, Text, Wrap, WrapItem, Center, Link } from '@chakra-ui/react';
import antony from '../images/antony.png';
import { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Footer = () => {
  const [flag, setFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    checkFooter()
  }, [location.pathname])

  const checkFooter = () => {
    let pathname = location.pathname;
    switch (pathname) {
      case "/questionaire":
        setFlag(true)
        break;
      case "/users":
        setFlag(true)
        break;
      case "/reports":
        setFlag(true)
        break;

      case "/institute":
        setFlag(true)
        break;

      case "/syllabus":
        setFlag(true)
        break;

      case "/assessment":
        setFlag(true)
        break;
      default:
        setFlag(false)

    }
  }
  if (flag) {
    return
  }

  return (
    <Box className="footer" fontFamily={"Segoe UI"} width={"100%"}>
      <br />
      <hr />
      <center>
        <img
          style={{
            width: "350px",
            height: "69px",
            marginTop: "10px",
          }}
          src={antony}
          alt="logo"
        />
      </center>
      <Container as="footer" role="contentinfo" bottom={0}>
        <Box padding="10px 0px 0px 0px">
          <Flex align={"center"} justify={"center"} gap={2}>
            <Link
              fontSize="sm"
              href="../privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            |
            <Link
              fontSize="sm"
              href="../terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </Link>
            |
            <Link
              fontSize="sm"
              href="../cancellation"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cancellation & Refund Policy
            </Link>
            |
            <Link
              fontSize="sm"
              href="../shipping"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shipping & Delivery
            </Link>
          </Flex>
        </Box>
        <Text fontSize="14px" color="fg.subtle" textAlign={"center"}>
          <b>A3 Minds</b>, No.38/1, AVM Avenue, 1St Street, Virugambakkam,
          Chennai – 600 092.
          <br />
          <Flex
            marginBottom={"5px"}
            justifyContent={"center"}
            flexWrap={"wrap"}
          >
            <Box width={"180px"}>
              <Text>
                <b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;
              </Text>
            </Box>
            <Box width={"180px"}>
              <Text>
                <b>Email:</b>&nbsp;info@a3minds.com
              </Text>
            </Box>
          </Flex>
        </Text>
        <Text fontSize="12px" color="fg.subtle" textAlign={"center"}>
          &copy; {new Date().getFullYear()} All rights reserved.
        </Text>
      </Container>
    </Box>
  );
}

export default Footer;
