import { Wrap, WrapItem, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, useDisclosure, useToast, Text, Box, Container } from "@chakra-ui/react";
import { INSTITUTE_API, STUDENT_API } from "../../services/apiRoutes";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import { toastByStatus, toastErrorOnly, toastWithButton } from "../../services/utils";

import { AuthContext } from "../../App";
import InstituteBulkUpload from "./InstituteBulkUpload";
import InstituteFilter from "./InstituteFilter";
import InstituteList from "./InstituteList";
import { useNavigate } from "react-router-dom";
import antony from '../../images/antony.png';

export default function Institute() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [excludedList, setExcludedList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [context] = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchObj, setSearchObj] = useState({
    "institution-id": undefined,
    searchValue: "",
    grade: "",
  });
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: [],
  });

  const handleExcludedList = (data) => {
    if (data.length) {
      setExcludedList(data)
    }
  }

  console.log('🚀 ~ file: Institute.js:26 ~ Institute ~ instituteList:', instituteList)
  const [studentList, SetStudentList] = useState({
    isLoading: false,
    data: [],
  });
  console.log('🚀 ~ file: Institute.js:31 ~ Institute ~ studentList:', studentList)
  const [infoMsg, setInfoMsg] = useState('Please select Institute');

  const getInstituteList = useCallback(async (requestBody = {}, source, URL, setState) => {
    let body = {
      ...requestBody
    };
    if (requestBody['institution-id']) {
      body = { ...body, "institution-id": Number(requestBody['institution-id']) }
    }
    await postCall(URL, body, {}, source)
      .then((res) => {
        if (res.status === 200) {
          setState((prev) => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
          if (res?.data && res?.data.length === 0) {
            setInfoMsg('No record to display');
          }
          if (!Number(searchObj['institution-id'])) {
            setInfoMsg('Please select Institute');
          }
          toastByStatus(
            toast,
            res?.data?.success,
            res?.data?.error,
            res?.data?.success,
            res?.data?.error
          );
        }
      }).catch((error) => toastErrorOnly(error)).finally(() => { SetStudentList((prev) => ({ ...prev, isLoading: false })) });
  }, [context?.user_data, searchObj, toast]);



  const constructorRequest = useCallback((source_two) => {
    if (!Number(searchObj["institution-id"])) return;
    SetStudentList((prev) => ({ ...prev, isLoading: true }));
    getInstituteList(
      {
        "institution-id": searchObj["institution-id"],
        "search-global": searchObj.searchValue,
        "search-grade": searchObj.grade,
      },
      source_two,
      STUDENT_API.STUDENT,
      SetStudentList
    );
  }, [getInstituteList, searchObj]);

  useEffect(() => {
    SetStudentList((prev) => ({ ...prev, isLoading: true }));
    const source_one = axiosSource();
    getInstituteList({}, source_one, INSTITUTE_API.INSTITUTE_ALL, SetInstituteList);
    return () => {
      source_one.cancel();
    };
  }, []);

  useEffect(() => {
    if (excludedList) {
      let arr=[];
      const finalList = excludedList.map((item) => {
        if (item.error) {
          arr.push([item.fname, item.lname, item.email])
         
        }
      });
      arr.unshift(["first name", "last name", "email"])
      setCsvData(arr)
    }
  }, [excludedList])

  useEffect(() => {
    if (csvData.length > 1) {
      toastWithButton(toast, <Button margin={6} textColor={"white"} onClick={downloadCSV}>Click to Download already Existing Emails</Button>)
    }
  }, [csvData])
  useEffect(() => {
    if (searchObj["institution-id"] === undefined || searchObj["institution-id"] === null) return;
    const source_two = axiosSource();
    SetStudentList((prev) => ({ ...prev, isLoading: true }));
    getInstituteList(
      {
        "institution-id": searchObj["institution-id"],
        "search-global": searchObj.searchValue,
        "search-grade": searchObj.grade,
      },
      source_two,
      STUDENT_API.STUDENT,
      SetStudentList
    );
    return () => {
      source_two.cancel();
    };
  }, [searchObj['institution-id']]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchObj(prev => ({ ...prev, [name]: value }));
  }, [setSearchObj]);

  const handleOnSearch = useCallback(() => {
    constructorRequest(null);
  }, [constructorRequest]);

  const handleReset = useCallback(() => {
    setSearchObj((prev) => ({ ...prev, searchValue: "", grade: "" }));
    getInstituteList(
      {
        "institution-id": searchObj["institution-id"],
        "search-global": '',
        "search-grade": '',
      },
      null,
      STUDENT_API.STUDENT,
      SetStudentList
    );
  }, [getInstituteList, searchObj]);

  const goToCreate = useCallback(() => {
    navigate("/create-institute");
  }, [navigate]);

  const deleteInstitute = useCallback(
    async (institutionId, isActive) => {
      const requestBody = { "lni-user-id": institutionId };
      const url = isActive
        ? STUDENT_API.STUDENT_DELETE
        : STUDENT_API.STUDENT_UN_DELETE;
      await postCall(url, requestBody)
        .then((res) => {
          if (res.status === 200) {
            toastByStatus(
              toast,
              res?.data?.success,
              res?.data?.error,
              res?.data?.success,
              res?.data?.error
            )
            SetStudentList((prev) => ({ ...prev, isLoading: true }));
            getInstituteList(
              { "institution-id": searchObj["institution-id"] },
              null,
              STUDENT_API.STUDENT,
              SetStudentList
            );
          }
        }).catch((error) => toastErrorOnly(error)).finally(() =>
          SetInstituteList((prev) => ({ ...prev, isLoading: true }))
        );
    },
    [getInstituteList, searchObj["institution-id"], toast]
  );

  const handleStatusChange = useCallback(
    (e) => {
      SetStudentList((prev) => ({ ...prev, isLoading: true }));
      deleteInstitute(e["lni-user-id"], e["active"]);
    },
    [deleteInstitute]
  );
  const convertToCSV = () => {
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    return 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
  };

  const handleAddStudents = useCallback(() => {
    SetStudentList((prev) => ({ ...prev, isLoading: true }));
    getInstituteList({ "institution-id": searchObj["institution-id"] }, null, STUDENT_API.STUDENT, SetStudentList);
  }, [getInstituteList, searchObj])
  // const handlePageClick = useCallback((data) => {
  //     console.log('onPageChange', data);
  //     let offset = Math.ceil(pagination.currentPage * pagination.perPage);
  //     setPagination(prev => ({...prev, currentPage: prev.currentPage +  data.selected, offset: offset}));
  // }, [pagination.currentPage, pagination.perPage]);

  const downloadCSV = () => {

    const csvData = convertToCSV();
    const link = document.createElement('a');
    link.href = csvData;
    link.target = '_blank';
    link.download = 'data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setCsvData([]);
    setExcludedList([])
  };



  return (
    <>
      {studentList.isLoading && (
        <div className="loader flex-center">Loading...</div>
      )}
      <Flex mt={5} flexDirection={'column'} h="87vh" alignItems="center">
        <Heading fontSize={{ base: 'xl', md: "2xl", lg: '3xl' }}>Institute Management</Heading>
        {
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <InstituteBulkUpload
                  searchObj={searchObj}
                  instituteData={instituteList}
                  handleChange={handleChange}
                  onAddStudents={handleAddStudents}
                  onCreate={goToCreate}
                  handleOnExcluded={handleExcludedList}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        }
        <InstituteFilter
          searchObj={searchObj}
          instituteData={instituteList}
          handleChange={handleChange}
          onGo={handleOnSearch}
          onReset={handleReset}
          onUpload={onOpen}
        />
        {studentList.data.length ? <InstituteList
          studentList={studentList}
          changeStatus={handleStatusChange}
          infoMsg={infoMsg}
        /> : <p style={{ "padding": "2%", "font-weight": "bold" }}>{infoMsg}</p>}
      </Flex>

      <Box className="footer" marginTop={{ base: "50%", md: '20%', lg: '20%' }} fontFamily={'Segoe UI'} >
        <br />
        <hr />
        <center>
          <img style={{
            width: '350px',
            height: '69px',
            marginTop: '10px'
          }} src={antony} alt="logo" />
        </center>
        <Container as="footer" role="contentinfo" bottom={0}>
          <Text fontSize="14px" color="fg.subtle" textAlign={'center'}>
            <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam, Chennai – 600 092.
            <br />
            <Flex marginBottom={'5px'} justifyContent={'center'} flexWrap={'wrap'}>
              <Box width={'170px'}>
                <Text><b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;</Text>
              </Box>
              <Box width={'180px'}>
                <Text><b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;</Text>
              </Box>
              <Box width={'180px'}>
                <Text><b>Email:</b>&nbsp;A3minds@gmail.com</Text>
              </Box>
            </Flex>
          </Text>
          <Text fontSize="12px" color="fg.subtle" textAlign={'center'}>
            &copy; {new Date().getFullYear()} All rights reserved.
          </Text>
        </Container>
      </Box>
    </>
  );
}
