import { Button, Flex, FormControl, FormLabel, Heading, Select, useToast, Container, Text, Box, Link } from "@chakra-ui/react";
// import CreateEditSyllabus from './CreateSyllabus';
import { INSTITUTE_API, SYLLABUS_API } from "../../services/apiRoutes";
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import { toastByStatus, toastErrorOnly } from "../../services/utils";
import antony from '../../images/antony.png';

import { AuthContext } from "../../App";
import { CONSTANTS } from "../../services/constants";
import SyllabusList from "./SyllabusList";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

function Syllabus() {
  console.log('?? ~ file: Syllabus.js:13 ~ Syllabus ~ Syllabus:')
  const toast = useToast();
  const [context] = useContext(AuthContext);
  const navigate = useNavigate();
  const [syllabusList, SetSyllabusList] = useState({
    isLoading: false,
    data: []
  });
  // filter
  const [searchObj, setSearchObj] = useState({
    "institution-id": 0
  });
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: []
  });
  const [infoMsg, setInfoMsg] = useState('Please select Institute');

  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchObj(prev => ({ ...prev, [name]: value }));
  }, [setSearchObj]);

  const getSyllabusList = useCallback(async (search = {}, source) => {
    let requestBody = {
      "institution-id": searchObj['institution-id'] ? Number(searchObj['institution-id']) : 0,
      // "session_type_id": 0
    };
    if (!isAdmin) {
      requestBody = { ...requestBody, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall(SYLLABUS_API.SYLLABUS_LIST, requestBody, {}, source).then(res => {
      if (res.status === 200) {
        if (!res?.data || (res?.data && res?.data.length === 0)) {
          setInfoMsg('No record to display')
        }
        toastByStatus(
          toast,
          res?.data?.success,
          res?.data?.error,
          res?.data?.success,
          res?.data?.error
        )
        SetSyllabusList(prev => ({ ...prev, data: res?.data }));
      }
    }).catch((error) => toastErrorOnly(error)).finally(() => SetSyllabusList(prev => ({ ...prev, isLoading: false })));
  }, [searchObj, toast]);

  useEffect(() => {
    if (!Number(searchObj['institution-id']) && !isAdmin) {
      SetSyllabusList(prev => ({ data: [], isLoading: false }));
      setInfoMsg('Please select Institute')
      return;
    };
    SetSyllabusList(prev => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getSyllabusList({}, source);
    return () => {
      source.cancel()
    }
  }, [getSyllabusList, searchObj['institution-id'], isAdmin]);

  async function getInstituteList(source) {
    let body = {};
    if (!isAdmin) {
      body = { ...body, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true).then(res => {
      if (res.status === 200) {
        toastByStatus(
          toast,
          res?.data?.success,
          res?.data?.error,
          res?.data?.success,
          res?.data?.error
        )
        SetInstituteList(prev => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
      }
    }).catch((error) => toastErrorOnly(error)).finally(() => SetInstituteList(prev => ({ ...prev, isLoading: false })));
  };

  useEffect(() => {
    const source = axiosSource();
    getInstituteList(source);
    return () => {
      source.cancel();
    };
  }, []);

  const deleteUser = useCallback(async (userId, isActive) => {
    const requestBody = { "syllabus-id": userId }
    const url = isActive ? SYLLABUS_API.SYLLABUS_DELETE : SYLLABUS_API.SYLLABUS_UNDELETE;
    await postCall(url, requestBody).then(res => {
      if (res.status === 200) {
        toastByStatus(
          toast,
          res?.data?.success,
          res?.data?.error,
          res?.data?.success,
          res?.data?.error
        )
        SetSyllabusList(prev => ({ ...prev, isLoading: true }));
        getSyllabusList();
      }
    }).catch((error) => toastErrorOnly(error)).finally(() => SetSyllabusList(prev => ({ ...prev, isLoading: true })));
  }, [getSyllabusList, SetSyllabusList, toast]);

  const handleStatusChange = useCallback((e) => {
    SetSyllabusList(prev => ({ ...prev, isLoading: true }));
    deleteUser(e['syllabus-id'], e['active']);
  }, [deleteUser]);

  const goToCreate = useCallback(() => {
    navigate('/create-syllabus');
  }, [navigate]);

  const handleDownload = useCallback((link) => {
    window.open(link, '_blank');
  }, [])

  return (
    <>
      <Flex
        mt={5}
        align={{ base: "center", md: "center", lg: "end" }}
        flexDirection={"column"}
        h="87vh"
        alignItems="center"
      >
        <Heading fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}>
          Syllabus Management
        </Heading>
        <Flex mt={2} align={"end"} gap={5}>
          <FormControl width={{ lg: "97%" }}>
            <FormLabel>Institute</FormLabel>
            <Select
              name="institution-id"
              id="institution-id"
              value={searchObj["institution-id"]}
              onChange={handleChange}
            >
              <option key="select" value={0}>
                -Select-
              </option>
              {instituteList?.data?.map((option, index) => (
                <option
                  key={
                    option["institution-id"] +
                    option["institution-name"] +
                    index
                  }
                  value={option["institution-id"]}
                >
                  {option["institution-name"]}
                </option>
              ))}
            </Select>
          </FormControl>
          {isAdmin && (
            <Button
              size={"md"}
              colorScheme="blue"
              type="button"
              onClick={() => {
                navigate("/create-syllabus");
              }}
              pr={8}
              pl={8}
            >
              Create Syllabus
            </Button>
          )}
        </Flex>
        {syllabusList.data && syllabusList.data.length > 0 ? (
          <SyllabusList
            changeStatus={handleStatusChange}
            syllabusList={syllabusList}
            isAdmin={isAdmin}
            infoMsg={infoMsg}
            onDownload={handleDownload}
          />
        ) : (
          <p style={{ padding: "2%", "font-weight": "bold" }}>{infoMsg}</p>
        )}
      </Flex>
      <Box className="footer" fontFamily={"Segoe UI"} width={"100%"}>
        <br />
        <hr />
        <center>
          <img
            style={{
              width: "350px",
              height: "69px",
              marginTop: "10px",
            }}
            src={antony}
            alt="logo"
          />
        </center>
        <Container as="footer" role="contentinfo" bottom={0}>
          <Box padding="10px 0px 0px 0px">
            <Flex align={"center"} justify={"center"} gap={2}>
              <Link
                fontSize="sm"
                href="../privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              |
              <Link
                fontSize="sm"
                href="../terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </Link>
            </Flex>
          </Box>
          <Text fontSize="14px" color="fg.subtle" textAlign={"center"}>
            <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam,
            Chennai – 600 092.
            <br />
            <Flex
              marginBottom={"5px"}
              justifyContent={"center"}
              flexWrap={"wrap"}
            >
              <Box width={"170px"}>
                <Text>
                  <b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;
                </Text>
              </Box>
              <Box width={"180px"}>
                <Text>
                  <b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;
                </Text>
              </Box>
              <Box width={"180px"}>
                <Text>
                  <b>Email:</b>&nbsp;A3minds@gmail.com
                </Text>
              </Box>
            </Flex>
          </Text>
          <Text fontSize="12px" color="fg.subtle" textAlign={"center"}>
            &copy; {new Date().getFullYear()} All rights reserved.
          </Text>
        </Container>
      </Box>
    </>
  );
}

export default Syllabus;
