import "./App.css";
import Header, { Nav } from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./AppRoutes";
// 1. import `ChakraProvider` component
import { ChakraProvider, Container, extendTheme } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";
import { CONSTANTS } from "./services/constants";
import "./Site.css";
import ScrollToTop from "./components/ScrollToTop";
import { chakraStyles } from "./uiStyles";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({
  auth_key: localStorage.getItem(CONSTANTS.AUTH_KEY),
  // auth_key: "authKey",
  user_type: localStorage.getItem(CONSTANTS.AUTH_USER_TYPE),
  user_data: localStorage.getItem(CONSTANTS.AUTH_USER_DATA),
});

function App() {
  const token = localStorage.getItem(CONSTANTS.AUTH_KEY);
  const userType = localStorage.getItem(CONSTANTS.AUTH_USER_TYPE);
  const userData = localStorage.getItem(CONSTANTS.AUTH_USER_DATA);
  const [data, setData] = useState({
    auth_key: token || null,
    user_type: userType || null,
    user_data: userData ? JSON.parse(userData) : null,
  });
  const theme = extendTheme({
    ...chakraStyles,
    lg: "1024px",
    xl: "1440px",
    "2xl": "1600px",
    "3xl": "2560px",
    // "3xl": "2560px",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (userType === "STUDENT") {
      navigate("/student");
    } else if (userType === "TRAINER") {
      navigate("/trainer");
    } else if (userType == "COORD") {
      navigate("/coord");
    }
    // else {
    //   navigate('/dashboard')
    // }
  }, []);

  return (
    // <Router>
    <AuthContext.Provider value={[data, setData]}>
      <ChakraProvider theme={theme}>
        <>
          <ScrollToTop />
          <Header />
          <AppRoutes />
          {/* <Container position={"relative"}> */}
          <Footer />
          {/* </Container> */}
        </>
      </ChakraProvider>
    </AuthContext.Provider>
    // </Router>
  );
}

export default App;
