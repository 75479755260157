// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container { 
    display: grid;
    flex-direction:row;
    width: 100% !important;
    margin-top: 10px;
}

.containericon { 
    display:inline-block;
    vertical-align:bottom;
    margin-left: 15px;
    margin-top: 10px;
    float: right;
}

.containerdiv{
    margin-left: 40px;
    font-weight: bold;
}

table, td, th {
    border: 1px solid;
    text-align: center;    
  }

  .report-header{
    padding-inline: 0.5rem !important;
    text-align: center !important;
  }
  
  table {
    width: 95%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .filter-go {
    background: #0563b4;
    padding: 5px 10px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/ReportManagement/Reports.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,iCAAiC;IACjC,6BAA6B;EAC/B;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".container { \n    display: grid;\n    flex-direction:row;\n    width: 100% !important;\n    margin-top: 10px;\n}\n\n.containericon { \n    display:inline-block;\n    vertical-align:bottom;\n    margin-left: 15px;\n    margin-top: 10px;\n    float: right;\n}\n\n.containerdiv{\n    margin-left: 40px;\n    font-weight: bold;\n}\n\ntable, td, th {\n    border: 1px solid;\n    text-align: center;    \n  }\n\n  .report-header{\n    padding-inline: 0.5rem !important;\n    text-align: center !important;\n  }\n  \n  table {\n    width: 95%;\n    border-collapse: collapse;\n    margin-left: auto;\n    margin-right: auto;\n    text-align: center;\n  }\n\n  .filter-go {\n    background: #0563b4;\n    padding: 5px 10px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
