import React, { useCallback, useState } from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Card, CardBody, Textarea, useToast, useTheme, Text } from '@chakra-ui/react';
import { CONSTANTS } from '../../services/constants';
import { postCall } from '../../services/httpService';
import { CONTACTUS } from '../../services/apiRoutes';

function ContactUs({ screenName = "Contact Us", create = true }) {
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    'areyou-type': "",
    'requirement': ""
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();


  const registerUser = useCallback(() => {
    const requestBody = { ...formValues };
    postCall(CONTACTUS, requestBody).then((res) => {
      if (res.status === 200) {
        toast({
          title: 'Information received successfully, We will contact you soon!',
          status: 'success',
          duration: CONSTANTS.DURATION,
          isClosable: true,
        });
        setFormValues({
          fname: "",
          lname: "",
          email: "",
          phone: "",
          'areyou-type': "",
          'requirement': ""
        });
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
        toast({
          title: error.message || 'Something went wrong',
          status: 'error',
          duration: CONSTANTS.DURATION,
          isClosable: true,
        })
      }
    }).finally(() => {
      setIsSubmit(false);
      setIsLoading(false);
    });
  }, [formValues, toast]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (validate(formValues)) {
      setIsLoading(true);
      registerUser();
    }
  }, [formValues, registerUser]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
    setFormErrors({});
  }, [setFormValues]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.fname) {
      errors.fname = "First Name is required!";
    }

    if (!values.lname) {
      errors.lname = "Last Name is required!";
    }

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is required!";
    } else if (values.phone.length < 10) {
      errors.phone = "Please enter valid mobile number";
    }

    if (!values['areyou-type']) {
      errors.utype = "Select Are You";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const theme = useTheme();
  const { imageFlex, flexText, fFont, topic } = theme;

  return (
    <>

      <Flex h={{ base: "87vh", lg: "125vh" }} alignItems="center" justifyContent="center">
        <Card m={2}>
          <CardBody>
            <form>
              <Flex flexDirection="column" minWidth={320} maxWidth={300}>
                {/* <Heading alignSelf={'center'} fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} mb={3}>Contact Us</Heading> */}
                <Text {...topic} ><b>Contact Us</b></Text>
                <FormControl isInvalid={formErrors.fname}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>First Name</FormLabel>
                  <Input
                    type="text"
                    name="fname"
                    placeholder="Enter First Name"
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                    value={formValues.fname}
                    onChange={handleChange}
                  />
                  <FormErrorMessage fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>{formErrors.fname}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formErrors.lname}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>Last Name</FormLabel>
                  <Input
                    type="text"
                    name="lname"
                    placeholder="Enter Last Name"
                    value={formValues.lname}
                    onChange={handleChange}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  />
                  <FormErrorMessage>{formErrors.lname}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formErrors.phone}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>Phone Number</FormLabel>
                  <Input
                    type="text"
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={formValues.phone}
                    onChange={handleChange}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  />
                  <FormErrorMessage fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>{formErrors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formErrors.email}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>Email Address</FormLabel>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Enter Email Address"
                    value={formValues.email}
                    onChange={handleChange}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  />
                  <FormErrorMessage fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>{formErrors.email}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formErrors.utype}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>Are You</FormLabel>
                  <Select fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} value={formValues['areyou-type']} name="areyou-type" id="areyou-type" onChange={handleChange}>
                    <option value="">-Select-</option>
                    <option value="Student">Student</option>
                    <option value="Individual/Group">Individual/Group</option>
                    <option value="Company">Company</option>
                  </Select>
                  <FormErrorMessage>{formErrors['areyou-type']}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={formErrors.requirement}>
                  <FormLabel fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>Requirement</FormLabel>
                  <Textarea
                    type="text"
                    name="requirement"
                    placeholder="Enter Requirement"
                    value={formValues.requirement}
                    onChange={handleChange}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  />
                  {/* <FormErrorMessage>{formErrors.requirement}</FormErrorMessage> */}
                </FormControl>
                <Box mt={2}>
                  <Flex align={'center'} justify={'center'} gap={2}>
                    <Button isLoading={isLoading} size={"md"} disabled={isLoading} onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </form>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}

export default ContactUs;