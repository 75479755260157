import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  UnorderedList,
  ListItem,
  Text,
  useTheme
} from '@chakra-ui/react'

import wellbeing2 from '../../images/wellbeing2.jpg';
import wellbeing3 from '../../images/wellbeing3.jpg';
import wellbeing from '../../images/wellbeing.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Wellbeing() {
  const theme = useTheme();
  const { imageFlex, flexText, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>WELL-BEING AND MENTAL HEALTH</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="wellbeing" /> */}
        <img src={wellbeing} alt="wellbeing" />
      </Box>
      <Box>
        <Flex mt={5} mb={5} alignItems={'center'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont}>
                At A3 Minds, we prioritize the well-being and mental health of individuals. Our Well-being and Mental Health programs provide support, guidance, and tools to help individuals manage stress, cultivate resilience, and promote overall emotional well-being.<br />
                <b>Our Well – being and Mental Health Training Programs are broadly divided into two categories which covers the entire spectrum of life.</b>
              </Text>
            </Box>
            <Box m={5}>
              <Center>
                <Image src={wellbeing2} w={'100%'} mt={2} mb={1} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText}>
                  Emotional Intelligence and Self-Awareness Workshop
                </Text>
              </Flex>
              <Center>
                <Image src={wellbeing3} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText}>
                  Stress Management and Resilience Building
                </Text>
              </Flex>
            </Box>
            <Box m={5}>

              <Text mt={1} mb={4} fontSize={{ base: "md", md: "xl", lg: "2xl" }}>
                <b>WELL – BEING AND MENTAL HEALTH Training Programs has been designed to address the following Modules.</b>
              </Text>
              <UnorderedList fontSize={fFont}>
                <ListItem>Explores strategies for stress management, self-care, emotional well-being and fostering a
                  positive mind-set.</ListItem>
                <ListItem>Further delves deeper into emotional intelligence, mindfulness practices and building
                  healthy coping mechanisms.</ListItem>
                <ListItem>Focuses on resilience-building strategies and self-care practices for maintaining mental well-
                  being.</ListItem>
                <ListItem>Explores topics such as emotional intelligence, positive psychology and building mental
                  resilience in challenging situations.</ListItem>
                <ListItem>Equips with advanced stress management techniques, effective coping mechanisms and strategies to handle high-pressure situations.</ListItem>
                <ListItem>Deepens the understanding of emotional intelligence, self-awareness and developes comprehensive well-being plans to navigate the demands of academics and future endeavours.</ListItem>
              </UnorderedList>
            </Box>
          </Container>

        </Flex>
      </Box>
    </>
  )
}