import { Flex, Heading, Box, Center } from '@chakra-ui/react'
import React from 'react'
import Logo from "../../../images/logo.png";

function DashBoardHome() {
  return (
    <Flex mt={5} flexDirection={'column'} h="87vh" alignItems="center">
      <Box m={5} mt={0}>
          <Heading mt={6} size={"lg"} textAlign={"center"}>
            Welcome to
          </Heading>
          <Center mt={0} mb={5}>
            <div className="logowithcontent">
              <img
                style={{
                  width: "400px",
                  height: "85px",
                }}
                src={Logo}
                alt=""
              />
            </div>
          </Center>
        </Box>
    </Flex>



  )
}

export default DashBoardHome
