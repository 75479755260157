import React from "react";
import { Table as ChakraTable, TableProps } from "@chakra-ui/react";
import { Provider } from "../table-utils";
// import { Provider } from "..";

export const Table = (props) => {
    const { className, ...rest } = props;
    const classes = `${className || ""} responsiveTable`;

    return (
        <Provider value={{}}>
            <ChakraTable className={classes}  {...rest} />
        </Provider>
    );
};
