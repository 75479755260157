import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';

const SuccessModal = ({ onClose }) => {
    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Thank You</ModalHeader>
                <ModalBody>
                    Successfully registered!
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const ErrorModal = ({ onClose }) => {
    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Registration Error</ModalHeader>
                <ModalBody>
                    Registration failed. Please try again.
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export { SuccessModal, ErrorModal };