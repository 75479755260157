import React, { memo } from "react";
// import { Link } from "react-router-dom";
// import { Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { useColorModeValue, useBreakpointValue, Flex, Stack, Container, Input, Text, useToast } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";
import { Link } from "react-router-dom";
import { axiosSource, postCall } from "../../services/httpService";
import { QUESTIONAIRE_API } from "../../services/apiRoutes";
import { CONSTANTS } from "../../services/constants";
function QuestionaireList({ questionaireList, changeStatus, gradeLevel, changeList, questionaireApidata }) {
    const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
    const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
    const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });
    const toast = useToast();
    async function updateQuestionList(list, item) {
        const source = axiosSource();

        const requestBody = item;

        await postCall(QUESTIONAIRE_API.QUESTION_EDIT, requestBody, {}, source).then(res => {
            if (res.status === 200) {
                changeList({
                    isLoading: false,
                    data: list
                })
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
                toast({
                    title: error || "",
                    status: 'error',
                    duration: CONSTANTS.DURATION
                });
            }
        })

    };
    const updateList = (item, index) => {
        let updatedItem = item;
        updatedItem["active"] = Number(item.active) ? 0 : 1;
        let newUpdatedList = questionaireList.data.map((item) => {
            if (item["question-id"] === updatedItem["question-id"]) {
                item = updatedItem;
            }
            return item
        })
        updateQuestionList(newUpdatedList, updatedItem)
    }

    return (
        <>
            <Flex m={5} overflowY={"auto"} >
                <Table variant={variant} colorScheme={colorScheme} mt={5}  >
                    <Thead backgroundColor={popoverContentBgColor}>
                        <Tr>
                            <Th fontSize={'2xs'}>Session Desc</Th>
                            <Th fontSize={'2xs'}>Grade/Dept</Th>
                            <Th fontSize={'2xs'}>Section/Year</Th>
                            <Th fontSize={'2xs'}>Level</Th>
                            <Th fontSize={'2xs'}>Questionnaire Title</Th>
                            <Th fontSize={'2xs'}>Questionnaire Option</Th>
                            <Th fontSize={'2xs'}>Answer</Th>
                            {/* <Th fontSize={'2xs'}>Questionaire Type</Th> */}
                            <Th fontSize={'2xs'}>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(questionaireList?.data && questionaireList?.data.length > 0) ? questionaireList.data.map((item, index) => (
                            <Tr key={item['question-id']}>
                                <Td fontSize={'2xs'} >{(item ? item['session'] : null) || "-"}</Td>
                                <Td fontSize={'2xs'} >{(item ? item['grade-name'] : null) || "-"}</Td>
                                <Td fontSize={'2xs'} >{(item ? item['section'] : null) || "-"}</Td>
                                <Td fontSize={'2xs'} >{(item ? item['level-name'] : null) || "-"}</Td>
                                <Td fontSize={'2xs'} >{item['question-text'] || "-"}</Td>
                                {/* <Td>{item['question-type'] || "-"}</Td> */}
                                <Td fontSize={'2xs'} >
                                    <ul>
                                        {(item['option-list'] && item['option-list'].length > 0) ?
                                            item['option-list'].map((option, index) =>
                                                <li key={option['option-text'] + index} style={{ listStyleType: 'none' }}>{index + 1}.&nbsp;{option['option-text'] || "-"}</li>
                                            )
                                            : <li>-</li>
                                        }
                                    </ul>
                                </Td>
                                <Td fontSize={'2xs'} >
                                    <ul>
                                        {(item['option-list'] && item['option-list'].length > 0) ?
                                            item['option-list'].map((option, index) => {
                                                if (option['option-isAnswer'] === 1) {
                                                    return <li key={option['option-text'] + index} style={{ listStyleType: 'none' }}>{index + 1}.&nbsp;{option['option-text'] || "-"}</li>
                                                }
                                            }) : <li>-</li>
                                        }
                                    </ul>
                                    {/* { item['option-list'].filter((option, index) => {
                                    if(option['option-isAnswer'] === 1) return option
                                })[0]['option-text'] || "-"} */}
                                </Td>
                                <Td fontSize={'2xs'}>
                                    <Flex gap={3}>

                                        <Link to={"/edit-questionaire"} state={{ "item": item, "data": questionaireApidata }}>{"Edit"}</Link>
                                        <Text cursor={"pointer"} fontSize={{ base: '2px', md: "xs", lg: '10px' }} onClick={() => { updateList(item, index) }}   >{Number(item.active) ? "active" : "inActive"}</Text>
                                    </Flex>
                                </Td>
                                {/* <Td className="action_flex">
                                    <a href="javascript:void(0)" onClick={() => changeStatus(item)}>{item['active'] ? 'InActive' : 'Active'}</a>
                                </Td> */}
                            </Tr>
                        )) : (questionaireList?.isLoading ? null : <Tr><Td fontSize={'2xs'} >No data found</Td></Tr>)}
                    </Tbody>
                </Table>
            </Flex>
        </>
    )
}

export default memo(QuestionaireList);