import React, { useEffect } from 'react';
import { axiosSource, postCall } from "../../services/httpService";
import { USER_API } from "../../services/apiRoutes";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Card, CardBody } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { CONSTANTS } from '../../services/constants';
import { initialValues, validate } from '../../services/userService';
import { handleToast, toastByStatus, toastErrorOnly } from '../../services/utils';

function CreateUser({ screenName = "Create User", create = true }) {
  const toast = useToast();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    isLoading: false,
    data: null
  });
  const navigate = useNavigate();
  let { id } = useParams();

  async function getUserDetails(id, source) {
    const requestBody = { "lni-user-id": id };
    await postCall(USER_API.USER, requestBody, {}, source).then(res => {
      if (res.status === 200) {
        setUserDetails(prev => ({ ...prev, data: res?.data }));
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    }).finally(() => setUserDetails(prev => ({ ...prev, isLoading: false })));
  };

  useEffect(() => {
    const source = axiosSource();
    if (id) {
      setUserDetails(prev => ({ ...prev, isLoading: true }));
      getUserDetails(Number(id), source)
    }
    return () => {
      source.cancel()
    }
  }, [id]);

  useEffect(() => {
    if (!userDetails.data) return;
    setFormValues({
      fname: userDetails.data['fname'],
      lname: userDetails.data['lname'],
      email: userDetails.data['email'],
      phone: userDetails.data['phone'],
      utype: userDetails.data['user-type'],
      active: userDetails.data['active'],
      "lni-user-id": userDetails.data['lni-user-id'],
      "auth59-user-id": userDetails.data['auth59-user-id'],
    })
  }, [userDetails.data]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
    setFormErrors({});
  }, [setFormValues]);

  const registerUser = useCallback(() => {
    const requestBody = { ...formValues, "user-type": formValues.utype, "auth59-user-id": formValues['auth59-user-id'], "lni-user-id": formValues['lni-user-id'] };
    delete requestBody.utype;
    postCall(USER_API.REGISTER, requestBody).then((res) => {
      if (res.status === 200) {
        toastByStatus(
          toast,
          res?.data?.success,
          res?.data?.error,
          `User ${id ? 'updated' : 'created'} successfully`,
          res?.data?.error
        )
        setFormValues(initialValues);
        setTimeout(() => {
          navigate('/users');
        }, 2000);
      }
    }).catch((error) => toastErrorOnly(error)).finally((response) => {
      setIsLoading(false);
    });
  }, [formValues, id, navigate, toast]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (validate(formValues, setFormErrors)) {
      setIsLoading(true);
      registerUser();
    }
  }, [formValues, registerUser]);

  return (
    <>
      {(userDetails.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
      <Flex alignItems="center" justifyContent="center">
        <Card m={2} mt={5}>
          <CardBody h={{ base: "87vh", lg: "125vh" }}>
            <Flex justifyContent={'center'}>
              <form className="bulkupload-section form-data">
                <Heading mb={4} textAlign={"center"}>{id ? "Edit User" : screenName}</Heading>
                <Flex flexDirection="column" justifyContent={'center'} minWidth={{ base: 300, md: 400, lg: 400 }} maxWidth={300}>
                  <FormControl isInvalid={formErrors.fname}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      size='sm'
                      type="text"
                      name="fname"
                      placeholder="Enter First Name"
                      value={formValues.fname}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.fname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.lname}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      size='sm'
                      type="text"
                      name="lname"
                      placeholder="Enter Last Name"
                      value={formValues.lname}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.lname}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.email}>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                      size='sm'
                      type="text"
                      name="email"
                      placeholder="Enter Email Address"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.phone}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      size='sm'
                      type="tel"
                      name="phone"
                      max={15}
                      placeholder="Enter Phone Number"
                      value={formValues.phone}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={formErrors.utype}>
                    <FormLabel>User Type</FormLabel>
                    <Select size='sm' value={formValues.utype} name="utype" id="utype" onChange={handleChange}>
                      <option value="">-Select-</option>
                      <option value={CONSTANTS.USER_TYPE.ADMINISTRATOR}>Admin</option>
                      <option value={CONSTANTS.USER_TYPE.COORDINATOR}>Co-Ordinator</option>
                      <option value={CONSTANTS.USER_TYPE.TRAINER}>Trainer</option>
                      <option value={CONSTANTS.USER_TYPE.STUDENT}>Student</option>
                    </Select>
                    <FormErrorMessage>{formErrors.utype}</FormErrorMessage>
                  </FormControl>
                  <Box mt={2}>
                    <Flex align={'center'} justify={'center'} gap={2}>
                      <Button size={'sm'} colorScheme='blue' disabled={isLoading} isLoading={isLoading} onClick={handleSubmit}>
                        {create ? (id ? "Update" : "Submit") : "Register"}
                      </Button>
                      <Button size={'sm'} colorScheme='gray' type="button" onClick={
                        () => { navigate('/users') }
                      }>Cancel</Button>
                    </Flex>
                  </Box>
                </Flex>
              </form>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
}

export default CreateUser;

