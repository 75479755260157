import { Button, Flex, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import React, { memo } from "react";
import { CONSTANTS } from "../../services/constants";

function UserFilter({ submitted, searchObj, handleChange, onGo, onCreate, onReset }) {
  return (
    <>
      <Flex mt={2} align={{ base: 'center', md: 'center', lg: 'end' }} gap={2} direction={{ base: "column", md: "column", lg: "row" }}>
        <FormControl width={{ base: '100%', md: '100%', lg: '76%' }}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }}>Search User</FormLabel>
          <Input size={{ base: "sm", md: "md", lg: "lg" }} id="search" type="text" name="searchValue" placeholder="Enter Username" value={searchObj.searchValue} onChange={handleChange} />
        </FormControl>
        <FormControl width={{ base: '100%', md: '100%', lg: '76%' }}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }}>User Type</FormLabel>
          <Select value={searchObj.type} size={{ base: "sm", md: "md", lg: "lg" }} name="type" id="type" onChange={handleChange}>
            <option value="">-Select-</option>
            <option value={CONSTANTS.USER_TYPE.ADMINISTRATOR}>Admin</option>
            <option value={CONSTANTS.USER_TYPE.COORDINATOR}>Co-Ordinator</option>
            <option value={CONSTANTS.USER_TYPE.TRAINER}>Trainer</option>
            <option value={CONSTANTS.USER_TYPE.STUDENT}>Student</option>
          </Select>
        </FormControl>
        <Button colorScheme='blue' type="button" onClick={onGo} pr={8} pl={8}>
          Go
        </Button>
        <Button colorScheme='green' type="button" onClick={onCreate} pr={8} pl={8}>
          Create
        </Button>
        <Button isDisabled={(!searchObj.searchValue || !searchObj.type) && !submitted} type="button" onClick={onReset} pr={8} pl={8}>
          Reset
        </Button>
      </Flex>
    </>
  );
}

export default memo(UserFilter);
