import React, { useEffect, useState } from "react";
import "./score.css";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const ThankyouComponent = (props) => {
    // const [score, setScore] = useState(null);
    // useEffect(() => {
    //     let score = localStorage.getItem('score');
    //     if(score) {
    //         console.log(score);
    //         setScore(JSON.parse(score));
    //     }
    // }, []);
  return (
    // <Box as={Container} maxW="7xl" mt={14} p={4}>
    <Flex h="87vh" mt={12} mb={12} direction={'column'} alignItems={'center'} justifyContent={'center'}>
      <Box>
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </Box>
      <Heading>Great work!</Heading>
      <Text>Assessment submitted successfully</Text>
      {/* <Heading fontSize={'2xl'}>Grade 100%</Heading> */}
      {/* <Box>
        {(score?.score && score['score-out-of']) && <Text>Score&nbsp;{score?.score}/{score['score-out-of']}</Text>}
      </Box> */}
    </Flex>
    // </Box>
  );
};

export default ThankyouComponent;
