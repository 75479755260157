import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Reports.css";
import { REPORTS } from "../../services/apiRoutes";
import { axiosSource, postCall } from "../../services/httpService";
import ReportFilter from "./ReportFilter";
import { AuthContext } from "../../App";
import { useBreakpointValue } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";
import { useColorModeValue, Link, Flex, Container, Text, Box } from "@chakra-ui/react";
import antony from '../../images/antony.png';

export default function Reports() {
  const [context] = useContext(AuthContext);
  const [showCSVButton, setShowCSVButtton] = useState(false);
  const [reportList, SetReportList] = useState({
    isLoading: false,
    data: [],
  });

  const getAssessList = useCallback(
    async (requestBody = {}, source) => {
      const body = { ...requestBody, "lni-user-id": context?.user_data['lni-user-id'] }
      setShowCSVButtton((prev) => false)
      await postCall(REPORTS, body, {}, source)
        .then((res) => {
          if (res.status === 200) {
            console.log("res", res.data);
            SetReportList((prev) => ({ ...prev, data: res?.data }));
            if(res?.data?.length)setShowCSVButtton((prev) => true)
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error.message); // => the response payload
          }
        })
        .finally(() =>
          SetReportList((prev) => ({ ...prev, isLoading: false }))
        );
    },
    [context?.user_data]
  );
  const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
  const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
  const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });

  useEffect(() => {
    SetReportList((prev) => ({ ...prev, isLoading: true }));
    const source_one = axiosSource();
    getAssessList({}, source_one);
    return () => {
      source_one.cancel();
    };
  }, [getAssessList]);

  const handleFilterSubmit = (data) => {
    const body = {
      "institution-id": data["institution-id"] ? Number(data["institution-id"]) : 0,
      "grade-id": data["grade-id"] ? Number(data["grade-id"]) : 0,
      "level-id": data["level-id"] ? Number(data["level-id"]) : 0,
      "section-name": data["grade-section"]
    };
    SetReportList((prev) => ({ ...prev, isLoading: true }));
    getAssessList(body);
  };
  const downloadCSV = () => {
    const csvString = [
      [
        "S.No",
        "Student Name",
        "Level",
        "Grade/Department",
        "Section/Year",
        "Personal Development %",
        "Communication And Interpersonal Skills %",
        "Adaption To Technology And 21ST-Century Skills %",
        "Well-Being And Mental Health %",
        "Academic And Career Readiness %",
        "Score %"
      ],
      ...reportList?.data.map((item, index) => [
        index + 1,
        item.full_name,
        item.level || "NA",
        item.grade || "NA",
        item["section_name"] || "NA",
        Number(item["pd_per"]) || "NA",
        Number(item["cais_per"]) || "NA",
        Number(item["adapt_per"]) || "NA",
        Number(item["wellBeing_per"]) || "NA",
        Number(item["acedemic_per"]) || "NA",
        Number(item["Overal_per"]) || "NA"
      ])
    ].map(e => e.join(",")) 
     .join("\n");

      // Create a hidden <a> element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([csvString]));
      link.download = "report.csv";

      // Append the link to the body
      document.body.appendChild(link);

      // Click the link
      link.click();
  };

  return (
    <>
      <ReportFilter
        handleGo={handleFilterSubmit}
        downloadCSV={downloadCSV}
        context={context}
        showCSVButton={showCSVButton}
        setShowCSVButtton={setShowCSVButtton}
      />
      <br />
      {reportList.isLoading && (
        <div className="loader flex-center">Loading...</div>
      )}
      <>
        <Flex mt={5} width={"100%"}>
          {reportList.data.length ? (
            <Container maxW={"100%"} overflowY={"auto"}>
              <Table
                variant={variant}
                colorScheme={colorScheme}
                mt={5}
                width={{ base: "65%", md: "65%", lg: "100%" }}
              >
                <Thead
                  backgroundColor={popoverContentBgColor}
                  position={"sticky"}
                  top={0}
                >
                  <Tr>
                    <Th className="report-header" fontSize={"2xs"}>
                      S.No
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Student Name
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Level{" "}
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Grade/Department
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Section/Year
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Personal Development %
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Communication And Interpersonal Skills %
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Adaption To Technology And 21ST-Century Skills %
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Well-Being And Mental Health %
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Academic And Career Readiness %
                    </Th>
                    <Th className="report-header" fontSize={"2xs"}>
                      Score %
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reportList?.data && reportList?.data.length > 0 ? (
                    reportList.data.map((item, index) => (
                      <Tr key={item.full_name + index}>
                        <Td fontSize={"2xs"}>{index + 1}</Td>
                        <Td fontSize={"2xs"}>{item.full_name}</Td>
                        <Td fontSize={"2xs"} css={{ minWidth: "6rem" }}>
                          {item.level || "NA"}
                        </Td>
                        <Td fontSize={"2xs"}>{item.grade || "NA"}</Td>
                        <Td fontSize={"2xs"}>{item["section_name"] || "NA"}</Td>
                        <Td fontSize={"2xs"}>
                          {Number(item["pd_per"]) || "NA"}
                        </Td>
                        <Td fontSize={"2xs"}>{item["cais_per"] || "NA"}</Td>
                        <Td fontSize={"2xs"}>{item["adapt_per"] || "NA"}</Td>
                        <Td fontSize={"2xs"}>
                          {item["wellBeing_per"] || "NA"}
                        </Td>
                        <Td fontSize={"2xs"}>{item["acedemic_per"] || "NA"}</Td>
                        <Td fontSize={"2xs"}>
                          {Number(item["Overal_per"]) || "NA"}
                        </Td>
                        {/* <Td fontSize={'2xs'}>{item.score}</Td> */}
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td fontSize={"2xs"}>No data found</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Container>
          ) : (
            <></>
          )}

          {/* </Container> */}
        </Flex>
        <Box
          className="footer"
          fontFamily={"Segoe UI"}
          width={"100%"}
          padding={0}
          margin={0}
          marginTop={{ lg: `${reportList.data.length ? "10%" : "40%"}` }}
        >
          <br />
          <hr />
          <center>
            <img
              style={{
                width: "350px",
                height: "69px",
                marginTop: "10px",
              }}
              src={antony}
              alt="logo"
            />
          </center>
          <Container as="footer" role="contentinfo" bottom={0}>
            <Box padding="10px 0px 0px 0px">
              <Flex align={"center"} justify={"center"} gap={2}>
                <Link
                  fontSize="sm"
                  href="../privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                |
                <Link
                  fontSize="sm"
                  href="../terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>
              </Flex>
            </Box>
            <Text fontSize="14px" color="fg.subtle" textAlign={"center"}>
              <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam,
              Chennai – 600 092.
              <br />
              <Flex
                marginBottom={"5px"}
                justifyContent={"center"}
                flexWrap={"wrap"}
              >
                <Box width={"170px"}>
                  <Text>
                    <b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;
                  </Text>
                </Box>
                <Box width={"180px"}>
                  <Text>
                    <b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;
                  </Text>
                </Box>
                <Box width={"180px"}>
                  <Text>
                    <b>Email:</b>&nbsp;A3minds@gmail.com
                  </Text>
                </Box>
              </Flex>
            </Text>
            <Text fontSize="12px" color="fg.subtle" textAlign={"center"}>
              &copy; {new Date().getFullYear()} All rights reserved.
            </Text>
          </Container>
        </Box>
      </>
    </>
  );
}
