import { Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Select, Wrap } from "@chakra-ui/react";
import React, { memo } from "react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function InstituteFilter({ searchObj, instituteData, handleChange, onGo, onReset, onUpload }) {
  const navigate = useNavigate();

  return (
    <>
      <Flex mt={2} align={{ base: 'center', md: 'center', lg: 'end' }} justifyContent={"center"} width={{ base: '70%', md: '70%', lg: '100%' }} gap={2} direction={{ base: "column", md: "column", lg: "row" }}>
        <Wrap align={"end"} justify={"center"} width={'100%'}   >
          <FormControl width={{ base: '100%', md: "100%", lg: '18%' }}>
            <FormLabel className="form-label" htmlFor="institution-id">
              Select Institute
            </FormLabel>
            <InputGroup>
              <Select
                size={'lg'}
                value={searchObj["institution-id"]}
                name="institution-id"
                id="institution-id"
                onChange={handleChange}
              >
                <option key="select" value={0}>
                  -Select-
                </option>
                {instituteData?.data?.map((option, index) => (
                  <option
                    key={
                      option["institution-id"] + option["institution-name"] + index
                    }
                    value={option["institution-id"]}
                  >
                    {option["institution-name"]}
                  </option>
                ))}
              </Select>
              {(searchObj['institution-id']) && <InputRightElement
                pointerEvents="visible"
                cursor={'pointer'}
                children={<Icon onClick={() => navigate(`/edit-institute/${searchObj["institution-id"]}`)} mr={12} title="Edit Institute" as={FiEdit} />}
              />}
            </InputGroup>
          </FormControl>
          <FormControl width={{ base: '100%', md: "100%", lg: '18%' }}>
            <FormLabel className="form-label" htmlFor="search">Select by Student</FormLabel>
            <Input size={'lg'} id="search" type="text" name="searchValue" placeholder="Enter Student" value={searchObj.searchValue} onChange={handleChange} />
          </FormControl>
          <FormControl width={{ base: '100%', md: "100%", lg: '19%' }}>
            <FormLabel className="form-label" htmlFor="grade">Filter By Grade</FormLabel>
            <Input size={'lg'} id="grade" type="text" name="grade" placeholder="Enter Grade" value={searchObj.grade} onChange={handleChange} />
          </FormControl>
          <Button size={'md'} colorScheme='blue' type="button" onClick={onGo} pr={8} pl={8}>
            Search
          </Button>
          <Button size={'md'} colorScheme='green' type="button" onClick={onUpload} pr={8} pl={8}>
            Upload
          </Button>
          <Button size={'md'} colorScheme='green' type="button" onClick={() => navigate('/create-institute')} pr={12} pl={12}>
            Create Institute
          </Button>
          <Button size={'md'} isDisabled={!searchObj.grade && !searchObj.searchValue} type="button" onClick={onReset} pr={8} pl={8}>
            Reset
          </Button>
        </Wrap>
      </Flex>
    </>
  );
}

export default memo(InstituteFilter);
