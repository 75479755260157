export const ShippingDelivery = {
  title: "Shipping and Delivery",
  contents: [
       
    {
      head: "Shipping and Delivery",
      details: `Shipping and Delivery is not applicable for our services.`,
    },
    
    {
      head: "Contact Information",
      details: `If you have any questions about these shipping and delivery, please contact us at:
        • Email: info@a3minds.com`,
    },
  ],
};
