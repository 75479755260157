// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  }
  
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Common/score.css"],"names":[],"mappings":";;EAEE;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,YAAY;IACZ,qBAAqB;IACrB,qCAAqC;IACrC,gFAAgF;EAClF;;EAEA;IACE,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,UAAU;IACV,8DAA8D;EAChE;;EAEA;IACE,yBAAyB;IACzB,oBAAoB;IACpB,qBAAqB;IACrB,mEAAmE;EACrE;;EAEA;IACE;MACE,oBAAoB;IACtB;EACF;EACA;IACE;MACE,eAAe;IACjB;IACA;MACE,+BAA+B;IACjC;EACF;EACA;IACE;MACE,0CAA0C;IAC5C;EACF","sourcesContent":["\n  \n  .checkmark {\n    width: 56px;\n    height: 56px;\n    border-radius: 50%;\n    display: block;\n    stroke-width: 2;\n    stroke: #fff;\n    stroke-miterlimit: 10;\n    box-shadow: inset 0px 0px 0px #7ac142;\n    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;\n  }\n  \n  .checkmark__circle {\n    stroke-dasharray: 166;\n    stroke-dashoffset: 166;\n    stroke-width: 2;\n    stroke-miterlimit: 10;\n    stroke: #7ac142;\n    fill: none;\n    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;\n  }\n  \n  .checkmark__check {\n    transform-origin: 50% 50%;\n    stroke-dasharray: 48;\n    stroke-dashoffset: 48;\n    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;\n  }\n  \n  @keyframes stroke {\n    100% {\n      stroke-dashoffset: 0;\n    }\n  }\n  @keyframes scale {\n    0%, 100% {\n      transform: none;\n    }\n    50% {\n      transform: scale3d(1.1, 1.1, 1);\n    }\n  }\n  @keyframes fill {\n    100% {\n      box-shadow: inset 0px 0px 0px 30px #7ac142;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
