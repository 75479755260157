export const USER_API = {
  NEWREGISTER: "/user/register",
  LOOKUPREGISTER: "/user/lookup-register",
  COMPETITIONREGISTER: "/user/cregister",

  PAYMENTCONFIRM: "/user/confirm_payment",
  PAYREGISTER: "/user/pay-registerv2",
  RZPREGISTER: "/user/rzp-register",
  TRANSDONE: "/user/confirm_payment",
  TRANSPENDING: "/user/pending_payment",
  REGISTER: "/user/addedit",
  USER: "/user/select",
  USER_DELETE: "/user/delete",
  USER_UN_DELETE: "/user/undelete",
  USER_INFO: "/user/get_loggedin_user_info",
};

export const INSTITUTE_API = {
  INSTITUTE: "/institution/select_by_user",
  INSTITUTE_ALL: "/institution/select",
  INSTITUTE_DELETE: "/institution/delete",
  INSTITUTE_UN_DELETE: "/institution/undelete",
  INSTITUTE_CREATE: "/institution/addedit",
};

export const STUDENT_API = {
  STUDENT: "/student/select",
  STUDENT_DELETE: "/student/delete",
  STUDENT_UN_DELETE: "/student/undelete",
  STUDENT_UPLOAD: "/student/upload",
};

export const SYLLABUS_API = {
  SYLLABUS: "/syllabus/select",
  SYLLABUS_LIST: "/syllabus/select",
  SYLLABUS_UPLOAD: "/file/upload",
  SYLLABUS_CREATE: "/syllabus/addedit",
  SYLLABUS_DELETE: "/syllabus/delete",
  SYLLABUS_UNDELETE: "/syllabus/undelete",
  SYLLABUS_TYPE: "/syllabus/types",
  SYLLABUS_UPDATE_COMPLETED: "/syllabus/update_completed",
  SYLLABUS_SESSION: "/syllabus/session_types",
  SYLLABUS_GET_ID: "/syllabus/get_id",
};

export const QUESTIONAIRE_API = {
  QUESTIONAIRE_UPLOAD: "/question/set/upload",
  QUESTIONAIRE: "/question/select",
  QUESTIONAIRE_SET: "/question/set/select",
  QUESTIONAIRE_SELECT: "/question/select2",
  QUESTION_SET_ADD_EDIT: "/question/set/addedit",
  QUESTION_ADD_EDIT: "/question/addedit",
  QUESTION_EDIT: "/question/edit",
};

export const GRADE_API = {
  GRADE: "/grade/select",
};

export const LEVEL_API = {
  LEVEL: "/level/select",
};

export const ASSESSMENT_API = {
  ASSESSMENT_ADD_EDIT: "/assessment/addedit",
  ASSESSMENT_ADD_EDIT_V2: "/assessment/addedit_v2",
  ASSESSMENT_SELECT: "/assessment/select",
  ASSESSMENT_SELECT_TWO: "/assessment/select2",
  ASSESSMENT_QUIZ: "/assessment/quiz_call",
  ASSESSMENT_SCORE: "/assessment/quiz_score",
};

export const CONTACTUS = "/request/add";

// export const REPORTS = '/assessment/report';
export const REPORTS = "/assessment/report3";

export const LOGOUT = "/user/logout";

export const SECTION_API = {
  SECTION: "/section/select",
};
