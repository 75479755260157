export const TermsAndConditions = {
  title: "Terms and Conditions",
  contents: [
    {
      head: "Introduction",
      details: `Welcome to A3Minds! These terms and conditions outline the rules and regulations for the use of A3Minds' website and services. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use A3Minds' website if you do not accept all of the terms and conditions stated on this page.`,
    },
    {
      head: "Definitions",
      details: `• Service: A3Minds is a training company that specializes in developing Non-Academic Skills and Abilities in Individuals.
        • User: Any individual or entity that uses the A3Minds website or services.
        • Content: All text, images, data, or other material provided by A3Minds on its website.`,
    },
    {
      head: "Use of the Website",
      details: `You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.`,
    },
    {
      head: "Intellectual Property Rights",
      details: `Unless otherwise stated, A3Minds and/or its licensors own the intellectual property rights for all material on the website. All intellectual property rights are reserved. You may view and/or print pages from the website for your own personal use subject to restrictions set in these terms and conditions.`,
    },
    {
      head: "User Content",
      details: `In these terms and conditions, "User Content" means material (including without limitation text, images, audio material, video material, and audio-visual material) that you submit to this website. You grant to A3Minds a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute your user content in any existing or future media.`,
    },
    {
      head: "Privacy Policy",
      details: `Your use of the website is also subject to A3Minds' Privacy Policy. Please review our Privacy Policy, which governs the website and informs users of our data collection practices.`,
    },
    {
      head: "Limitation of Liability",
      details: `A3Minds will not be liable for any indirect, special, or consequential loss or damage arising out of or in connection with the use of the website or services. A3Minds does not warrant that the website will be constantly available, or available at all, or that the information on the website is complete, true, accurate, or non-misleading.`,
    },
    {
      head: "Indemnity",
      details: `You agree to indemnify and hold harmless A3Minds, its employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses arising out of your use of the website, your violation of these terms, or your violation of any rights of another.`,
    },
    {
      head: "Termination",
      details: `A3Minds reserves the right to terminate your access to the website without any advance notice if you breach these terms and conditions.`,
    },
    {
      head: "Cancellation and Refund Policy",
      details: `Cancellation Policy: Course / Service Enrollment Cancellation
        • Cancellation by User:
          o You may cancel your enrollment for a course/service up to 15 days before the course start date to receive a full refund.
          o If you cancel your enrollment within 15 days but more than 7 days before the course/service start date, you will be eligible for a 50% refund.
          o No refunds will be provided if you cancel your enrollment 7 days or less before the course/service start date or after the course/service has commenced.
        • Cancellation by A3Minds:
          o A3Minds reserves the right to cancel any course/service due to insufficient enrollment, instructor availability, or other unforeseen circumstances. In such cases, you will be notified as soon as possible, and a full refund will be provided.
          o If a course/service is rescheduled or moved online/offline, participants will be given the option to attend the rescheduled course/service, move to the online/offline format, or receive a full refund.
        Refund Policy
        Refund Process
        • To request a refund, please contact our support team at info@a3minds.com with your enrollment or subscription details and the reason for the cancellation.
        • Refunds will be processed within 10 business days of receiving your request.
        • Refunds will be issued using the original payment method. If this is not possible, an alternative method will be arranged.
        Non-Refundable Items
        • The following items are non-refundable:
          o Materials and resources provided as part of the course or service that have been accessed or downloaded.
          o Courses or services that have already commenced or been completed.
          o Any administrative fees associated with the payment process.`,
    },
    {
      head: "Shipping and Delivery",
      details: `Shipping and Delivery is not applicable for our services.`,
    },
    {
      head: "Changes to Terms and Conditions",
      details: `A3Minds may revise these terms and conditions from time to time. Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. We encourage you to review this policy periodically to stay informed about our terms.`,
    },
    {
      head: "Governing Law",
      details: `These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in Chennai.`,
    },
    {
      head: "Contact Information",
      details: `If you have any questions about these terms and conditions, please contact us at:
        • Email: info@a3minds.com`,
    },
  ],
};
