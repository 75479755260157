import { RadioGroup, Radio, Stack, Text, Box, Button, useToast, Card, CardBody, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Select, Container } from '@chakra-ui/react';
import React, { useEffect } from "react"
import { axiosSource, postCall } from '../../services/httpService';
import { useLocation, useNavigate } from 'react-router-dom';
import { QUESTIONAIRE_API } from '../../services/apiRoutes';
import { CONSTANTS } from '../../services/constants';
import { Link } from "react-router-dom";



const EditQuestionarie = () => {

    let { state } = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    console.log("state : ", state)

    const handleOnSave = () => {
        if (editData) {

            updateQuestion()
        }

    }

    const [value, setValue] = React.useState(null);
    const [editable, setEditable] = React.useState(false);
    const [editData, setEditData] = React.useState(state.item);


    useEffect(() => {
        let newObj = JSON.parse(JSON.stringify(editData));
        newObj["option-list"] = newObj["option-list"].map((item) => {
            if (item["option-isAnswer"]) { item["option-isAnswer"] = 0 };
            if (item["option-text"] === value) { item["option-isAnswer"] = 1 }
            return item
        });
        setEditData(newObj)
    }, [value])

    async function updateQuestion() {
        const source = axiosSource();
        const requestBody = editData;
        await postCall(QUESTIONAIRE_API.QUESTION_EDIT, requestBody, {}, source).then(res => {
            if (res.status === 200) {
                navigate("/questionaire")
            }
        }).catch((error) => {
            if (error) {
                console.log(error.message); // => the response payload 
                toast({
                    title: error || "",
                    status: 'error',
                    duration: CONSTANTS.DURATION
                });
            }
        })

    };

    return (
        <Flex alignItems="center" justifyContent="center" h={{ base: "87vh", lg: "90vh" }} >
            <Card m={24} minW={300} minWidth={{ md: 400, lg: 400 }} maxWidth={350}  >
                <Text mt={6} textAlign={'center'} fontSize={{ base: "lg", md: "xl", lg: "2xl" }} fontWeight={'bold'} >Edit Questionaire</Text>
                <CardBody minW={300}>
                    <Stack direction={['column']} spacing={{ base: "42px", md: "42px", lg: "48px" }} >
                        {editable ? <Input fontSize={{ base: "sm", md: "md", lg: "lg" }} onChange={(event) => { setEditData({ ...editData, "question-text": event.target.value }) }} value={editData["question-text"]} htmlSize={4} width='auto' /> : <Text paddingBottom={2} fontSize={{ base: "sm", md: "md", lg: "lg" }}>{editData["question-text"]}</Text>}
                        <RadioGroup onChange={setValue} value={value}>
                            <Stack direction='column'>
                                {editData["option-list"].map((item, index) => {
                                    if (!value && item["option-isAnswer"]) {
                                        setValue(item["option-text"])
                                    }
                                    return (
                                        <Flex >
                                            <Radio size={{ base: "sm", md: "md", lg: "lg" }} borderColor={item["option-text"] !== value ? 'gray' : ''} value={item["option-text"]} pr={3}></Radio> {editable ? <Input onChange={(event) => { let currentObj = JSON.parse(JSON.stringify(editData)); currentObj['option-list'][index] = { ...item, "option-text": event.target.value }; setEditData(currentObj) }} fontSize={{ base: "sm", md: "md", lg: "lg" }} value={item["option-text"]} htmlSize={4} width='100%' /> : <Text mb={2} fontSize={{ base: "sm", md: "md", lg: "lg" }}  >{item["option-text"]}</Text>}
                                        </Flex>)
                                })}

                            </Stack>
                        </RadioGroup>
                        <Flex justifyContent={'center'} gap={3} >

                            <Button colorScheme='blue' type="button" fontSize={{ base: "sm", md: "md", lg: "lg" }} onClick={
                                () => { setEditable(!editable) }
                            } >Edit</Button>

                            <Link to={"/questionaire"} state={{ "data": state.data }}  >{<Button colorScheme='blue' onClick={() => { handleOnSave() }} type="button" fontSize={{ base: "sm", md: "md", lg: "lg" }}  >Save</Button>}</Link>
                            <Link to={"/questionaire"} state={{ "data": state.data }}>{<Button colorScheme='blue' type="button" fontSize={{ base: "sm", md: "md", lg: "lg" }} >Cancel</Button>}</Link>

                        </Flex>
                    </Stack>
                </CardBody>
            </Card>
        </Flex>
    )
}

export default EditQuestionarie;