import { CONSTANTS } from "./constants";

export const dataObj = {
  isLoading: false,
  data: []
}

export function checkfile(sender) {
  var validExts = [".xlsx", ".xls", ".csv"];
  var fileExt = sender.value;
  fileExt = fileExt.substring(fileExt.lastIndexOf("."));
  if (validExts.indexOf(fileExt) < 0) {
    alert(
      "Invalid file selected, valid files are of " +
      validExts.toString() +
      " types."
    );
    return false;
  } else return true;
}

function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

export function validateFileType(file) {
  const allowedTypes = ['csv'];
  return allowedTypes.includes(getExtension(file.name));
}

export const formatDate = (date) => new Date(date).toLocaleDateString('en-US');

export const handleToast = (toast, title, status, render) => {
  toast({
    title: title,
    status: status,
    duration: CONSTANTS.DURATION
  });
  if (!title || !status && render) {
    toast({
      title: "",
      status: 200,
      render: render,
      duration: CONSTANTS.DURATION
    })
  }
}

export const toastByStatus = (toast, success, error, successMsg, errMsg) => {
  if (success) {
    handleToast(toast, successMsg, 'success');
  };
  if (error) {
    handleToast(toast, errMsg, 'error');
  };
}

export const toastWithButton = (toast, render) => {
  handleToast(toast, render)
}

export const toastErrorOnly = (toast, error) => {
  if (error) {
    handleToast(toast, (error.message || 'Something went wrong'), 'error');
  }
}
