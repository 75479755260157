import React, { useCallback, useEffect, useState, useMemo } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import { GRADE_API, INSTITUTE_API, LEVEL_API } from "../../services/apiRoutes";
import { CONSTANTS } from "../../services/constants";
import { Button, Flex, FormControl, FormLabel, Input, Select, Container } from "@chakra-ui/react";

function ReportFilter({ handleGo, context, downloadCSV, showCSVButton, setShowCSVButtton }) {
  const initialValues = {
    "institution-id": "",
    "syllabus-id": 0,
    "grade-id": "",
    "level-id": "",
    "file-id": "",
    "complete-by": "",
    "syllabus-name": "",
    "grade-section": "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: [],
  });
  const [gradeList, SetGradeList] = useState({
    isLoading: false,
    data: [],
  });
  const [levelList, SetLevelList] = useState({
    isLoading: false,
    data: [],
  });
  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);
  const [instituteType, setInstituteType] = useState(false);

  async function getInstituteList(source) {
    let body = {};
    if (!isAdmin) {
      body = { ...body, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true)
      .then((res) => {
        // console.log('res?.data', res?.data);
        if (res.status === 200) {
          SetInstituteList((prev) => ({
            ...prev,
            data: res?.data?.error ? [] : res?.data,
          }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      })
      .finally(() =>
        SetInstituteList((prev) => ({ ...prev, isLoading: false }))
      );
  }

  async function getGradeList(id, type) {
    await postCall(
      GRADE_API.GRADE,
      {
        "institution-id": Number(id),
        "institution-type": type,
      },
      null,
      null,
      true
    )
      .then((res) => {
        if (res.status === 200) {
          if (type == "COLLEGE") {
            setInstituteType(false)
          } else {
            setInstituteType(true)
          }
          SetGradeList((prev) => ({ ...prev, data: res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      })
      .finally(() => SetGradeList((prev) => ({ ...prev, isLoading: false })));
  }

  async function getLevelList(source) {
    await postCall(
      LEVEL_API.LEVEL,
      {
        "institution-id": Number(formValues["institution-id"]),
      },
      null,
      source,
      true
    )
      .then((res) => {
        if (res.status === 200) {
          SetLevelList((prev) => ({ ...prev, data: res?.data }));
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      })
      .finally(() => SetLevelList((prev) => ({ ...prev, isLoading: false })));
  }

  const getGradeBasedOnScreen = useCallback(
    (data) => {
      const result = instituteList.data?.filter((item) => {
        const val = data;
        if (item["institution-id"] === Number(val["institution-id"])) {
          return item;
        }
      });
      // console.log('result', result);
      if (result && result.length > 0) {
        SetGradeList((prev) => ({ ...prev, isLoading: true }));
        getGradeList(
          result[0]["institution-id"],
          result[0]["institution-type"]
        );
      }
    },
    [instituteList.data]
  );

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      // console.log('name', name, value);
      setShowCSVButtton(prev => false)
      if (name === "institution-id") {
        setFormValues((prev) => ({
          ...prev,
          "grade-id": "",
          "grade-section": "",
        }));
        getGradeBasedOnScreen({ [name]: value });
      }
      setFormValues((prev) => ({ ...prev, [name]: value }));
    },
    [setFormValues, getGradeBasedOnScreen]
  );

  useEffect(() => {
    SetInstituteList((prev) => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getInstituteList(source);
    const source2 = axiosSource();
    getLevelList({}, source2);
    return () => {
      source.cancel();
      // source1.cancel()
      source2.cancel();
    };
  }, []);

  return (
    <Flex justifyContent={'center'} mt={10} >
      <Flex width={{ lg: '100%' }} justifyContent={"center"} direction={{ base: 'column', md: 'column', lg: 'row' }}>
        <FormControl width={{ lg: '233px' }} marginLeft={3}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }} fontWeight={'bold'}>  Institute:</FormLabel>
          <Select value={formValues["institution-id"]} size={{ base: "sm", md: "md", lg: "lg" }} name="institution-id" id="type" onChange={handleChange}>
            <option value="">-Select-</option>
            {instituteList &&
              instituteList?.data?.map((option, index) => (
                <option
                  key={
                    option["institution-id"] + option["institution-name"] + index
                  }
                  value={option["institution-id"]}
                >
                  {option["institution-name"]}
                </option>
              ))}
          </Select>
        </FormControl>


        <FormControl width={{ lg: '193px' }} marginLeft={3}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }} fontWeight={'bold'}> Grade:</FormLabel>
          <Select value={formValues["grade-id"]} size={{ base: "sm", md: "md", lg: "lg" }} name="grade-id" id="type" onChange={handleChange}>
            <option value="">-Select-</option>
            {gradeList?.data?.map((option, index) => (
              <option
                key={option["grade-value"] + index}
                value={option["grade-id"]}
              >
                {option["grade-name"]}
              </option>
            ))}
          </Select>
        </FormControl>



        {instituteType && <FormControl width={{ lg: '193px' }} marginLeft={3}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }} fontWeight={'bold'}> Section:</FormLabel>
          <Select value={formValues["grade-section"]} size={{ base: "sm", md: "md", lg: "lg" }} name="grade-section" onChange={handleChange}>
            <option value="">-Select-</option>
            {CONSTANTS.SECTION.map((option, index) => (
              <option key={option + index} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormControl>}


        <FormControl width={{ lg: '193px' }} marginLeft={3}>
          <FormLabel fontSize={{ base: 'sm', md: "md", lg: 'lg' }} fontWeight={'bold'}> Level:</FormLabel>
          <Select value={formValues["level-id"]} size={{ base: "sm", md: "md", lg: "lg" }} name="level-id" id="type" onChange={handleChange}>
            <option value="">-Select-</option>
            {levelList?.data?.map((option, index) => (
              <option
                key={option["level-value"] + index}
                value={option["level-id"]}
              >
                {option["level-name"]}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button m={2} mt={12} colorScheme='green' type="button" value="Go" onClick={() => handleGo(formValues)} pr={8} pl={8}>
          Go
        </Button>
        {showCSVButton && <Button m={2} mt={12} colorScheme='green' type="button" value="Go" onClick={() => downloadCSV()} pr={8} pl={8}>
          Download as CSV
        </Button>}

      </Flex>
    </Flex>




  );
}

export default ReportFilter;
