import { ASSESSMENT_API, INSTITUTE_API, SYLLABUS_API } from '../../services/apiRoutes';
import { Flex, FormControl, FormLabel, Heading, Select, useColorModeValue, useToast, Button, Text, Box, Container, Link } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { axiosSource, postCall } from '../../services/httpService';
import antony from '../../images/antony.png';

import AssessmentList from './AssessmentList'
import { AuthContext } from '../../App';
import { CONSTANTS } from '../../services/constants';
import { useNavigate } from 'react-router-dom';

function Assessment() {
  const toast = useToast();
  const [context] = useContext(AuthContext);
  const linkHoverColor = useColorModeValue('#0563b4', '#0563b4');
  const [assessList, SetAssessList] = useState({
    isLoading: false,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  // filter
  const [searchObj, setSearchObj] = useState({
    "institution-id": 0,
    "syllabus-type-id": 0,
  });
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: []
  });
  const [syllabusType, SetSyllabusType] = useState({
    isLoading: false,
    data: []
  });
  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);
  const [infoMsg, setInfoMsg] = useState(isAdmin ? 'Please select Institute' : 'No data found');
  const navigate = useNavigate();

  const getAssessList = useCallback(async (requestBody = {}, source) => {
    await postCall(ASSESSMENT_API.ASSESSMENT_SELECT, requestBody, {}, source)
      .then((res) => {
        if (res.status === 200) {
          SetAssessList((prev) => ({ ...prev, data: res?.data }));
          if ((!res?.data || (res?.data && res?.data.length === 0))) {
            setInfoMsg('No record to display');
          }
          if (isAdmin && !Number(searchObj['institution-id'])) {
            setInfoMsg('Please select Institute');
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message); // => the response payload
        }
      })
      .finally(() => SetAssessList((prev) => ({ ...prev, isLoading: false })));
  }, [SetAssessList, searchObj['institution-id']]);


  async function getInstituteList(source) {
    let body = {};
    if (!isAdmin) {
      body = { ...body, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true).then(res => {
      if (res.status === 200) {
        SetInstituteList(prev => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetInstituteList(prev => ({ ...prev, isLoading: false })));
  };

  const getSyllabusType = useCallback(async (source) => {
    const requestBody = {
      "institution-id": (searchObj['institution-id'] ? Number(searchObj['institution-id']) : 0)
    };
    await postCall(SYLLABUS_API.SYLLABUS_TYPE, requestBody, {}, source).then(res => {
      if (res.status === 200) {
        SetSyllabusType(prev => ({ ...prev, data: res?.data }));
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetSyllabusType(prev => ({ ...prev, isLoading: false })));
  }, [SetSyllabusType, searchObj['institution-id']]);

  useEffect(() => {
    if (!searchObj['syllabus-type-id']) return;
    SetAssessList((prev) => ({ ...prev, isLoading: true }));
    getAssessList({
      "institution-id": searchObj['institution-id'] ? Number(searchObj['institution-id']) : 0,
      "syllabus-type-id": searchObj['syllabus-type-id'] ? Number(searchObj['syllabus-type-id']) : 0,
      "user-id": isAdmin ? 0 : context?.user_data['lni-user-id']
    });
  }, [context?.user_data, getAssessList, searchObj]);

  useEffect(() => {
    const source_one = axiosSource();
    if (!isAdmin) {
      getAssessList({
        "user-id": context?.user_data['lni-user-id']
      }, source_one);
      return;
    };
    const source_two = axiosSource();
    getInstituteList(source_two);
    return () => {
      source_one.cancel();
      source_two.cancel();
    };
    // }, [context?.user_data['lni-user-id']]);
  }, []);

  useEffect(() => {
    if (!searchObj['institution-id']) return;
    SetSyllabusType(prev => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getSyllabusType(source);
  }, [searchObj['institution-id'], getSyllabusType]);

  /* send assessment */
  const handleSend = useCallback((assessmentValues) => {
    const body = {
      "institution-id": searchObj['institution-id'],
      "syllabus-id": assessmentValues['syllabus-id'],
      "complete-by": assessmentValues['Due date'],
      "assessment-id": assessmentValues['assessment-id']
    };
    setIsLoading(true);
    postCall(ASSESSMENT_API.ASSESSMENT_ADD_EDIT, body, null, null, false).then(function (result) {
      if (result.data.success != '') {
        toast({
          title: result.data.success || "",
          status: 'success',
          duration: CONSTANTS.DURATION
        });
      } else {
        toast({
          title: result.data.error || "",
          status: 'error',
          duration: CONSTANTS.DURATION
        });
      }
    })
      .catch((error) => {
        toast({
          title: error,
          status: 'error',
          duration: CONSTANTS.DURATION
        });
        console.error('Error:', error);
      }).finally(() => setIsLoading(false));
  }, [searchObj['institution-id'], toast]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setSearchObj(prev => ({ ...prev, [name]: value }));
  }, [setSearchObj]);

  return (
    <>
      {(isLoading || instituteList.isLoading || assessList.isLoading) && (
        <div className="loader flex-center">Loading...</div>
      )}
      <Flex mt={5} flexDirection={"column"} h="87vh" alignItems="center">
        <Heading fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}>
          {isAdmin ? "Assessment Management" : "Assessment"}
        </Heading>
        {isAdmin && (
          <Flex
            mt={2}
            align={"end"}
            width={{ base: "100%", md: "100%", lg: "45%" }}
            gap={2}
            direction={{ base: "column", md: "column", lg: "row" }}
          >
            <FormControl>
              <FormLabel fontSize={{ base: "sm", md: "md", lg: "lg" }}>
                Institute
              </FormLabel>
              <Select
                name="institution-id"
                id="institution-id"
                value={searchObj["institution-id"]}
                onChange={handleChange}
                size={{ base: "sm", md: "md", lg: "lg" }}
              >
                <option
                  key="select"
                  value={0}
                  fontSize={{ base: "sm", md: "md", lg: "lg" }}
                >
                  -Select-
                </option>
                {instituteList?.data?.map((option, index) => (
                  <option
                    key={
                      option["institution-id"] +
                      option["institution-name"] +
                      index
                    }
                    value={option["institution-id"]}
                    fontSize={{ base: "sm", md: "md", lg: "lg" }}
                  >
                    {option["institution-name"]}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel fontSize={{ base: "sm", md: "md", lg: "lg" }}>
                Syllabus Type
              </FormLabel>
              <Select
                name="syllabus-type-id"
                id="syllabus-type-id"
                value={searchObj["syllabus-type-id"]}
                onChange={handleChange}
                size={{ base: "sm", md: "md", lg: "lg" }}
              >
                <option key="select" value={0}>
                  -Select-
                </option>
                {syllabusType?.data?.map((option, index) => (
                  <option
                    key={
                      option["syllabus-type-id"] +
                      option["syllabus-type-name"] +
                      index
                    }
                    value={option["syllabus-type-id"]}
                  >
                    {option["syllabus-type-name"]}
                  </option>
                ))}
              </Select>
            </FormControl>
            {isAdmin && (
              <Button
                colorScheme="blue"
                type="button"
                onClick={() => {
                  navigate("/create-assessment");
                }}
                pr={14}
                pl={14}
              >
                Create Assessment
              </Button>
            )}
          </Flex>
        )}
        {assessList.data.length ? (
          <AssessmentList
            assessmentList={assessList}
            linkHoverColor={linkHoverColor}
            isAdmin={isAdmin}
            sendAssessment={handleSend}
            infoMsg={infoMsg}
          />
        ) : (
          <p style={{ padding: "2%", "font-weight": "bold" }}>{infoMsg}</p>
        )}
      </Flex>
      <Box className="footer" fontFamily={"Segoe UI"} width={"100%"}>
        <br />
        <hr />
        <center>
          <img
            style={{
              width: "350px",
              height: "69px",
              marginTop: "10px",
            }}
            src={antony}
            alt="logo"
          />
        </center>
        <Container as="footer" role="contentinfo" bottom={0}>
          <Box padding="10px 0px 0px 0px">
            <Flex align={"center"} justify={"center"} gap={2}>
              <Link
                fontSize="sm"
                href="../privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              |
              <Link
                fontSize="sm"
                href="../terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </Link>
            </Flex>
          </Box>
          <Text fontSize="14px" color="fg.subtle" textAlign={"center"}>
            <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam,
            Chennai – 600 092.
            <br />
            <Flex
              marginBottom={"5px"}
              justifyContent={"center"}
              flexWrap={"wrap"}
            >
              <Box width={"170px"}>
                <Text>
                  <b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;
                </Text>
              </Box>
              <Box width={"180px"}>
                <Text>
                  <b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;
                </Text>
              </Box>
              <Box width={"180px"}>
                <Text>
                  <b>Email:</b>&nbsp;A3minds@gmail.com
                </Text>
              </Box>
            </Flex>
          </Text>
          <Text fontSize="12px" color="fg.subtle" textAlign={"center"}>
            &copy; {new Date().getFullYear()} All rights reserved.
          </Text>
        </Container>
      </Box>
    </>
  );
}

export default Assessment
