import "../Site.css";

import {
  Box,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { coordMenu, guestMenuItems, menuItems, studentMenu, trainerMenu } from "../menuItems";

import { AuthContext } from "../App";
import { CONSTANTS } from "../services/constants";
import { LOGOUT } from "../services/apiRoutes";
import { postCall } from "../services/httpService";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const DesktopNav = () => {
  const linkColor = useColorModeValue("white", "white");
  const linkHoverColor = useColorModeValue("#0563b4", "#0563b4");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const navigate = useNavigate();
  const [context, setContext] = useContext(AuthContext);

  const getRoleBasedMenu = () => {
    if ((context?.auth_key || localStorage.getItem(CONSTANTS.AUTH_KEY))) {
      switch (context?.user_type) {
        case CONSTANTS.USER_TYPE.ADMINISTRATOR:
          return [...menuItems];
        case CONSTANTS.USER_TYPE.STUDENT:
          return [...studentMenu];
        case CONSTANTS.USER_TYPE.COORDINATOR:
          return [...coordMenu];
        case CONSTANTS.USER_TYPE.TRAINER:
          return [...trainerMenu];
        default:
          return [...menuItems];
      }
    } else {
      return [...guestMenuItems];
    }
  }

  const handleLogout = () => {
    setContext({ auth_key: null, user_type: null });
    localStorage.clear();
    postCall(LOGOUT).then(res => res);
  }

  return (
    <Stack direction={"row"} justifyContent={{ lg: 'right' }} spacing={{ lg: 10 }} marginBottom={'3.5'}>
      {getRoleBasedMenu().map((navItem, idx) => (
        <Box h={5} key={idx}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                fontSize={"xs"}

                position={"relative"}
                // className={
                //   (idx ? "header_menu " : "") +
                //   (navItem.submenu ? "submenu_icon " : "")
                // }
                p={2}
                href={navItem.url ?? "#"}
                onClick={(e) => {
                  e.preventDefault();
                  /* logout redirect */
                  if (navItem.title === "Logout") {
                    handleLogout()
                    setTimeout(() => {
                      navigate(navItem.url || "");
                    }, 1000);
                    return;
                  }
                  /* other path redirect */
                  navigate(navItem.url || "");
                }}
                fontWeight={800}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.title}
              </Link>
            </PopoverTrigger>

            {navItem.submenu && (
              <PopoverContent
                className="popover_submenu"
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
                maxW={"xl"}

              >
                <Stack>
                  {navItem.submenu.map((child, idx) => (
                    <DesktopSubNav key={idx} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

export const DesktopSubNav = ({ title, url, onClick }) => {
  const navigate = useNavigate();
  return (
    <Link
      href={url}
      onClick={(e) => {
        e.preventDefault();
        navigate(url || "");
        if (!onClick) return;
        onClick();
      }}
      role={"group"}
      display={"block"}
      // p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("#0563b4", "#0563b4") }}
    >

      <Stack direction={"row"} align={"flex-start"} fontSize={{ base: "sm", md: "md", lg: "xl" }} >
        <Box>
          <Text
            color={useColorModeValue("#000", "#fff")}
            transition={"all .3s ease"}
            fontWeight={500}
            textAlign={'left'}
          >
            {title}
          </Text>
        </Box>
      </Stack>
    </Link>
  );
};
