import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  UnorderedList,
  ListItem,
  Text,
  useTheme
} from '@chakra-ui/react'

import academic2 from '../../images/academic2.jpg';
import academic3 from '../../images/academic3.jpg';
import academic4 from '../../images/academic4.jpg';
import academicImg from '../../images/academic.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Academic() {
  const theme = useTheme();
  const { imageFlex, flexText, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>ACADEMIC AND CAREER READINESS</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="academic" /> */}
        <img src={academicImg} alt="aboutus" />
      </Box>
      <Box>
        <Flex mt={5} mb={5} alignItems={'center'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont}>
                Academic and Career Readiness is crucial for individuals to excel in their studies and prepare for their future aspirations. Our programs focus on developing critical thinking, time management, study skills, and building a strong foundation for academic and career success.<br />
                <b>Our Academic and Career Readiness Training Programs are broadly divided into three categories which covers the entire spectrum of life.</b>
              </Text>
            </Box>
            <Box m={5}>
              <Center>
                <Image src={academic2} w={'100%'} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText} >
                  Goal Setting and Action Planning for Academic Excellence
                </Text>
              </Flex>
              <Center>
                <Image src={academic3} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText} >
                  Personal Branding and Networking Skills
                </Text>
              </Flex>
              <Center>
                <Image src={academic4} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex}>
                <Text {...flexText} >
                  Entrepreneurial Skills
                </Text>
              </Flex>
            </Box>
            <Box m={5}>
              <Text mt={1} mb={4} fontSize={{ base: "md", md: "xl", lg: "2xl" }}>
                <b>ACADEMIC AND CAREER READINESS Training Programs has been designed to address the following Modules.</b>
              </Text>
              <UnorderedList fontSize={fFont}>
                <ListItem>Teaches to foundational study skills, time management techniques, and goal-setting
                  strategies to foster academic success.</ListItem>
                <ListItem>Further delve into critical thinking, problem-solving, and organizational skills, enhancing the
                  academic readiness.</ListItem>
                <ListItem>Focuses on advanced study skills, effective exam preparation, and academic planning to help
                  individuals achieve the academic goals. It prepares individuals for higher education by enhancing
                  study skills, research abilities and providing guidance on the higher studies.</ListItem>
                <ListItem>Guides career exploration, decision-making, and develop strategies for college admissions
                  and future career pathways. Refine the academic and career readiness through topics such
                  as personal branding, networking and building a strong college portfolio.</ListItem>
                <ListItem>Prepares to integrate and adjust to the advancement and changes brought by new
                  technologies.</ListItem>
                <ListItem>Broadly covers to a set of abilities and competencies that are considered essential for
                  success in the rapidly evolving modern world.</ListItem>
              </UnorderedList>
            </Box>
          </Container>

        </Flex>
      </Box>
    </>
  )
}