import React, { useContext, useEffect, useState } from 'react'
import { Container, Heading, Box, Text, Radio, Checkbox, Stack, Button, Flex, FormControl, FormErrorMessage, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosSource, postCall } from '../../services/httpService';
import { ASSESSMENT_API } from '../../services/apiRoutes';
import { CONSTANTS } from '../../services/constants';
import { AuthContext } from '../../App';

function Quiz() {
    const [context] = useContext(AuthContext);
    const { assessmentId } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    // const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [assessmentData, setAssessmentData] = useState({
        isLoading: false,
        data: []
    });
    const [optionValues, setOptionValues] = useState([]);
    const errObj = {
        errIdx: null,
        errMsg: ''
    };
    const [error, setError] = useState({...errObj});
    const [isLoading, setIsLoading] = useState(false);
    const [quizUUID, setQuizUUID] = useState('')

    async function getQuizDetails(id, source) {
        const requestBody = { 
            "assessment-id": id,
            "user-id": context?.user_data['lni-user-id']
        };
        await postCall(ASSESSMENT_API.ASSESSMENT_QUIZ, requestBody, {}, source).then(res => {
          if (res.status === 200) {
            if(res && res.data) {
                if(res.data.error) {
                    navigate("/assessment")
                    toast({
                        title: res.data.error || "",
                        status: 'error',
                        duration: CONSTANTS.DURATION
                      });
                } else {

                    const quizData = res?.data?.filter(item => {
                        item['option-list'].map(option => {
                            option['option-isAnswer'] = 0
                            return option;
                        });
                        return item;
                    })
                    setAssessmentData(prev => ({ ...prev, data: quizData }));
                    setQuizUUID(res.headers.get("Quiz-Uuid"));
                }
            }
          }
        }).catch((error) => {
          if (error) {
            console.log(error.message); // => the response payload 
            toast({
                title: "Server errror" || "",
                status: 'error',
                duration: CONSTANTS.DURATION
              });
          }
        }).finally(() => setAssessmentData(prev => ({ ...prev, isLoading: false })));
    };

    useEffect(() => {
        const source = axiosSource();
        if(!assessmentId) return;
        setAssessmentData(prev => ({ ...prev, isLoading: true }));
        getQuizDetails(assessmentId, source)
    }, [assessmentId]);

    // const handleAnswerSelect = (index, answer) => {
    //     setSelectedAnswers((prevAnswers) => {
    //     const newAnswers = [...prevAnswers];
    //     newAnswers[index] = answer;
    //     return newAnswers;
    //     });
    // };

    const onSelectAnswer = (value, index, option, assIdx, isRadio) => {
        /* If question type is Single choice - radio */
        if(isRadio) {
            let optionArray = [...[...assessmentData?.data][assIdx]['option-list']];
            optionArray.filter((item, idx) => {
                if(idx !== index) {
                    item['option-isAnswer'] = 0;
                } else {
                    item['option-isAnswer'] = 1;
                }
                return item;
            });
            assessmentData.data[assIdx]['option-list'] = optionArray;
            setAssessmentData(prev => ({...prev, data: assessmentData?.data}));
            // console.log("🚀 ~ optionArray:", optionArray);
            return;
        }
        /* If question type Multiple choice - check box */
        // let optionsArry = [...assessmentData?.data][assIdx]['option-list'][index];
        // let selectedOption = { ...optionsArry };
        // let updateAnswer = { ...selectedOption, 'option-isAnswer': value ? 1 : 0 }
        // assessmentData.data[assIdx]['option-list'][index] = updateAnswer;
        // setAssessmentData(prev => ({...prev, data: assessmentData?.data}));
    };

    const handleSubmit = () => {
        const answersResult = [];
        let isOptionsInValid = false;
        assessmentData.data.forEach((item, index) => {
            let optionList = [...item['option-list']];
            if(isOptionsInValid) return;
            if(!optionList || (optionList && optionList.length === 0)) return;
            if(optionList.every(item => item['option-isAnswer'] === 0) || !optionList.every(item => Object.keys(item).includes("option-isAnswer"))) {
                isOptionsInValid = true;
                setError({
                    errIdx: index,
                    errMsg: 'Please select the option'
                });
                return;
            } else {
                setError({...errObj});
            }
            isOptionsInValid = false;
            let answerObj = {
                "question-id": item['question-id'],
                "answers": []
            }
            optionList.forEach((option) => {
                answerObj.answers.push({
                    "option-id": option['option-id'],
                    "selected": option['option-isAnswer']
                })
            });
            answersResult.push(answerObj);
        });
        if(!isOptionsInValid) {
            setIsLoading(true);
            postCall(ASSESSMENT_API.ASSESSMENT_SCORE, {
                "assessment-id": assessmentId,
                "user-id": context?.user_data['lni-user-id'],
                "question-answers": answersResult,
                "quiz-uuid": quizUUID
            }).then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    if(res.data.error) {
                        toast({
                            title: res.data.error,
                            status: 'error',
                            duration: CONSTANTS.DURATION
                          });
                          return
                    } else {
                        toast({
                          title: `Answers submitted successfully!`,
                          status: 'success',
                          duration: CONSTANTS.DURATION
                        });
                      //   localStorage.setItem('score', res.data);
                        setTimeout(() => {
                          navigate('/thankyou');
                        }, 1000);
                      }
                    }
              }).catch((error) => {
                if (error) {
                  toast({
                    title: error.message || 'Something went wrong',
                    status: 'error',
                    duration: CONSTANTS.DURATION
                  })
                }
              }).finally(() => {
                setIsLoading(false);
              });
        }
    };

    return (
        <>
            {(assessmentData.isLoading || isLoading) && (<div className="loader flex-center">Loading...</div>)}
            <Flex h="70vh" flexDirection={'column'} mt={5} mb={5} alignItems={'center'}>
                <Heading textAlign={'center'}>Assessment</Heading>
                <Stack maxHeight="650px" overflowY={'scroll'}>
                {/* <Container maxW={'3xl'}> */}
                {(assessmentData.data && assessmentData.data.length > 0) ? <>
                    <form>
                        <Box p={4}>
                            {assessmentData?.data?.map((question, index) => (
                                <FormControl key={question['question-text'] + question['question-id']} isInvalid={(index === error.errIdx) ? error.errMsg: null}>
                                    <Box key={index} mb={4}>
                                        <Text fontSize="xl">{question['question-text']}</Text>
                                        <Stack spacing={2}>
                                            {question['option-list']?.map((option, answerIndex) => (
                                                <Radio
                                                    key={answerIndex}
                                                    name='radioQuestion'
                                                    value={option['option-id']}
                                                    isChecked={option['option-isAnswer']}
                                                    onChange={(e) => onSelectAnswer(e.target.value, answerIndex, option, index, true)}
                                                >
                                                    {option['option-text']}
                                                </Radio>
                                            ))}
                                        </Stack>
                                        {/* {question['question-type'] === CONSTANTS.QUESTION_TYPE[0] ? (
                                            <Stack spacing={2}>
                                                {question['option-list']?.map((option, answerIndex) => (
                                                    <Radio
                                                        key={answerIndex}
                                                        name='radioQuestion'
                                                        value={option['option-id']}
                                                        isChecked={option['option-isAnswer']}
                                                        onChange={(e) => onSelectAnswer(e.target.value, answerIndex, option, index, true)}
                                                    >
                                                        {option['option-text']}
                                                    </Radio>
                                                ))}
                                            </Stack>
                                        ) : (
                                            <Stack spacing={2}>
                                                {question['option-list']?.map((option, answerIndex) => (
                                                    <Checkbox
                                                        name={"options" + answerIndex}
                                                        key={"options" + answerIndex}
                                                        isChecked={option['option-isAnswer']}
                                                        onChange={(e) => onSelectAnswer(e.target.checked, answerIndex, option, index, false)}
                                                    >
                                                        {option['option-text']}
                                                    </Checkbox>
                                                ))}
                                            </Stack>
                                        )} */}
                                    </Box>
                                    {(index === error.errIdx) && <FormErrorMessage>{error.errMsg}</FormErrorMessage>}
                                </FormControl>
                            ))}
                            <Button isLoading={isLoading} onClick={handleSubmit} disabled={optionValues.length !== assessmentData.data.length}>
                                Submit
                            </Button>
                        </Box>
                    </form></>: <Heading textAlign={'center'}>No Assessments Available</Heading>}
                {/* </Container> */}
                </Stack>
            </Flex>
        </>
    )
}

export default Quiz
