// import React, { useEffect, useState } from 'react'
// // import { useNavigate } from 'react-router-dom';

// const react_app_url = process.env.REACT_APP_HOST;

// function Login() {
//     // const navigate = useNavigate();
//     // const changeRoute = (route) => {
//     //     navigate(route);
//     // }
//     const [isLoading, setIsLoading] = useState(true);
//     const handleLogin = () => {
//         window.open(`https://auth59.com/nop?ccode=9c3cfe7f-78f3-43d1-a4f0-a4ddae7c3863&r=${react_app_url}`,"_self")
//     }
//     useEffect(() => {
//         setTimeout(() => {
//             setIsLoading(false);
//             handleLogin();
//         }, 2000);
//     }, []);

//     return (
//         <div className='container' style={{height: '100vh'}}>
//             {isLoading && <div className="loader flex-center">Redirecting to Login...</div>}
//             {/* <button onClick={handleLogin}>Login</button> */}
//             {/* <button onClick={() => changeRoute('/register')}>Register</button> */}
//         </div>
//     )
// }

// export default Login
import "./Login.css"

import React, { useState } from 'react';
import { axiosSource, postCall } from "../../services/httpService";
import { handleToast, toastByStatus, toastErrorOnly } from "../../services/utils";

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";


function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      console.log('Email:', email);

      let data = JSON.stringify({
        "user_id": email
      });
      localStorage.setItem('email', email)

      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: 'https://a3minds.in/api/user/generate_otp',
      //   headers: { 
      //     'Content-Type': 'application/json'
      //   },
      //   data : data,
      //   params:new URLSearchParams({})
      // };
      // await axios.request(config)
      await postCall('/user/generate_otp', data, {})
        .then((response) => {
          console.log(JSON.stringify(response.data));

          if (response.status == 200) {
            //  toastErrorOnly('Email is not recognized. Try with a different email')
            response.data.status == 1 ? alert('Email is not recognized. Try with a different email') : navigate('/otppage')

          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setEmailError('Please enter a valid email address');
    }
  };

  return (
    <Box>
      <div className="login-container">
        <Box width={{ base: '70%', "md": '35%', "lg": '23%' }}>
          <form onSubmit={handleSubmit} width={{ base: '70%', "md": '35%', "lg": '23%' }}  >
            <div >
              <label>Email:</label>
              <input
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <span className="error">{emailError}</span>}
            </div>
            {/* <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div> */}
            <button className="otp-button" type="submit">Send OTP</button>
          </form>
        </Box>
      </div>
    </Box>
  );
}

export default Login;
