import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  Text,
  UnorderedList,
  ListItem,
  extendTheme,
  useTheme
} from '@chakra-ui/react'

import aboutus2 from '../../images/Aboutus2.png';
import aboutus3 from '../../images/Aboutus3.png';
import aboutus4 from '../../images/Aboutus4.jpg';
import aboutus5 from '../../images/Aboutus5.jpg';
import aboutus6 from '../../images/Aboutus6.jpg';
import aboutus7 from '../../images/Aboutus7.jpg';
import aboutus_image from '../../images/Aboutus.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function AboutUs() {
  const theme = useTheme();
  const { aFont, dFont, fFont, topic, aboutStyles } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>ABOUT US</b></Text>
      </Flex>
      <Box>
        <Box mt={'5'} position={'relative'}>
          {/* <Box className='aboutus' /> */}
          <img src={aboutus_image} alt="aboutus" />
        </Box>
        <Flex mt={5} mb={5} alignItems={'center'} flexDirection={'column'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont} >
                Welcome to A3 Minds! We are a company driven by a team of passionate individuals who are experts in their respective domains, backed by the best minds from both academia and industry. Our mission is to empower individuals with essential non-academic skills and foster their personal growth, leadership abilities, and soft skills.
              </Text>
            </Box>
          </Container>

          <Box>
            <Text {...aboutStyles.topic} fontSize={aFont}>VISION</Text>
            <Flex {...aboutStyles.visionFlex}>
              <Flex {...aboutStyles.visionContainer}>
                <Image src={aboutus2} alt='Vision' w={'350px'} />
              </Flex>
              <Box display={'flex'} w={{ lg: '90%' }} pt={6} pb={6}>
                <Text css={{ display: "flex", flexWrap: "wrap", alignContent: "center" }} color={'white'} className="vision" my={0} fontSize={aFont}>
                  <i>Our Vision is to empower & train Individuals with essential skills and foster their personal growth, leadership abilities and soft skills.</i>
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box>
            <Text mt={20} {...aboutStyles.topic} fontSize={aFont}>OUR MISSION</Text>
            <Flex {...aboutStyles.lists}>
              <Box {...aboutStyles.missionText}>
                <UnorderedList color={'white'} className="mission" fontSize={dFont}>
                  <ListItem>Our training approach is rooted with scientifically tested and proven methods that are practically applicable to real life situation.</ListItem>
                  <ListItem>Our training modules are meticulously framed at different levels based on extensive research with latest trends.</ListItem>
                  <ListItem>We deliver top-quality training through a team of highly qualified and talented professionals who are committed to providing personalized attention each individual.</ListItem>
                  <ListItem>Our Curriculum designed to cover a wide range of topics essential for personal growth and success. We continuously refine and update our curriculum to ensure that it remains relevant and impactful. </ListItem>
                </UnorderedList>

              </Box>
              <Center {...aboutStyles.imageCenter} >
                <Image src={aboutus3} alt='Vision' w={'350px'} />
              </Center>
            </Flex>
          </Box>
          <Container maxW={'4xl'}>
            <Box>
              <Text {...aboutStyles.topic} fontSize={aFont}>OUR STRATEGIES</Text>
              <Image mt={10} src={aboutus4} w={'750px'} />
              <Text {...aboutStyles.strategiesText} fontSize={fFont}>
                At A3 Minds, we believe in the power of evidence-based learning. Our training approach is rooted in scientifically tested and proven methods that have shown to be highly effective in enhancing individuals' abilities and overall development. We strive to provide a dynamic learning experience that is engaging, practical, and applicable to real-life situations.
              </Text>
              <Image mt={24} src={aboutus5} />
              <Text {...aboutStyles.strategiesText} fontSize={fFont}>
                We understand that each student is unique, with varying needs and aspirations. That's why our training modules are meticulously framed at different levels, keeping in mind the specific requirements of individuals at various stages of their academic journey. Our curriculum is carefully crafted based on extensive research, ensuring that it aligns with the latest industry trends and best practices.
              </Text>
              <Image mt={24} src={aboutus6} />
              <Text {...aboutStyles.strategiesText} fontSize={fFont}>
                To deliver top-quality training, we have assembled a team of talented and dedicated professionals who possess both expertise in their fields and a genuine passion for student development. Our instructors are highly qualified and experienced, bringing a wealth of knowledge and practical insights to every training session. They are committed to providing personalized attention and guidance to nurture the potential within each student.
              </Text>
              <Image mt={24} src={aboutus7} />
              <Text {...aboutStyles.strategiesText} fontSize={fFont}>
                At A3 Minds, we take immense pride in our curriculum, which has been thoughtfully designed to cover a wide range of topics essential for individuals' success and personal growth. We continuously refine and update our curriculum to ensure that it remains relevant and impactful. We also acknowledge the invaluable contributions of the people behind our curriculum development and delivery, who work tirelessly to create an enriching learning experience for our individuals.<br />

              </Text>



            </Box>

          </Container>
        </Flex>
        <Box width={'100%'} >
          <Flex bg={'#4c4c47'} >
            <Box>
              <Text color={'white'} fontSize={dFont} className="gray-block" mt={20} mb={20}>
                Join us at A3 Minds and embark on a transformative journey of self-discovery, skill enhancement, and personal growth. We are committed to unlocking your true potential and equipping you with the necessary skills to thrive in the ever-evolving world. Together, let's unleash the power of your mind and shape a brighter future!
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}