
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
  } from '@chakra-ui/react'
  
  function BasicUsage({ isOpen, onClose, children}) {

  return (
    <>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={'white'}>Skipped Duplicate Records</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box color={'red'}>
              This is a list of records that were skipped because they already exist in the database. Please review
            </Box>
            {children}
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Okay
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default BasicUsage;