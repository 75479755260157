import React, { memo } from "react";
import "./institutesStyle.css";
// import { Link } from "react-router-dom";
// import { Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, Link } from "@chakra-ui/react";
import { useColorModeValue, Link, useBreakpointValue, Flex, Box, Container, Text } from "@chakra-ui/react"
import { Table, Thead, Tbody, Tr, Th, Td } from "../../table";
import antony from "../../images/antony.png"
import { useNavigate } from "react-router-dom";
function InstituteList({ studentList, changeStatus = () => { }, modal = false, infoMsg }) {
    const linkHoverColor = useColorModeValue('#0563b4', '#0563b4');
    const popoverContentBgColor = useColorModeValue('gray.200', 'gray.800');
    const variant = useBreakpointValue({ base: "striped", md: "striped", lg: "" });
    const colorScheme = useBreakpointValue({ base: "gray", md: "gray", lg: "" });
    const navigate = useNavigate();
    return (
        <>
            <Flex className="lni-table-section" mt={5} maxHeight="600px" >

                <Table variant={variant} colorScheme={colorScheme} mt={5} marginBottom={'20%'}
                >

                    <Thead backgroundColor={popoverContentBgColor} position={'sticky'} top={0}>
                        <Tr>
                            <Th fontSize={'2xs'}>Student Name</Th>
                            {!modal && <Th fontSize={'2xs'}>Contact Number</Th>}
                            <Th fontSize={'2xs'}>Email Address</Th>
                            {!modal && <Th fontSize={'2xs'}>Grade/Department</Th>}
                            {!modal && <Th fontSize={'2xs'}>Section/Year</Th>}
                            {!modal && <Th fontSize={'2xs'}>Action</Th>}
                        </Tr>
                    </Thead>
                    <Tbody >
                        {(studentList?.data && studentList?.data.length > 0) ? studentList.data.map((item, index) => (
                            <Tr key={item['student-name'] + index}>
                                <Td fontSize={'2xs'} >{!modal ? item['student-name'] : (`${item['fname']} ${item['lname']}`) || '-'}</Td>
                                {!modal && <Td fontSize={'2xs'} >{item.phone || '-'}</Td>}
                                <Td fontSize={'2xs'} >{item.email || '-'}</Td>
                                {!modal && <Td fontSize={'2xs'} >{item.grade || '-'}</Td>}
                                {!modal && <Td fontSize={'2xs'} >{item.section || '-'}</Td>}
                                {!modal && <Td fontSize={'2xs'} className="action_flex">
                                    <Link _hover={{ color: linkHoverColor }} onClick={() => { navigate(`/edit-user/${item['lni-user-id']}`) }} >Edit</Link>
                                    <Link _hover={{ color: linkHoverColor }} href="javascript:void(0)" onClick={() => changeStatus(item)}>{item['active'] ? 'InActive' : 'Active'}</Link>
                                </Td>}


                            </Tr>
                        )) : (studentList.isLoading ? null : <Tr><Td textAlign="center" fontSize={'sm'} colSpan={'5'}>{infoMsg}</Td></Tr>)}
                    </Tbody>

                </Table>
            </Flex>

        </>
    )
}

export default memo(InstituteList);