export const menuItems = [
    {
        title: 'Managements',
        url: '#',
        submenu: [
          {
            title: 'User',
            url: '/users',
          },
          {
            title: 'Institute',
            url: '/institute',
          },
          {
            title: 'Syllabus',
            url: '/syllabus',
          },
          {
            title: 'Questionaire',
            url: '/questionaire',
          },
          {
            title: 'Assessment',
            url: '/assessment',
          }
        ]
    },
    {
      title: 'Reports',
      url: '/reports',
    },
    {
        title: 'Logout',
        url: '/home',
    }
];

export const studentMenu = [
  {
    title: 'Home',
    url: '/student',
  },
  {
    title: 'Assessment',
    url: '/assessment',
  },
  {
      title: 'Logout',
      url: '/home',
  }
];

export const coordMenu = [
  {
    title: 'Home',
    url: '/coord',
  },
  {
      title: 'Logout',
      url: '/home',
  }
];

export const trainerMenu = [
  {
      title: 'Home',
      url: '/trainer',
  },
  {
    title: 'Managements',
    url: '#',
    submenu: [
      {
        title: 'Syllabus',
        url: '/syllabus',
      }
    ]
  },
  {
      title: 'Logout',
      url: '/home',
  }
];

export const guestMenuItems = [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: "About Us",
    url: "/aboutus",
  },
  {
    title: "Our Training Modules",
    url: "/program",
  },
  {
    title: "Programs",
    url: "#",
    submenu: [
      {
        title: "Personal Development",
        url: "/personaldev",
      },
      {
        title: "Communication & Interpersonal Skills",
        url: "/communication",
      },
      {
        title: "Adaptation to technology and 21st-century skills",
        url: "/adaptation",
      },
      {
        title: "Well - Being & Mental Health",
        url: "/wellbeing",
      },
      {
        title: "Academic & Career Readiness",
        url: "/academic",
      },
    ],
  },
  {
    title: "Pedagogy",
    url: "/pedagogy",
  },
  {
    title: "Contact Us",
    url: "/contactus",
  },
  {
    title: "Login",
    url: "/login",
  }
  // },
  // {
  //   title: "Register",
  //   url: "/register",
  // },
];