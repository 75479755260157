import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  UnorderedList,
  ListItem,
  Text,
  useTheme
} from '@chakra-ui/react'

import personal2 from '../../images/personal2.jpg';
import personal3 from '../../images/personal_3.jpg';
import personal4 from '../../images/personal4.jpg';
import personal5 from '../../images/personal5.jpg';
import personal from '../../images/personal.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Personaldev() {
  const theme = useTheme();
  const { pedagogyStyles, personalStyles, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>PERSONAL DEVELOPMENT</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="personal" /> */}
        <img src={personal} alt="personal" />
      </Box>
      <Box>
        <Flex mt={5} mb={5} alignItems={'center'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont}>
                At A3 Minds, we believe that personal development plays a crucial role in the holistic growth of individuals. Our Personal Development programs are designed to equip school, college students and individuals with essential life skills and empower them to thrive academically and personally.<br />
                <b>Our Personal Development Training Programs are broadly divided into four categories which covers the entire spectrum of life.</b>
              </Text>
            </Box>
            <Box m={5}>
              <Center>
                <Image src={personal2} w={'100%'} mb={1} />
              </Center>
              <Flex {...personalStyles.imageFlex}>
                <Text {...personalStyles.flexText} >
                  Enriching Core Values, Ambition and Vision
                </Text>
              </Flex>

              <Center>
                <Image src={personal3} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...personalStyles.imageFlex}  >
                <Text {...personalStyles.flexText}  >
                  Time Management and Productivity Strategies
                </Text>
              </Flex>
              <Center>
                <Image src={personal4} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...personalStyles.imageFlex}  >
                <Text {...personalStyles.flexText}  >
                  Goal Setting and Growth Mindset
                </Text>
              </Flex>



              <Center>
                <Image src={personal5} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...personalStyles.imageFlex}>
                <Text {...personalStyles.flexText} >
                  Self-Care and Elevating Character
                </Text>
              </Flex>

              <Text mt={5} mb={5} fontSize={{ base: "md", md: "xl", lg: "2xl" }}>
                PERSONAL DEVELOPMENT Training Programs has been designed to address the following Modules.
              </Text>
              <UnorderedList fontSize={fFont}>
                <ListItem>Personal development skills focus on laying the foundation of effective communication, time
                  management, and goal setting.</ListItem>
                <ListItem>Builds upon the foundational skills learned exploring critical thinking, problem-solving, and
                  creativity and innovation.</ListItem>
                <ListItem>Delves deeper into personal development areas, including emotional intelligence, resilience
                  building, and building healthy relationships.</ListItem>
                <ListItem>Further enhances the personal development skills by diving into topics like leadership
                  development, self-awareness and conflict resolution.</ListItem>
                <ListItem>Trains to have good fundamental beliefs and behaviour in order to have a clean vision and
                  ambition which will lead to growth and progress.</ListItem>
                <ListItem>Improves the Physical, Mental and Emotional well – being by having proper Self – Care and
                  Discipline to overcome obstacles and work towards the goals with greater confidence.</ListItem>
              </UnorderedList>
            </Box>
          </Container>

        </Flex>
      </Box>
    </>
  )
}