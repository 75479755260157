import { Flex, Heading, useToast, Container, Text, Box } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { axiosSource, postCall } from "../../services/httpService";
import { handleToast, toastByStatus, toastErrorOnly } from "../../services/utils";

import { AuthContext } from "../../App";
import { CONSTANTS } from '../../services/constants'
import { USER_API } from "../../services/apiRoutes";
import UserFilter from "./UserFilter";
import UserList from "./UserList";
import { useNavigate } from "react-router-dom";
import { userSearch } from "../../services/userService";
import antony from '../../images/antony.png';

export function User() {
    const toast = useToast();
    const [context] = useContext(AuthContext);
    const navigate = useNavigate();
    const [isSubmitted, setSubmitted] = useState(false);
    const [searchObj, setSearchObj] = useState(userSearch);
    const [userList, SetUserList] = useState({
        isLoading: false,
        data: []
    });

    const getUserList = useCallback(async (search = {}, source) => {

        await postCall(USER_API.USER, {
            "search-usertype": search?.type,
            "search-global": search.searchValue
        }, {}, source).then(res => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                SetUserList(prev => ({ ...prev, data: res?.data }));
            }
        }).catch((error) => toastErrorOnly(error)).finally(() => SetUserList(prev => ({ ...prev, isLoading: false })));
    }, [SetUserList, toast]);
    console.log('🚀 ~ file: User.js:42 ~ User ~ SetUserList:', userList)

    const getSet = useCallback((searchObj, submitted = false) => {
        SetUserList(prev => ({ ...prev, isLoading: true }));
        getUserList(searchObj);
        if (submitted) {
            setSubmitted(submitted)
        }
    }, [getUserList]);

    useEffect(() => {
        SetUserList(prev => ({ ...prev, isLoading: true }));
        const source = axiosSource();
        getUserList({}, source);
        return () => {
            source.cancel()
        }
    }, [getUserList]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setSearchObj(prev => ({ ...prev, [name]: value }));
    }, [setSearchObj]);

    const handleOnGO = useCallback(() => {
        if (!searchObj.searchValue && !searchObj.type) {
            handleToast(toast, 'Enter username or select type to Go', 'warning');
            return;
        };
        getSet(searchObj, true);
    }, [searchObj, getSet, toast]);

    const handleReset = useCallback(() => {
        setSearchObj(userSearch);
        getSet(userSearch);
    }, [getSet]);

    const goToCreate = useCallback(() => {
        navigate('/create-user');
    }, [navigate]);

    const deleteUser = useCallback(async (userId, isActive) => {
        const requestBody = { "lni-user-id": userId }
        const url = isActive ? USER_API.USER_DELETE : USER_API.USER_UN_DELETE;
        await postCall(url, requestBody).then(res => {
            if (res.status === 200) {
                toastByStatus(
                    toast,
                    res?.data?.success,
                    res?.data?.error,
                    res?.data?.success,
                    res?.data?.error
                )
                getSet(searchObj);
            }
        }).catch((error) => toastErrorOnly(error)).finally(() => SetUserList(prev => ({ ...prev, isLoading: true })));
    }, [toast, getSet, searchObj]);

    const handleStatusChange = useCallback((e) => {
        SetUserList(prev => ({ ...prev, isLoading: true }));
        deleteUser(e['lni-user-id'], e['active']);
    }, [deleteUser]);
    console.log(!userList.isLoading)

    return (
        <>
            {/* {!userList.data.length  && (<div className="loader flex-center">Loading...</div>)} */}
            {localStorage.getItem(CONSTANTS.AUTH_KEY) && !userList.isLoading && <Flex mt={5} mb={5} flexDirection={'column'} h="87vh" alignItems="center">
                <Heading fontSize={{ base: 'xl', md: "2xl", lg: '3xl' }}>User Management</Heading>
                <UserFilter submitted={isSubmitted} searchObj={searchObj} handleChange={handleChange} onGo={handleOnGO} onCreate={goToCreate} onReset={handleReset} />
                <UserList userList={userList} changeStatus={handleStatusChange} />

            </Flex>}

        </>
    )
}