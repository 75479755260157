import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react'
import QuestionaireList from './QuestionaireList'
import { axiosSource, postCall } from '../../services/httpService';
import { INSTITUTE_API, QUESTIONAIRE_API, SYLLABUS_API } from '../../services/apiRoutes';
import QuestionaireFilter from './QuestionaireFilter';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Text, Box, Container } from '@chakra-ui/react';
import { CONSTANTS } from '../../services/constants';
import { AuthContext } from '../../App';
import antony from '../../images/antony.png';
import { useLocation } from 'react-router-dom';

function Questionaire() {
  let { state } = useLocation();
  const navigate = useNavigate();
  const [context] = useContext(AuthContext);
  const [searchObj, setSearchObj] = useState({
    "institution-id": 0,
    "syllabus-type-id": 0,
  });
  console.log("questionaire state : ", state)



  const [questionaireList, SetQuestionaireList] = useState({
    isLoading: false,
    data: []
  });
  const [instituteList, SetInstituteList] = useState({
    isLoading: false,
    data: []
  });
  const [syllabusType, SetSyllabusType] = useState({
    isLoading: false,
    data: []
  });
  const [syllabusList, SetSyllabusList] = useState({
    isLoading: false,
    data: []
  });
  const [gradeLevel, setGradeLevel] = useState();
  const [infoMsg, setInfoMsg] = useState('Please select Institute');
  const isAdmin = useMemo(() => (context?.user_type === CONSTANTS.USER_TYPE.SUPER_ADMIN || context?.user_type === CONSTANTS.USER_TYPE.ADMINISTRATOR), [context]);

  const getQuestionaireList = useCallback(async (search = {}, source) => {
    const requestBody = {
      "institution-id": Number(searchObj['institution-id']),
      // "question-set-id": 0,
      "syllabus-type-id": Number(searchObj['syllabus-id'])
    };
    await postCall(QUESTIONAIRE_API.QUESTIONAIRE_SELECT, requestBody, {}, source).then(res => {
      if (!res?.data || (res?.data && res?.data.length === 0)) {
        setInfoMsg('No record to display')
      }
      SetQuestionaireList(prev => ({ ...prev, data: res?.data }));
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetQuestionaireList(prev => ({ ...prev, isLoading: false })));
  }, [searchObj, SetQuestionaireList]);

  const getLevelandSyllabus = useCallback(async (id) => {
    const requestBody = {
      "syllabus-type-id": (id ? Number(id) : 0)
    };
    await postCall(SYLLABUS_API.SYLLABUS_LIST, requestBody).then(res => {
      // console.log('res', res.data);
      setGradeLevel({ 'grade-name': res.data['grade-name'], 'level-name': res.data['level-name'] })
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    });
  }, []);


  async function getInstituteList(source) {
    let body = {};
    if (!isAdmin) {
      body = { ...body, "user-id": context?.user_data['lni-user-id'] }
    }
    await postCall((isAdmin ? INSTITUTE_API.INSTITUTE_ALL : INSTITUTE_API.INSTITUTE), body, null, source, true).then(res => {
      if (res.status === 200) {
        SetInstituteList(prev => ({ ...prev, data: res?.data?.error ? [] : res?.data }));
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetInstituteList(prev => ({ ...prev, isLoading: false })));
  };

  const getSyllabusList = useCallback(async (source) => {
    const requestBody = {
      "institution-id": (searchObj['institution-id'] ? Number(searchObj['institution-id']) : 0)
    };
    await postCall(SYLLABUS_API.SYLLABUS, requestBody, {}, source).then(res => {
      if (res.status === 200) {
        SetSyllabusList(prev => ({ ...prev, data: res?.data }));
      }
    }).catch((error) => {
      if (error) {
        console.log(error.message); // => the response payload 
      }
    })
      .finally(() => SetSyllabusList(prev => ({ ...prev, isLoading: false })));
  }, [SetSyllabusList, searchObj['institution-id']]);

  async function getSyllabusType(source) {
    await postCall(SYLLABUS_API.SYLLABUS_TYPE, null, null, source, true).then(res => {
      if (res.status === 200) {
        SetSyllabusType(prev => ({ ...prev, data: res?.data }));
      }
    }).finally(() => SetSyllabusType(prev => ({ ...prev, isLoading: false })));
  };



  useEffect(() => {
    SetInstituteList(prev => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getInstituteList(source);
    const source3 = axiosSource();
    getSyllabusType({}, source3)
    if (state?.data?.["institution-id"]) {
      setSearchObj(state.data)

    }
    return () => {
      source.cancel()
      source3.cancel()
    }


  }, []);



  // useEffect(() => {
  //   if (!searchObj['institution-id']) return;
  //   SetSyllabusList(prev => ({ ...prev, isLoading: true }));
  //   const source = axiosSource();
  //   getSyllabusList(source);
  // }, [searchObj['institution-id'], getSyllabusList]);
  useEffect(() => {
    if (!searchObj['institution-id']) return;
    SetSyllabusType(prev => ({ ...prev, isLoading: true }));
    const source = axiosSource();
    getSyllabusType(source);
  }, [searchObj['institution-id']]);

  useEffect(() => {
    if (searchObj['institution-id'] && state) {
      handleOnSearch()
    }
  }, [searchObj])


  const handleStatusChange = (e) => { };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    console.log("name : ", name);
    console.log("value : ", value);

    setSearchObj(prev => ({ ...prev, [name]: value }));
  }, [setSearchObj]);

  const handleOnSearch = useCallback(() => {
    if (!Number(searchObj['institution-id']) || !Number(searchObj['syllabus-id'])) {
      SetQuestionaireList({ data: [], isLoading: false })
      return;
    }
    SetQuestionaireList(prev => ({ ...prev, isLoading: true }));
    // getLevelandSyllabus(searchObj['syllabus-id']);
    getQuestionaireList({}, null);
  }, [getLevelandSyllabus, getQuestionaireList, searchObj]);

  const goToCreate = useCallback(() => {
    navigate("/create-questionaire");
  }, [navigate]);

  const goToUpload = useCallback(() => {
    navigate("/questionaire-upload");
  }, [navigate]);

  return (
    <>
      {(instituteList.isLoading || syllabusList.isLoading || questionaireList.isLoading) && (
        <div className="loader flex-center">Loading...</div>
      )}
      <Flex mt={5} flexDirection={'column'} h="87vh" alignItems="center">
        <Heading className="user-heading" textAlign={"center"} fontSize={{ base: 'xl', md: "2xl", lg: '3xl' }}>Questionnaire Management</Heading>
        <QuestionaireFilter
          searchObj={searchObj}
          instituteList={instituteList}
          syllabusType={syllabusType}
          syllabusList={syllabusList}
          handleChange={handleChange}
          onGo={handleOnSearch}
          onCreate={goToCreate}
          onUpload={goToUpload}
        />
        {questionaireList.data.length ? <QuestionaireList questionaireList={questionaireList} changeStatus={handleStatusChange} gradeLevel={gradeLevel} changeList={SetQuestionaireList} questionaireApidata={searchObj} /> : <p style={{"padding": "2%", "font-weight": "bold"}}>{infoMsg}</p>}
      </Flex>
      <Box className="footer" fontFamily={'Segoe UI'} width={'100%'}>
        <br />
        <hr />
        <center>
          <img style={{
            width: '350px',
            height: '69px',
            marginTop: '10px'
          }} src={antony} alt="logo" />
        </center>
        <Container as="footer" role="contentinfo" bottom={0}>
          <Text fontSize="14px" color="fg.subtle" textAlign={'center'}>
            <b>A3 Minds</b>, No.38 A, AVM Avenue, 1St Street, Virugambakkam, Chennai – 600 092.
            <br />
            <Flex marginBottom={'5px'} justifyContent={'center'} flexWrap={'wrap'}>
              <Box width={'170px'}>
                <Text><b>Phone:</b>&nbsp;+91 44 47853984,&nbsp;&nbsp;</Text>
              </Box>
              <Box width={'180px'}>
                <Text><b>Mobile:</b>&nbsp;+91 99620 55455,&nbsp;&nbsp;</Text>
              </Box>
              <Box width={'180px'}>
                <Text><b>Email:</b>&nbsp;A3minds@gmail.com</Text>
              </Box>
            </Flex>
          </Text>
          <Text fontSize="12px" color="fg.subtle" textAlign={'center'}>
            &copy; {new Date().getFullYear()} All rights reserved.
          </Text>
        </Container>
      </Box>
    </>
  )
}

export default Questionaire
