import axios from "axios";
import { CONSTANTS } from "./constants";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const ccode = process.env.REACT_APP_CCODE;

export const axiosSource = () => axios.CancelToken.source();

export function getCall(url, params = {}, cancelToken) {
  let config = {};
  config["headers"] = { "Content-Type": "application/json" };
  config["params"] = new URLSearchParams(params);
  return axios.get(baseUrl + url, { ...config, cancelToken: cancelToken?.token });
}

export function postCall(url, data, params = {}, cancelToken, contentType = true) {
  let config = {};
  config["headers"] = { 'ccode': ccode, 'auth-key': localStorage.getItem(CONSTANTS.AUTH_KEY), 'debug-token': CONSTANTS.DEBUG_TOKEN };
  if (contentType) {
    config["headers"] = { ...config["headers"], "Content-Type": "application/json" }
  }
  config["params"] = params ? new URLSearchParams(params) : null;
  const configCopy = cancelToken ? { ...config, cancelToken: cancelToken?.token || null } : { ...config };
  return axios.post(baseUrl + url, data, configCopy);
}