import { postCall } from "../services/httpService";
import { USER_API } from "../services/apiRoutes";
import { toastByStatus } from "./utils";

const onPaymentSuccess = async (requestBody, setModalVisible) => {
  try {
    const response = await postCall(USER_API.RZPREGISTER, requestBody);
    console.log("Payment Success:", response.data);
    const lookupResponse = await postCall(USER_API.PAYMENTCONFIRM, requestBody);
    console.log(lookupResponse.data);
    if (lookupResponse.data) {
      setModalVisible(true);
    }
  } catch (error) {
    console.error("Error in payment:", error);
  }
};


export const initializeGpay = async (body, toast, setModalVisible) => {
  try {
    const timestamp = Date.now();
    const date = new Date(timestamp);

    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    console.log(formattedDate);
    const amount = 100;

    const requestBody = {
      ...body,
      amount: amount * 100,
      trans_status: "success",
      trans_date: formattedDate,
    };
    onPaymentSuccess(requestBody, setModalVisible);

  } catch (error) {

    toastByStatus(
      toast,
      "",
      error.response.data.error,
      "",
      error.response.data.error
    );

    window.location.href = "/register";
  }
};

export const initializeRazorpay = async (body, toast, setModalVisible) => {
  try {
    const res = await postCall(USER_API.PAYREGISTER, body);
    console.log("RAZORPAY");
    console.log("body");
    console.log(body);
    console.log("res");
    console.log(res);
    const loadRazorPay = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
      });
    };

    const scriptLoaded = await loadRazorPay();
    if (!scriptLoaded) {
      alert("Razorpay failed to load. Sorry for the inconvenience");
      return;
    }

    const amount = res.data.order.amount;
    console.log("order");
    console.log(res.data.order);
    console.log("Amount");
    console.log(amount);
    let options = {
      //key: process.env.REACT_APP_RZP_TEST_API_KEY,
      key: process.env.REACT_APP_RZP_LIVE_API_KEY,
      amount: amount * 100,
      currency: "INR",
      name: "A3Minds",
      description: "PROD",
      handler: function (response) {
        console.log(response);

        const timestamp = Date.now();
        const date = new Date(timestamp);

        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1
          }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        console.log(formattedDate);

        const requestBody = {
          ...body,
          amount: amount * 100,
          transId: response.razorpay_payment_id,
          trans_status: "success",
          trans_date: formattedDate,
        };
        onPaymentSuccess(requestBody, setModalVisible);
      },
      prefill: {
        name: `${body.fname} ${body.lname}`,
        email: `${body.email}`,
        contact: `${body.phone}`,
      },
      notes: {
        address: "office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      console.error(response.error);
      window.location.href = "/register";
    });
  } catch (error) {
    console.log(error.response.data);
    toastByStatus(
      toast,
      "",
      error.response.data.error,
      "",
      error.response.data.error
    );
    window.location.href = "/register";
  }
};
