import React, { memo, useRef, useState } from "react";
import { postCall } from "../../services/httpService";
import { STUDENT_API } from "../../services/apiRoutes";
import { Box, Button, Card, CardBody, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Select, useDisclosure, useToast } from "@chakra-ui/react";
import { FiFile, FiX, FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import StudentFile from "../../files/lni_student.csv";
import BasicUsage from "../../components/BasicModal";
import InstituteList from "./InstituteList";
import { CONSTANTS } from "../../services/constants";
import { validateFileType } from "../../services/utils";

function InstituteBulkUpload({ searchObj, instituteData, handleChange, onAddStudents, onCreate, handleOnExcluded }) {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [studentList, SetStudentList] = useState({
        isLoading: false,
        data: [],
    });
    const ref = useRef();
    const viewRef = useRef();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const handleBulkUpload = (e) => {
        if (!validateFileType(e.target.files[0])) {
            var validExts = [".csv"];
            toast({
                title: "Invalid file selected, valid files are " +
                    validExts.toString() +
                    " types.",
                status: 'warning',
                duration: CONSTANTS.DURATION,
            })
            return;
        }
        setFile(e.target.files[0]);
    };

    const uploadFile = () => {
        if (!searchObj['institution-id']) return;
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('institution-id', searchObj['institution-id']);
        postCall(STUDENT_API.STUDENT_UPLOAD, formData, null, null, false)
            .then((result) => {
                toast({
                    title: `Students uploaded successfully`,
                    status: 'success',
                    duration: CONSTANTS.DURATION,
                })
                setFile(null);
                onAddStudents();
                if (ref.current) {
                    ref.current.value = "";
                };
                if (viewRef.current) {
                    viewRef.current.value = "";
                };
                console.log('result', result.data.usr_records);
                handleOnExcluded(result.data.usr_records)

                setTimeout(() => {
                    if (result.data && result.data.usr_records && result.data.usr_records.length > 0) {
                        onOpen();
                        SetStudentList((prev) => ({ ...prev, data: result.data.usr_records }));
                    }
                }, 1000);
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(() => setLoading(false));
    }

    return (
        <>
            {loading && (
                <div className="loader flex-center">Loading...</div>
            )}
            {/* <BasicUsage isOpen={isOpen} onClose={onClose}>
                <InstituteList
                    studentList={studentList}
                    changeStatus={() => {}}
                    modal={true}
                />
            </BasicUsage> */}
            <Card mt={9} mb={5}>
                <Heading size={'md'} alignSelf={'center'} p={2}>Institute Bulk Upload</Heading>
                <CardBody>
                    <Flex flexDirection={'column'}>
                        <FormControl>
                            <FormLabel className="form-label" htmlFor="bulk_upload">
                                Upload Student
                            </FormLabel>
                            {/* <Input
                                ref={ref}
                                type="file"
                                name="upload"
                                accept=".pdf,.doc,.csv,.docx,application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleBulkUpload}
                            /> */}
                            <InputGroup size={'sm'}>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<Icon as={FiFile} />}
                                />
                                <input type='file' accept=".pdf, .doc,.csv,.docx, .xlsx, .xls, application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    name={'upload'} ref={ref} style={{ display: 'none' }} onChange={handleBulkUpload}></input>
                                <Input
                                    placeholder={"Browse File"}
                                    readOnly={'true'}
                                    ref={viewRef}
                                    onClick={() => ref.current.click()}
                                    value={file?.name || null}
                                />
                                {(file && file.name) && <InputRightElement
                                    pointerEvents="visible"
                                    cursor={'pointer'}
                                    onClick={() => {
                                        setFile(null);
                                        if (ref.current) {
                                            ref.current.value = "";
                                        }
                                        if (viewRef.current) {
                                            viewRef.current.value = "";
                                        }
                                    }}
                                    children={<Icon as={FiX} />}
                                />}
                            </InputGroup>
                        </FormControl>
                        {/* {(file && file.name) && <p className="upload">{file.name}</p>} */}
                        <Button size={'sm'} colorScheme='blue' mt='2' type="submit" isDisabled={!searchObj['institution-id'] || !file} onClick={uploadFile}>upload</Button>
                        <Button size={'sm'} colorScheme='green' mt='2' type="Button" onClick={onCreate} pr={8} pl={8}>Create Institute</Button>
                        <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <a style={{
                                color: '#3182ce'
                            }} href={StudentFile} target="_blank" download="sample_student" rel="noreferrer">Download sample format</a>
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
        </>
    );
}

export default memo(InstituteBulkUpload);
