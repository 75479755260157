export const PrivacyPolicy = {
  title: "Privacy Policy",
  contents: [
    {
      head: "Introduction",
      details: `A3Minds ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.a3minds.com] or use our services, or engage with us in any other way. By accessing our website or using our services, you agree to the terms of this Privacy Policy.`,
    },
    {
      head: "Information We Collect",
      details: `We may collect and process the following types of information:
        - Personal Identification Information: Name, email address, phone number, postal address, and other similar contact data.
        - Demographic Information: Age, gender, interests, and preferences.
        - Technical Information: IP address, browser type, operating system, and usage details.
        - Financial Information: Payment details, billing address, and other information necessary to process payments.
        - Other Information: Any other information you provide directly to us, such as feedback, survey responses, and support requests.`,
    },
    {
      head: "How We Collect Information",
      details: `We collect information in the following ways:
        - Directly from You: When you register on our website, subscribe to our newsletter, enroll in a course, or contact us for support.
        - Automatically: Through cookies and other tracking technologies when you interact with our website.
        - From Third Parties: Such as payment processors, social media platforms, and marketing partners.`,
    },
    {
      head: "Use of Your Information",
      details: `We use the information we collect for the following purposes:
        - To Provide Services: To process transactions, manage your account, and deliver the services you have requested.
        - To Communicate with You: To send you updates, newsletters, and other information related to your use of our services.
        - To Improve Our Services: To understand how you use our services and to enhance and personalize your experience.
        - To Protect Our Services: To detect and prevent fraudulent activities and ensure the security of our website and services.
        - To Comply with Legal Obligations: To fulfill legal requirements and respond to legal requests.`,
    },
    {
      head: "Disclosure of Your Information",
      details: `We may share your information in the following circumstances:
        - With Service Providers: We may share your information with third-party service providers who perform services on our behalf.
        - With Affiliates: We may share your information with our affiliates for purposes consistent with this Privacy Policy.
        - For Legal Reasons: We may disclose your information if required by law or in response to valid requests by public authorities.
        - With Your Consent: We may share your information for any other purpose disclosed to you with your consent.`,
    },
    {
      head: "Security of Your Information",
      details: `We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.`,
    },
    {
      head: "Your Rights",
      details: `Depending on your location, you may have the following rights regarding your personal information:
        - Access: The right to access and obtain a copy of your personal information.
        - Correction: The right to correct inaccuracies in your personal information.
        - Deletion: The right to request the deletion of your personal information.
        - Objection: The right to object to the processing of your personal information.
        - Restriction: The right to request the restriction of processing your personal information.
        - Portability: The right to receive your personal information in a structured, commonly used, and machine-readable format.
        To exercise these rights, please contact us using the information provided below.`,
    },
    {
      head: "Third-Party Links",
      details: `Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party websites. We encourage you to read the privacy policies of any linked websites you visit.`,
    },
    {
      head: "Changes to This Privacy Policy",
      details: `We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.`,
    },
    {
      head: "Contact Us",
      details: `If you have any questions about this Privacy Policy, please contact us at:
        - Email: info@a3minds.com`,
    },
  ],
};
