import {
  Box,
  Flex,
  Center,
  Image,
  chakra,
  GridItem,
  Container,
  UnorderedList,
  ListItem,
  Text,
  useTheme
} from '@chakra-ui/react'

import communication2 from '../../images/communication2.jpg';
import communication3 from '../../images/communication3.jpg';
import communication4 from '../../images/communication4.jpg';
import communication5 from '../../images/communication5.jpg';
import communication6 from '../../images/communication6.jpg';
import communication from '../../images/communication.jpg';

export const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function Communication() {
  const theme = useTheme();
  const { imageFlex, flexText, fFont, topic } = theme;
  return (
    <>
      <Flex height={'6rem'} alignItems={'center'} justifyContent={'center'} >
        <Text {...topic} ><b>COMMUNICATION AND INTERPERSONAL SKILLS</b></Text>
      </Flex>
      <Box position={"relative"}>
        {/* <Box className="communication" /> */}
        <img src={communication} alt="personal" />
      </Box>
      <Box>
        <Flex mt={5} mb={5} alignItems={'center'}>
          <Container maxW={'3xl'}>
            <Box m={5}>
              <Text fontSize={fFont}>
                Effective communication and interpersonal skills are essential for success in academic and personal spheres. Our Communication and Interpersonal Skills programs provide individuals with the tools and techniques to express themselves confidently, collaborate effectively, and build positive relationships.<br />
                <b>Our Communication and Interpersonal Training Programs are broadly divided into five categories which covers the entire spectrum of life.</b>
              </Text>
            </Box>
            <Box m={5}>
              <Center>
                <Image src={communication2} w={'100%'} mt={2} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText}>
                  Building Effective Communication
                  <br />Skills
                </Text>
              </Flex>
              <Center>
                <Image src={communication3} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText}>
                  Developing Leadership Abilities for Student Success
                </Text>
              </Flex>
              <Center>
                <Image src={communication4} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText}>
                  Enhancing Critical Thinking and Problem-Solving Skills
                </Text>
              </Flex>
              <Center>
                <Image src={communication5} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText}>
                  Nurturing Creativity and Innovation
                </Text>
              </Flex>
              <Center>
                <Image src={communication6} w={'100%'} mt={12} mb={1} />
              </Center>
              <Flex {...imageFlex} >
                <Text {...flexText}>
                  Building Healthy Relationships and Conflict Resolution
                </Text>
              </Flex>
            </Box>
            <Box m={5}>
              <Text mt={1} mb={4} fontSize={{ base: "md", md: "xl", lg: "2xl" }}>
                <b>COMMUNICATION AND INTERPERSONAL SKILLS Training Programs has beendesigned to address the following Modules.</b>
              </Text>
              <UnorderedList fontSize={fFont}>
                <ListItem>Developes foundational communication skills, including listening, speaking, and non-verbal
                  communication.</ListItem>
                <ListItem>Further expands communication skills to include persuasive speaking, public speaking and
                  effective presentation skills.</ListItem>
                <ListItem>Teaches advanced communication skills, including effective writing, professional
                  communication, and public speaking for academic and career success.</ListItem>
                <ListItem>Developes Interpersonal skills such as teamwork, empathy and conflict resolution, fostering
                  positive relationships.</ListItem>
                <ListItem>Deepens the interpersonal skills through topics like leadership development, effective
                  collaboration and negotiation techniques.</ListItem>
                <ListItem>Further refines the interpersonal skills through topics like networking, building influential
                  relationships and effective conflict management.</ListItem>
              </UnorderedList>
            </Box>
          </Container>

        </Flex>
      </Box>
    </>
  )
}